import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Heading, Image } from 'arwes'
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import BasicFrame from '../components/BasicFrame';
import SemiSection from '../components/SemiSection';
import SectionContact from './SectionContact';

const useStyles = makeStyles({
    root: {
        textAlign: 'left'
    },
    headingContainer: {
        textAlign: 'center',
        marginBottom: 42,
    },
    section1: {
        fontSize: '1.3em',
        margin: '24px',
    },
    sectionLeadIndustry: {
        margin: '48px 24px 32px 24px',
        '& .leadRow': {
            margin: '10px auto',
            'padding': '24px 36px',
        },
        '& .leadRow:nth-child(even)': {
            'background-color': 'rgb(3, 33, 38)',
            'border-radius': '16px'
        }
    },
    sectionTeam: {
        margin: '24px',
        textAlign: 'center'
    },
    sectionTeamLeadName: {
        textAlign: 'center',
        fontSize: '1.4em',
    },
    sectionTeamLeadTitle: {
        textAlign: 'center',
        fontSize: '1em',
    },
    sectionTeamLeadContent: {
        fontSize: '0.9em',
    },
});

function AboutPage() {
    const classes = useStyles();

    return <>
        <div className={classes.root}>
            {/* SECTION 1 */}
            <BasicFrame>
                <div className={classes.headingContainer}>
                    <Heading node='h2'>
                        <FormattedMessage id='about.section1.title' />
                    </Heading>
                </div>

                <div className={classes.section1}>
                    <FormattedMessage id='about.section1.content' values={{ br: <br /> }} />
                </div>

                <div style={{ height: '64px' }}></div>

                {/* SECTION HOW WE LEAD */}

                <SemiSection flex>
                    <div style={{ height: '32px' }}></div>
                    <div className={classes.sectionLeadIndustry}>
                        <div className={classes.headingContainer}>
                            <Heading node='h2'>
                                <FormattedMessage id='about.sectionLead.title' />
                            </Heading>
                        </div>

                        <div className='leadRow'>
                            <FormattedMessage id='about.sectionLead.1' values={{ b: (...chunks) => <b>{chunks}</b> }} />
                        </div>
                        <div className='leadRow'>
                            <FormattedMessage id='about.sectionLead.2' values={{ b: (...chunks) => <b>{chunks}</b> }} />
                        </div>
                        <div className='leadRow'>
                            <FormattedMessage id='about.sectionLead.3' values={{ b: (...chunks) => <b>{chunks}</b> }} />
                        </div>
                    </div>
                    <div style={{ height: '32px' }}></div>
                </SemiSection>

                {/* SECTION TEAM */}

                <div className={classes.sectionTeam}>
                    <div style={{ height: '32px' }}></div>
                    <div className={classes.headingContainer}>
                        <Heading node='h2'>
                            <FormattedMessage id='about.sectionTeam.title' />
                        </Heading>
                    </div>

                    <div className='sectionTeamLead'>
                        <Grid container spacing={3}>
                            <Grid item md={6} sm={12} className={classes.grid}>
                                <div className={classes.sectionTeamLeadName}><FormattedMessage id="about.sectionTeam.ceo.name" /></div>
                                <div className={classes.sectionTeamLeadTitle}><FormattedMessage id="about.sectionTeam.ceo.title" /></div>
                                <div className={classes.sectionTeamLeadContent}><FormattedMessage id="about.sectionTeam.ceo.content" /></div>
                            </Grid>

                            <Grid item md={6} sm={12} className={classes.grid}>
                                <div className={classes.sectionTeamLeadName}><FormattedMessage id="about.sectionTeam.coo.name" /></div>
                                <div className={classes.sectionTeamLeadTitle}><FormattedMessage id="about.sectionTeam.coo.title" /></div>
                                <div className={classes.sectionTeamLeadContent}><FormattedMessage id="about.sectionTeam.coo.content" /></div>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ height: '52px' }}></div>
                    <div className='sectionTeamColleagues'>
                        <Grid container spacing={3}>
                            <Grid item md={4} sm={12} className={classes.grid}>
                                <div className={classes.sectionTeamColleagueName}><FormattedMessage id="about.sectionTeam.1.name" /></div>
                                <div className={classes.sectionTeamColleagueTitle}><FormattedMessage id="about.sectionTeam.1.title" /></div>
                            </Grid>

                            <Grid item md={4} sm={12} className={classes.grid}>
                                <div className={classes.sectionTeamColleagueName}><FormattedMessage id="about.sectionTeam.2.name" /></div>
                                <div className={classes.sectionTeamColleagueTitle}><FormattedMessage id="about.sectionTeam.2.title" /></div>
                            </Grid>
                            <Grid item md={4} sm={12} className={classes.grid}>
                                <div className={classes.sectionTeamColleagueName}><FormattedMessage id="about.sectionTeam.2.name" /></div>
                                <div className={classes.sectionTeamColleagueTitle}><FormattedMessage id="about.sectionTeam.2.title" /></div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </BasicFrame>

            <div style={{ height: '32px' }}></div>


        </div>;
        <BasicFrame>
            <SectionContact />
        </BasicFrame>
    </>
}

export default AboutPage
