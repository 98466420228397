import { gtagRegister } from '../core/GTagManager';
import * as catalogService from '../services/catalogService';

export function getCheckoutSession(items) {
  return dispatch => {
    gtagRegister("event", "begin_checkout", {
      currency: "USD",
      value: 15,
      items: [
        {
          item_id: "123",
          item_name: "ColdGame",
          index: 0,
          item_brand: "Discape",
          item_category: "Activity",
          price: 15,
          quantity: 1
        }
      ]
    });

    catalogService.getCheckoutSession(items)
  };
}