import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    separator: {
        height: '1px',
        width: '100%',
        backgroundColor: '#042c35'
    },
});

const Separator = () => {
    const classes = useStyles();

    return <div className={classes.separator}></div>
}

export default Separator