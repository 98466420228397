import { socket } from './networkManager'
import getClientId from './clientId';
import { setClientId, setUserType } from 'discapecommon/actions/userActions'
import { userActions } from './actions/userActions'
import {
  setStateFromServer, setPersistantStateFromServer,
  setConnectionState
} from 'discapecommon/actions/index'
import createSocketIoMiddleware from 'redux-socket.io';
import { asyncDispatchMiddleware, createBridgeEventManager } from 'discapecommon/storeMiddlewares'
import { logger } from './core/storeMiddlewares'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger'
import { batchActions, batchDispatchMiddleware } from 'redux-batched-actions';
import reducer from './reducers'
import initialState from './consts/defaultState'
import eventManager from './eventManager'

const socketIoMiddleware = createSocketIoMiddleware(socket, (type, action) => action.meta?.sendServer);
const eventManagerMiddleware = createBridgeEventManager(eventManager);
// const loggerMiddleware = createLogger();

const middlewares = [
  socketIoMiddleware,
  eventManagerMiddleware,
  thunk,
  batchDispatchMiddleware,
  asyncDispatchMiddleware
]

middlewares.push(logger)

const store = applyMiddleware(...middlewares)(createStore)(reducer, initialState);

socket.on('state', state =>
  store.dispatch(setStateFromServer(state))
);

// Persistant object's state
socket.on('stateUpdate', state =>
  store.dispatch(setPersistantStateFromServer(state))
);

socket.emit('action', setUserType('USER'));

[
  'connect',
  'connect_error',
  'connect_failed',
  'connect_timeout',
  'reconnect',
  'reconnecting',
  'reconnect_error',
  'reconnect_failed',
  'disconnect'
].forEach(ev =>
  socket.on(ev, () => store.dispatch(setConnectionState(ev, socket.connected)))
);

store.dispatch(setClientId(getClientId()));

// [
//   'connect',
//   'reconnect',
// ].forEach(ev =>
//   socket.on(ev, () => store.dispatch(userActions.verifyToken()))
// );

export default store;