import { demoTypes } from 'discapecommon/consts/demoTypes';
import sendClientRequest from './_sendClientRequest'

export function book(data) {
  const request = {
    type: demoTypes.UP_DEMO_BOOK,
    ...data
  };

  return sendClientRequest(request)
    .then(response => {
      return response.ok;
    });
}