import { demoTypes } from 'discapecommon/consts/demoTypes';
import * as demoService from '../services/demoService';
import { openAdditionalPanel, closeAdditionalPanel } from 'discapecommon/actions/index';
import { alertActions } from 'discapecommon/actions/alertActions';
import browserHistory from 'discapecommon/browserHistory'
import { alertConstants } from 'discapecommon/consts/alertConsts';


export function book(data) {
    return dispatch => {
        console.log('request ', request(data))
        dispatch(request(data));

        demoService.book(data)
            .then(
                () => dispatch(success()),
                error => dispatch(failure(error.message))
            );
    };

    function request(data) { return { type: demoTypes.DEMO_BOOK_PENDING, ...data } }
    function success() { return { type: demoTypes.DEMO_BOOK_SUCCESS } }
    function failure(errorMessageKey) { return { type: demoTypes.DEMO_BOOK_ERROR, errorMessageKey: errorMessageKey } }
}
