import {log} from '../../utils'
import {selectTrigger, unselectTrigger} from '../actions/triggerActions'

const triggerPanel = (state = [], action) => {
  switch (action.type) {
    case 'OPEN_ARG_PANEL':
      var previousPanel = state.find(({ functionId }) => functionId == action.functionId)
      if(typeof previousPanel !== 'undefined') {
        state = state.filter(({ id }) => id < previousPanel.id )
      }
      var id = Math.max(0, ...state.map(f => f.id)) + 1;
      return [...state,
        {
          id: id,
          argId: action.argId,
          functionId:action.functionId,
          childPanel:action.childPanel,
        }
      ]
    case 'CHANGE_ARG_VALUE':
    case 'CHANGE_ARG_MODE':
      var previousPanel = state.find(({ functionId }) => functionId == action.id)
      if(typeof previousPanel !== 'undefined') {
        return state.filter(({ id }) => id <= previousPanel.id )
      }
      return state;
    case 'SELECT_FUNCTION_TYPE':
      var previousPanel = state.find(({ functionId }) => functionId == action.id)
      if(typeof previousPanel !== 'undefined') {
        return state.filter(({ id }) => id < previousPanel.id )
      }
      return state;
    case 'SELECT_TRIGGER':
    case 'SELECT_ECA':
      return [];
    case 'CLOSE_ARG_PANEL':  
      return state.filter(({ id }) => id < action.id);
    default:
      return state
  }
}

export default triggerPanel
