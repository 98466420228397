import navigationManager from "../navigationManager"

const NavLink = ({ refC, to, onClick, onMouseEnter, onMouseLeave, className, children, options }) => {
    const _onClick = (e) => {
        if (options?.newTab) {
            window.open(to, '_blank').focus();
        }
        else {
            navigationManager.push(to);
        }
        e.preventDefault();
        if (typeof onClick === 'function') {
            onClick()
        }
    }

    return <a
        ref={refC}
        href={to}
        onClick={_onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}>
        {children}
    </a>
}

export default NavLink