import { openAdditionalPanel, closeAdditionalPanel } from 'discapecommon/actions/index'
import { setChannelStatus, playAudio, stopAudio, videoConferenceEnded } from '/src/actions/mediaActions'
import { channels } from 'discapecommon/consts/channels';
import { OCCURING_EVENT_TYPES } from 'discapecommon/consts/occuringEventTypes'
import { openEnigma } from 'discapecommon/actions/enigmaActions'
import navigationManager from '../navigationManager';

const defaultState = {
  preloads: [],
  isSoundUnlocked: false, // Can be set from the options
  options: {
    keepUp: false, // keepUp at the end
    showPickUp: true, // Start a call first
    isSkippable: false // If skippable, player can stop it by pressed the red hand button
  }
}

/*
Il faudrait implémenter un système de liste de lecture pour une suite de vidéo
=> Un peu compliqué de faire ça avec de la logique
*/

const videoConferenceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'START_VIDEO_CONFERENCE':
      action.asyncDispatch(openAdditionalPanel('PLAYER_VIDEO'));
      action.asyncDispatch(setChannelStatus(0, 'PAUSE'));
      navigationManager.scrollToTop();

      if (action.options?.showPickUp)
        action.asyncDispatch(playAudio(2000, 100, channels.SFX));

      return {
        ...state,
        isStarted: true,
        mediaId: action.mediaId,
        operation: action.operation,
        keepUp: action.options?.keepUp,
        showPickUp: action.options?.showPickUp,
        options: action.options,
        isSoundUnlocked: action.options?.isSoundUnlocked || ((!action.options?.showPickUp) ?? true),
      };
    case 'UPDATE_VIDEO_CONFERENCE':
      if (!state.isStarted) {
        action.asyncDispatch(openAdditionalPanel('PLAYER_VIDEO'));
        action.asyncDispatch(setChannelStatus(0, 'PAUSE'));
        action.asyncDispatch(playAudio(2000, 100, channels.SFX));
      }
      return {
        ...state,
        isStarted: true,
        mediaId: action.mediaId,
        operation: action.operation,
        keepUp: action.keepUp,
      }
    case 'VIDEO_CONFERENCE_PRELOAD':
      return {
        ...state,
        preloads: [...state.preloads, action.mediaId]
      }
    case 'VIDEO_CONFERENCE_ENDED':
      if (state.keepUp && !action.force) {
        return state;
      }
      action.asyncDispatch(closeAdditionalPanel('PLAYER_VIDEO'));
      action.asyncDispatch(setChannelStatus(0, 'PLAY'));
      action.asyncDispatch(stopAudio(2000, channels.SFX)) // Stop Skype sound
      return { ...state, isStarted: false };
    case OCCURING_EVENT_TYPES.VIDEO_CONFERENCE_END_ICON_CLICK:
      if (state.options?.isSkippable) {
        action.asyncDispatch(videoConferenceEnded(state.mediaId, true, 'CLOSED_BY_USER'));
      }
      else {
        action.asyncDispatch(openEnigma(1000))
      }
      return state;
    case 'SUBMIT_ENIGMA_ANSWER':
      if (action.id == 1000 && action.answer == 'YES') {
        action.asyncDispatch(videoConferenceEnded(state.mediaId, true, 'CLOSED_BY_USER'));
      }
      return state;
    case 'CLT_SET_VIDEO_CONFERENCE_MUTED':
      return { ...state, isMuted: action.isMuted }
    case 'VIDEO_CONFERENCE_PICKED_UP':
      action.asyncDispatch(stopAudio(2000, channels.SFX)) // Stop Skype sound
      return state;
    case 'CLT_CHECK_LEAVE_SESSION':
    case 'VIDEO_CONFERENCE_CLOSE_ALL':
      action.asyncDispatch(closeAdditionalPanel('PLAYER_VIDEO'));
      return {};
    case 'CLT_UNLOCK_SOUND':
      return { ...state, isSoundUnlocked: true }
    default:
      return state;
  }
}

export default videoConferenceReducer