import { userTypes } from 'discapecommon/consts/userTypes';
import { refreshCurrentPage } from 'discapecommon/actions/layoutActions'
import { cltRefreshRoleDistribution } from 'discapecommon/actions/gameFlowActions'
import { closeAdditionalPanel } from 'discapecommon/actions/index'
import playerInfos from '../core/playerInfos'
import { storeToken } from '../services/userService'
import { openStaticLink } from 'discapecommon/actions/index'

const initialState = { isLoggingIn: false, isLoggedIn: false, alert: {} };

/*
    USER ACCOUNT
*/

export default function user(state = initialState, action) {
  switch (action.type) {
    case userTypes.USERS_SET_USER_INFOS:
      if (action.user?.token)
        storeToken(action.user?.token);
      return {
        ...state,
        id: action.user.userHandlerId,
        userHandlerId: action.user.userHandlerId,
        userProfileId: action.user.userProfileId,
        username: action.user.username,
        isLoggedIn: !action.user.isTempUser,
      }
    case 'DOWN_UPDATE_USERS':
      var user = action.users.find(({ userHandlerId }) => userHandlerId == state.userHandlerId);
      if (user) {
        playerInfos.setPlayerNumber(user.playerNumber)
        action.asyncDispatch(cltRefreshRoleDistribution())
        return { ...state, playerNumber: user.playerNumber }
      }
      else {
        console.error('Unmatched userId')
      }
      return state;
    case 'SET_CURRENT_SCENE':
      if (action.scene == 'LOBBY' && !state.isLoggedIn) {
        action.asyncDispatch(openStaticLink('RENAME_DIALOG'))
      }
      return state;
    case 'PUSH_SESSION_STATE':
      return { ...state, playerNumber: action.playerNumber }
    case userTypes.USERS_LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
      };
    case userTypes.USERS_LOGIN_SUCCESS:
    case userTypes.USERS_REGISTER_SUCCESS:
      action.asyncDispatch(refreshCurrentPage())
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        alert: {},
        ...action.user,
      };
    case userTypes.USERS_LOGIN_FAILURE:
      console.log('action', action.alert)
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        alert: action.alert,
      };
    case userTypes.USERS_LOG_OUT:
      action.asyncDispatch(refreshCurrentPage())
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
      };
    case userTypes.USERS_UPDATE_REQUEST:
      return { ...state, isWaitingResponse: true }
    case userTypes.USERS_UPDATE_SUCCESS:
      console.log('user', action.user)
      action.asyncDispatch(closeAdditionalPanel('RENAME_DIALOG'))
      return { ...state, isWaitingResponse: false, ...action.user }
    case userTypes.USERS_UPDATE_FAILURE:
      return { ...state, alert: action.alert, isWaitingResponse: false }

    case userTypes.USERS_UPDATE_PASSWORD_REQUEST:
      return { ...state, alertPassword: {}, passwordUpdateStatus: 'REQUESTED' }
    case userTypes.USERS_UPDATE_PASSWORD_SUCCESS:
      return { ...state, alertPassword: {}, passwordUpdateStatus: 'SUCCESS' }
    case userTypes.USERS_UPDATE_PASSWORD_FAILURE:
      return { ...state, alertPassword: action.alert, passwordUpdateStatus: 'FAILURE' }

    case userTypes.USERS_RETRIEVE_PASSWORD_REQUEST:
      return { ...state, alertPasswordRetrieval: {}, passwordRetrievalStatus: 'REQUESTED' }
    case userTypes.USERS_RETRIEVE_PASSWORD_EMAIL_SENT:
      return { ...state, alertPasswordRetrieval: {}, passwordRetrievalStatus: 'EMAIL_SENT' }
    case userTypes.USERS_RETRIEVE_PASSWORD_FAILURE:
      return { ...state, alertPasswordRetrieval: action.alert, passwordRetrievalStatus: 'FAILURE' }

    case userTypes.USERS_VERIFY_RESET_TOKEN_REQUEST:
      return { ...state, alertPasswordRetrieval: {}, passwordRetrievalStatus: 'VERIFICATION_REQUESTED' }
    case userTypes.USERS_VERIFY_RESET_TOKEN_SUCCESS:
      return { ...state, alertPasswordRetrieval: {}, passwordRetrievalStatus: 'TOKEN_VERIFIED' }
    case userTypes.USERS_VERIFY_RESET_TOKEN_FAILURE:
      return { ...state, alertPasswordRetrieval: action.alert, passwordRetrievalStatus: 'VERIFICATION_FAILED' }

    case userTypes.USERS_SET_NEW_PASSWORD_REQUEST:
      return { ...state, alertPasswordRetrieval: {}, passwordRetrievalStatus: 'NEW_PASSWORD_REQUESTED' }
    case userTypes.USERS_SET_NEW_PASSWORD_SUCCESS:
      return { ...state, alertPasswordRetrieval: {}, passwordRetrievalStatus: 'NEW_PASSWORD_SUCCESS' }
    case userTypes.USERS_SET_NEW_PASSWORD_FAILURE:
      return { ...state, alertPasswordRetrieval: action.alert, passwordRetrievalStatus: 'NEW_PASSWORD_FAILURE' }
    default:
      return state
  }
}