import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { userActions } from '../actions/userActions'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Frame, Heading, Link, Button, Loading, Words } from 'arwes'
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import { injectIntl, FormattedMessage } from 'react-intl';
import Separator from '../components/Separator';
import { openStaticLink } from 'discapecommon/actions/index';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    width: '100%',
  },
  content: {
    padding: 16,
    width: '100%',
  },
  heading: {
    marginTop: 12,
  },
  value: {
    minWidth: 150,
  },
  table: {
    margin: 'auto',
    marginBottom: 20,
    '& td': {
      padding: 10,
      // display: 'inline-flex',
    }
  },
  loading: {
    position: 'absolute',
    top: 0,
    right: 50,
  }
});

var ProfileInfos = ({ intl, user, updateUser, alert, isWaitingResponse }) => {
  const classes = useStyles();

  const [ownUser, setUser] = useState(user);
  const [emailStatus, setEmailStatus] = useState(0);
  const [passwordStatus, setPasswordStatus] = useState(0);
  const [usernameStatus, setUsernameStatus] = useState(0);
  const [ownAlerts, setOwnAlerts] = useState([]);
  const [hasFocus, setHasFocus] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  var ownAlertsTemp = [];

  const handleSubmit = () => {
    ownAlertsTemp = ownAlerts;
    const emailResult = verifyEmail();
    const usernameResult = verifyUsername();
    // const passwordResult = verifyPassword();
    setOwnAlerts(ownAlertsTemp);

    if (!(emailResult && usernameResult))
      return false;

    updateUser(ownUser);
  }

  const addAlert = (newAlert) => {
    if (!ownAlertsTemp.find(({ key }) => key == newAlert.key))
      ownAlertsTemp = ([...ownAlertsTemp, newAlert])
  }
  const removeAlert = (_key) => {
    ownAlertsTemp = ownAlertsTemp.filter(({ key }) => key != _key);
  }

  const verifyEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(ownUser.email)) {
      setEmailStatus(1)
      removeAlert('EMAIL_INVALID');
      return true;
    }
    else {
      setEmailStatus(-1)
      addAlert({ key: 'EMAIL_INVALID', message: 'Email invalid' })
      return false;
    }
  }

  const verifyUsername = () => {
    if (user.username.length > 2) {
      setUsernameStatus(1)
      removeAlert('USERNAME_MINIMAL_LENGTH');
      return true;
    }
    else {
      setUsernameStatus(-1);
      addAlert({
        key: 'USERNAME_MINIMAL_LENGTH',
        message: 'Username must be of 3 characters minimum'
      })
      return false;
    }
  }

  const verifyPassword = () => {
    if (ownUser.password.length > 2) {
      setPasswordStatus(1)
      removeAlert('PASSWORD_MINIMAL_LENGTH');
      return true;
    }
    else {
      setPasswordStatus(-1)
      addAlert({
        key: 'PASSWORD_MINIMAL_LENGTH',
        message: 'Password must be of 3 characters minimum'
      });
      return false;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...ownUser, [name]: value });

    switch (name) {
      case 'email':
        setEmailStatus(0);
        break;
      case 'username':
        setUsernameStatus(0);
        break;
    }
  }

  const handleBlur = (e) => {
    const { name } = e.target;
    ownAlertsTemp = ownAlerts;
    setHasFocus(false);
    switch (name) {
      case 'email':
        verifyEmail();
        break;
      case 'username':
        verifyUsername();
        break;
    }
    setOwnAlerts(ownAlertsTemp);
  }

  const handleFocus = () => {
    setHasFocus(true);
  }

  useEffect(() => {
    const listener = async (event) => {
      if (hasFocus && (event.code === "Enter" || event.code === "NumpadEnter")) {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleSubmit]);

  const getActions = () => {
    if (true) {
      return <div id="actions">
        <Button onClick={handleSubmit} className={classes.submit}>
          Save
        </Button>
      </div>
    }
  }

  return (
    <div className={classes.root}>
      {isWaitingResponse && <Loading animate className={classes.loading} />}
      <Hidden smDown><Heading node='h2' className={classes.heading}>
        <FormattedMessage id='profile.infos.title' />
      </Heading></Hidden>
      <div>{alert?.message && <FormattedMessage id={alert.message} />}</div>
      {ownAlerts.map((ownAlert, i) => <Words key={i}>{ownAlert.message}</Words>)}
      <table className={classes.table}>
        <tbody>
          <tr>
            <td><FormattedMessage id='auth.label.username' /></td>
            <td className={classes.value}>
              <Input
                autoFocus
                error={usernameStatus == -1}
                margin="dense"
                id="username"
                name="username"
                label={intl.formatMessage({ id: 'auth.label.username' })}
                type="username"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={ownUser.username}
              />
            </td>
          </tr>
          <tr>
            <td><FormattedMessage id='auth.label.email' /></td>
            <td>
              <Input
                error={emailStatus == -1}
                margin="dense"
                id="email"
                name="email"
                label={intl.formatMessage({ id: 'auth.label.email' })}
                type="email"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={ownUser.email}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {getActions()}
      {isLoggedIn && <>
        <div style={{ height: 32 }}></div>
        <Separator />
        <div style={{ height: 32 }}></div>
        <div id="actions">
          <Button onClick={() => dispatch(openStaticLink('LOG_OUT'))} className={classes.submit}>
            Se déconnecter
          </Button>
        </div>
      </>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  isWaitingResponse: state.user.isWaitingResponse,
  alert: state.user.alert,
})

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (user) => dispatch(userActions.update(user))
  }
}

ProfileInfos = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileInfos)

export default injectIntl(ProfileInfos)