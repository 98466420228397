
import { OCCURING_EVENT_TYPES } from 'discapecommon/consts/occuringEventTypes'
// UPLOADS

export const addUpload = (mediaType, groupId, req, name) => ({
  type: 'ADD_UPLOAD',
  req,
  groupId,
  mediaType,
  name,
})

export const setUploadProgress = (id, progress, req) => ({
  type: 'SET_UPLOAD_PROGRESS',
  id,
  progress,
  req,
  meta: {
    log: false,
  }
})

export const setUploadComplete = (id, fileName) => ({
  type: 'SET_UPLOAD_COMPLETE',
  id,
  fileName,
})

export const setUploadFailed = (id) => ({
  type: 'SET_UPLOAD_FAILED',
  id,
})

export const cancelUpload = id => ({
  type: 'CANCEL_UPLOAD',
  id
})

export const openUploadPanel = () => ({
  type: 'OPEN_UPLOAD_PANEL',
})

export const closeUploadPanel = () => ({
  type: 'CLOSE_UPLOAD_PANEL',
})

// Sound

export const registerMedia = (uploadId, mediaType, groupId, name, fileName) => (
  {
    type: 'REGISTER_MEDIA',
    uploadId,
    mediaType,
    groupId,
    name,
    fileName,
    meta: { sendServer: true },
  })

export const changeMedia = data => ({
  type: 'CHANGE_MEDIA',
  id: data.id,
  targetName: data.name,
  targetValue: data.value,
  meta: { sendServer: true },
})

export const deleteMedia = data => ({
  type: 'DELETE_MEDIA',
  id: data.id,
  meta: { sendServer: true },
})

// To be read by medias reducer
export const playMedia = (id, mode = 'DEFAULT', operation = 'PLAY', options = {}) => ({
  type: 'PLAY_MEDIA',
  id,
  mode,
  operation,
  options,
})

export const showImage = (media, mode = 'DEFAULT') => ({
  type: 'SHOW_IMAGE',
  media,
  mode,
})


// MEDIA PLAYER
export const mediaPlay = data => ({
  type: 'MEDIA_PLAY',
})

export const mediaPause = data => ({
  type: 'MEDIA_PAUSE',
})

export const mediaStop = data => ({
  type: 'MEDIA_STOP',
})

export const mediaEnd = () => ({
  type: 'MEDIA_END',
})

// PLAYLISTS
export const addPlaylist = (values) => (
  {
    type: 'ADD_PLAYLIST',
    name: values.name,
    meta: { sendServer: true },
  })

export const changePlaylistName = data => ({
  type: 'CHANGE_PLAYLIST_NAME',
  id: data.id,
  name: data.name,
  value: data.value,
  meta: { sendServer: true },
})

export const selectPlaylist = data => ({
  type: 'SELECT_PLAYLIST',
  id: data.id,
  meta: { sendServer: false },
})

export const unselectPlaylist = data => ({
  type: 'UNSELECT_PLAYLIST',
  meta: { sendServer: false },
})

export const deletePlaylist = data => ({
  type: 'DELETE_PLAYLIST',
  id: data.id,
  meta: { sendServer: true },
})

export const registerMusic = (groupId, id) => ({
  type: 'REGISTER_MUSIC',
  id,
  groupId,
  meta: { sendServer: true },
})

export const deleteMusic = (groupId, id) => ({
  type: 'DELETE_MUSIC',
  id,
  groupId,
  meta: { sendServer: true },
})

export function videoOperation(media, operation, mode = 'DEFAULT', options = {}) {
  return {
    type: 'VIDEO_OPERATION',
    media,
    operation,
    mode,
    options,
  }
}

export function audioOperation(media, operation) {
  return {
    type: 'AUDIO_OPERATION',
    media,
    operation,
  }
}

export function setMediaList(list) {
  return {
    type: 'SET_MEDIA_LIST',
    list,
    meta: {
      sendClient: true,
    }
  }
}

export const closeFilePanel = (id) => ({
  type: 'CLOSE_FILE_PANEL',
  id,
})

/*
  id: fileId
  volume: 0 - 100
*/
export const playAudio = (id, volume = 100, channelId = 1) => ({
  type: 'PLAY_AUDIO',
  id,
  volume,
  channelId,
  meta: {
    sendClient: true,
  }
})

export const stopAudio = (id, channelId) => ({
  type: 'STOP_AUDIO',
  id,
  channelId,
  meta: {
    sendClient: true,
  }
})

export const setChannelVolume = (channelId = 0, volume = 20) => ({
  type: 'SET_CHANNEL_VOLUME',
  channelId,
  volume,
  meta: {
    sendClient: true,
  }
})

export const setChannelStatus = (channelId = 0, status = 'PLAY') => ({
  type: 'SET_CHANNEL_STATUS',
  channelId,
  status,
  meta: {
    sendClient: true,
  }
})

export const stopAllAudio = () => ({
  type: 'STOP_ALL_AUDIO',
  meta: {
    sendClient: true,
  }
})

export const setFilesStatus = (files, status) => ({
  type: 'CLT_SET_FILES_STATUS',
  files,
  status,
})

export const setVideoConferenceMuted = (isMuted) => ({
  type: 'CLT_SET_VIDEO_CONFERENCE_MUTED',
  isMuted,
})

export const startVideoConference = (mediaId, operation = 'PLAY',
  options = {
    keepUp: false,
    showPickUp: true,
    isSkippable: true
  }) => ({
    type: 'START_VIDEO_CONFERENCE',
    mediaId,
    operation,
    options
  })

export const videoConferenceStopAll = () => ({
  type: 'VIDEO_CONFERENCE_CLOSE_ALL'
})

export const updateVideoConference = (mediaId, operation = 'PLAY', options = {
  keepUp: false,
}) => ({
  type: 'UPDATE_VIDEO_CONFERENCE',
  mediaId,
  operation,
  keepUp: options?.keepUp,
})

export const videoConferencePickedUp = () => ({
  type: 'VIDEO_CONFERENCE_PICKED_UP',
})

export function videoConferenceEnded(mediaId, force = false, reason = 'unknown') {
  return {
    type: OCCURING_EVENT_TYPES.VIDEO_CONFERENCE_ENDED,
    mediaId,
    force,
    reason,
    meta: {
      isOccuringEvent: true,
    }
  }
}

export function videoConferenceEndIconClick(media) {
  return {
    type: OCCURING_EVENT_TYPES.VIDEO_CONFERENCE_END_ICON_CLICK,
    media,
    meta: {
      isOccuringEvent: true,
    }
  }
}