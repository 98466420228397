import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Hidden from '@material-ui/core/Hidden';
import { Frame, Heading, Link, Button, Loading, Words } from 'arwes'
import { injectIntl, FormattedMessage } from 'react-intl';
import { userActions } from '../actions/userActions'

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    width: '100%',
  },
  content: {
    padding:16,
    width:'100%',
  },
  heading: {
    marginTop:12,
  },
  value: {
    minWidth: 150,
  },
  table: {
    margin: 'auto',
    marginBottom:20,
    '& td': {
      padding: 10,
      // display: 'inline-flex',
    }
  },
  loading: {
    position:'absolute',
    top:0,
    right:50,
  }
});

var ProfilePassword = ({ intl, user, updatePassword, passwordUpdateStatus, 
  alertPassword, isWaitingResponsePassword }) => {
  const classes = useStyles();

  const [passwords, setPasswords] = useState({oldPassword:'', password:'', password2:''});
  const [ownStatus, setOwnStatus] = useState(passwordUpdateStatus);
  const [oldPasswordStatus, setOldPasswordStatus] = useState(0);
  const [passwordStatus, setPasswordStatus] = useState(0);
  const [password2Status, setPassword2Status] = useState(0);
  const [ownAlerts, setOwnAlerts] = useState([]);
  const [hasFocus, setHasFocus] = useState(false);
  var ownAlertsTemp = [];

  const handleSubmit = () => {
    ownAlertsTemp = ownAlerts;
    const oldPassword = verifyPassword('oldPassword');
    const password = verifyPassword('password');
    const password2 = verifyPassword('password2');
    setOwnAlerts(ownAlertsTemp);

    if(!(oldPassword && password && password2))
      return false;

    setOwnStatus('REQUESTED')
    updatePassword(passwords);
  }

  const addAlert = (newAlert) => {
    if(!ownAlertsTemp.find(({key}) => key == newAlert.key))
      ownAlertsTemp = ([...ownAlertsTemp, newAlert])
  }
  const removeAlert = (_key) => {
    ownAlertsTemp = ownAlertsTemp.filter(({key}) => key != _key);
  }

  const verifyPassword = (name) => {
    var isValid = true;
    if (passwords[name].length > 2) {
      removeAlert('PASSWORD_MINIMAL_LENGTH');
      isValid = true;
    }
    else {
      addAlert({key: 'PASSWORD_MINIMAL_LENGTH', 
        message: 'Password must be of 3 characters minimum'});
      isValid = false;
    }

    switch(name) {
      case 'oldPassword':
        setOldPasswordStatus(isValid ? 1 : -1)
        break;
      case 'password':
        setPasswordStatus(isValid ? 1 : -1);
        break;
      case 'password2':
        if (passwords.password == passwords.password2) {
          removeAlert('PASSWORD2_DIFFERENT');
        }
        else {
          addAlert({key: 'PASSWORD2_DIFFERENT', 
            message: 'The confirmation is different from the new password'});
          isValid = false;
        }
        setPassword2Status(isValid ? 1 : -1);
        break;
    }
    return isValid;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });

    switch(name) {
      case 'oldPassword':
        setOldPasswordStatus(0);
        break;
      case 'password':
        setPasswordStatus(0);
        break;
      case 'password2':
        setPassword2Status(0);
        break;
    }
    setOwnStatus('PENDING')
  }

  const handleBlur = (e) => {
    const { name } = e.target;
    ownAlertsTemp = ownAlerts;
    verifyPassword(name);
    setOwnAlerts(ownAlertsTemp);
    setHasFocus(false);
  }

  const handleFocus = () => {
    setHasFocus(true);
  }

  useEffect(() => {
    const listener = async (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleSubmit]);

  // STATUS HANDLING
  if(ownStatus != passwordUpdateStatus && ownStatus != 'PENDING') {
    setOwnStatus(passwordUpdateStatus)
  }

  const getActions = () => {
    switch(ownStatus) {
      case 'PENDING':
        return <div id="actions">
          <Button onClick={handleSubmit} className={classes.submit}>
            <FormattedMessage id='template.button.state.save'/>
          </Button>
        </div>  
        break
      case 'FAILURE': 
        return <div id="actions">
          <Button layer='alert'>
            <FormattedMessage id='template.button.state.error'/>
          </Button>
        </div>
        break;
      case 'SUCCESS': 
        return <div id="actions">
          <Button layer='success'>
            <FormattedMessage id='template.button.state.saved'/>
          </Button>
        </div>
        break;
      case 'REQUESTED': 
        return <div id="actions">
          <Button disabled>
            <FormattedMessage id='template.button.state.waiting'/>
          </Button>
        </div>
        break;
      default: 
        return <div id="actions">
          <Button disabled>
            <FormattedMessage id='template.button.state.upToDate'/>
          </Button>
        </div>
    }
  }

  return (
    <div className={classes.root}>
      {ownStatus == 'REQUESTED' && <Loading animate className={classes.loading}/>}
      <Hidden smDown><Heading node='h2' className={classes.heading}>
        <FormattedMessage id='profile.password.title'/>
      </Heading></Hidden>
      <Words>{alertPassword?.message}</Words>
      {ownAlerts.map((ownAlert, i) => <Words key={i}>{ownAlert.message}</Words>)}
      <table className={classes.table}>
        <tbody>
          <tr>
            <td><FormattedMessage id='auth.label.oldPassword'/></td>
            <td className={classes.value}>
              <Input
                autoFocus
                error={oldPasswordStatus == -1}
                margin="dense"
                id="oldPassword"
                name="oldPassword"
                label={intl.formatMessage({id: 'auth.label.oldPassword'})}
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                value={passwords.oldPassword}
              />
            </td> 
          </tr>
          <tr>
            <td><FormattedMessage id='auth.label.newPassword'/></td>
            <td>
              <Input
                error={passwordStatus == -1}
                margin="dense"
                id="password"
                name="password"
                label={intl.formatMessage({id: 'auth.label.password'})}
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                value={passwords.password}
              />
            </td>
          </tr>
          <tr>
            <td>
              <FormattedMessage id='auth.label.confirmation'/>
            </td>
            <td>
              <Input
                margin="dense"
                id="password2"
                name="password2"
                label={intl.formatMessage({id: 'auth.label.confirmation'})}
                type="password"
                error={password2Status == -1}
                onChange={handleChange}
                onBlur={handleBlur}
                value={passwords.password2}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {getActions()}
    </div>
  )
}

const mapStateToProps = (state) => ({
  alertPassword: state.user.alertPassword,
  passwordUpdateStatus: state.user.passwordUpdateStatus,
})

const mapDispatchToProps = dispatch => {
  return {
    updatePassword: (user) => dispatch(userActions.updatePassword(user))
  }
}

ProfilePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePassword)

export default injectIntl(ProfilePassword)