import getTextDate from '../helpers/getTextDate'
import { privacyPolicyFr } from './specialeLocales/privacyPolicyFr'
import { termsFr } from './specialeLocales/termsFr'

export const fr = {
  'undefined': 'undefined',
  'demo.title': 'Rejoignez-nous pour une demo',
  'demo.description': `1. Sélectionnez la date.{br}
    2. N'hésitez pas à inviter des collègues de votre entreprise!{br}
    3. Le jour de l'événement, nous vous enverrons un lien "Zoom" pour nous rejoindre.
    `,
  'demo.defaultDetailsTitle': 'Rencontrez nous!',
  'demo.defaultDetailsContent': 'Durant cette session de démonstration live, rencontrez notre équipe et découvrez notre activité "SIG-Enquête de haut vol"!',
  'demo.book': 'Réserver',
  'demo.info.firstName': 'Prénom',
  'demo.info.lastName': 'Nom',
  'demo.info.email': 'Email',
  'demo.info.phone': 'Téléphone',
  'demo.open': 'Disponible',
  'demo.free': 'Gratuit',
  'demo.confirm': 'Confirmer',
  'demo.cancel': 'Annuler',
  'demo.tryAgain': 'Essayer à nouveau',
  'demo.bookingConfirmed': 'Réservation confirmée.',
  'demo.capacity': 'Capacité',
  'demo.location': 'Lieu',
  'demo.location.value': "Zoom (Nous vous enverrons une invitation Calendar)",
  'demo.date.sig.title': 'Mission SIG',
  'demo.goHome': `Retourner à la page d'accueil`,
  'error.undefinedKey': 'Erreur: message indéfinie',
  'error.invalidEmail': 'Erreur: adresse email invalide',
  'error.emailTaken': 'Erreur: adresse email déjà utilisée',
  'error.usernameTooShort': 'Votre nom doit contenir plus de 3 caractères',
  'error.usernameOrPasswordIncorrect': `Erreur: adresse email ou mot de passe incorrect`,
  'error.tokenIncorrect': 'Erreur: jeton incorrect',
  'error.gameLockUnfound': `Verrou de jeu non trouvé`,
  'error.selectedArticleUnfound': `Article non trouvé`,
  'error.pageUnfound': 'Page non trouvée',
  'notification.registrationSuccessful': 'Inscription réussie',
  'notification.ipCopied': 'IP copiée avec succès',
  'features.title': 'Fonctionnalités et avantages',
  'features.section1.catchphrase': `Pourquoi nous choisir?`,
  'features.section1.description': `Découvrez des jeux d'évasion en ligne palpitants, conçus par des experts, qui s'adaptent à votre budget.
    Avec notre garantie de satisfaction, vivez une aventure inoubliable !`,
  'home.section1.slogan': `ÊTES-VOUS PRÊT?`,
  'home.section1.button': 'Voir les missions',
  'homepage.catchphrase': `Le film d'action interactif qui vous met au défi!`,//`Le défi d'une escape room, {br}100% en ligne !`,
  'home.section1.trial': 'ESSAYER LA MISSION DE DECOUVERTE (10min)',
  'home.section1.playNow': 'JOUER MAINTENANT',
  'home.section1.organiseEvent': 'ORGANISER UN EVENEMENT',
  'home.section1.miniGames': 'Enrichi de micro-activités',
  'home.section1.duration': 'De 20 à 60 minutes',
  'home.section1.unlimitedPlayers': 'Sans limite de joueurs',
  'home.section1.howDoesItWork': 'Comment ça marche?',
  'home.section1.headerSolo': 'EN SOLO',
  'home.section1.howItWorksSolo': `<li>Jo vous briefera au plus vite. Elle est exigente mais votre survie en dépend.</li>
                <li>Vous devrez parcourir des documents et utiliser des micro-applications pour atteindre votre objectif.</li>
                <li>Un seul mot d’ordre: foncez!</li>`,
  'home.section1.goMissionsSolo': 'Missions en solo',
  'home.section1.headerMulti': 'EN ÉQUIPE',
  'home.section1.howItWorksMulti': `<li>Regroupez-vous dans une visio  Zoom, Google Meet ou encore Teams.</li>
                <li>Créez une session sur Discape pour que vos coéquipiers vous rejoignent.</li>
                <li>Lorsque tous les coéquipiers ont rejoint, lancez la session.</li>
                <li>Vous pourrez alors accomplir les micro-applications et votre objectif.</li>
                <li>Un seul mot d’ordre: foncez!</li>`,
  'home.section1.goMissionsMulti': 'Missions en équipe',
  'home.sectionMissions.featuredMissions': 'Missions en vedette',
  'home.sectionMissions.viewAllMissions': 'Voir toutes les missions',
  'home.section2.description': `Discape est un film interactif de 15 à 60 minutes ! Vous devrez mener l'enquête à propos d'un hacker Londonien ! Faites équipes pour trouver des indices et résoudre des puzzles!`,
  'home.sectionNotReady.notReady': 'Pas encore prêt?',
  'home.sectionNotReady.tryOurFreeSoloDemo': 'Essayez notre démo gratuite',
  'home.sectionNotReady.joinOurLiveDemo': 'Rejoignez une session démo multijoueur',
  'homepage.descriptionSection.title': 'Dans quelques minutes, plongez dans une mission captivante !',
  'homepage.descriptionSection.longDescription': `Prêt pour une aventure façon escape room ? Discape propose un jeu d'aventure à domicile rempli de codes à déchiffrer,
    de pistes à découvrir et d'énigmes à résoudre. Si vous aimez les escape rooms, vous adorerez naviguer à travers ces
    mystères en ligne. La réussite exigera du raisonnement déductif, de la réflexion critique et un brin d'astuce !
    Les joueurs peuvent se lancer dans leur mission à leur convenance, en mettant en pause, en sauvegardant leur progression
    et en reprenant quand ils le souhaitent.`,
  'teambuilding.section1.title': 'TEAMBUILDING',
  'teambuilding.section1.catchphrase': `Développez vos équipes:{br}
    les activités les plus dynamiques en ligne`,
  'teambuilding.section1.button': `Contacter l'équipe événementielle`,
  'teambuilding.sectionKeyElements.gameMasters': `Équipe d'accueil dédiée`,
  'teambuilding.sectionKeyElements.duration': `Durée adaptable à vos besoins`,
  'teambuilding.sectionKeyElements.unlimitedPlayers': `Sans limite de joueurs`,
  'teambuilding.sectionMagic.title': 'Notre recette miracle',
  'teambuilding.sectionMagic.corporateSpirit.title': `1. L'expérience n°1 pour développer son équipe`,
  'teambuilding.sectionMagic.corporateSpirit.content': `Notre activité de teambuilding en ligne permet à tous les participants de jouer simultanément, favorisant ainsi une dynamique de groupe unique et engageante. En collaborant en temps réel pour résoudre des micro-activités, vos équipes renforceront leur cohésion tout en s'amusant, créant des liens durables au-delà des écrans. Créez des liens au-delà de la distance.`,
  'teambuilding.sectionMagic.hosts.title': `2. Nos hôtes sur-entrainés`,
  'teambuilding.sectionMagic.hosts.content': `Notre équipe est composée d'acteurs sur-entrainés par notre créatifs et nos managers. Vous serez entièrement pris en main.`,
  'teambuilding.sectionMagic.flexibility.title': `3. Flexibilité`,
  'teambuilding.sectionMagic.flexibility.content': `Notre escape game en ligne pour le teambuilding offre une flexibilité d'organisation inégalée, permettant de réunir facilement des équipes dispersées géographiquement sans contraintes de déplacement. Cette solution s'adapte à tous les emplois du temps et peut être mise en place rapidement, offrant ainsi une expérience de team building efficace et accessible, quelle que soit la situation de votre entreprise.`,
  'contact.title': 'Nous contacter',
  'contact.list.email': 'Email: <a>info@discapegame.com</a>',
  'contact.list.phone': 'Téléphone: <a>Planifier un appel</a>',
  'contact.send': 'Envoyer',
  'contact.info.messageHolder': 'Message',
  'contact.messageConfirmed': 'Votre message a été envoyé.',
  'contact.tryAgain': 'Essayer à nouveau',
  'faq.howDoesItWork': 'Comment ça marche?',
  'faq.howDoesItWorkText': `En équipe avec vos amis, vous devrez utiliser plusieurs appareils (téléphone ou ordinateur) connectés ensemble pour accomplir votre mission.
    Une fois la mission lancée, vous pourrez discuter ensemble sur le chat ou vous pourrez utiliser une application tierce pour discuter avec la voix.
    Pour toute demande d'assistance, contactez-nous à <a>info@discapegame.com</a>.`,
  'faq.willIBeGroupedWithStrangers': 'Est-ce que je serai groupé avec des inconnus?',
  'faq.willIBeGroupedWithStrangersText': `C'est vous qui contrôlez les participants qui sont dans votre session. Gardez la session comme étant "privé" pour éviter qu'un inconnu ne rejoigne.`,
  'faq.ageLimit': `Quel est l'âge limite?`,
  'faq.ageLimitText': `Nous recommandons nos jeux pour les plus de 12 ans. Nous autorisons les participants plus jeunes, mais certaines parties du contenu peuvent être trop difficiles pour eux.`,
  'faq.largeGroup': `Pouvez-vous accueillir de grands événements ou des fêtes d'entreprise?`,
  'faq.largeGroupText': `Absolument! Nous adorons héberger de grand événements et fêtes d'entreprise.
    Nous avons une équipe dédiée pour vous aider à coordonner tous les détails pour vous.
    Vous pouvez contacter notre équipe par email au <a>info@discapegame.com</a> pour plus d'information.`,
  'faq.howLongDoesAGameLast': `Combien de temps dure un jeu?`,
  'faq.howLongDoesAGameLastText': `Nos jeux ont des durées variables, allant de 20 à 60 minutes.`,
  'faq.gameLanguage': `Le jeu est-il en français et en anglais?`,
  'faq.gameLanguageText': `Les jeux sont jouables en français et en anglais. Vous pouvez changer de langue grâce à l'icône de la planète dans la barre du menu`,
  'faq.IHaveBeenDisconnected': `J'ai été déconnecté! Est-ce que je peux me reconnecter?`,
  'faq.IHaveBeenDisconnectedText': `Tout à fait. Nous avons conçu cette plateforme pour gérer tout type de déconnexion / reconnexion.
    Il faudra alors recharger la page avec la même adresse URL.`,
  'faq.isThereHelpAvailable': `Est-ce qu'on peut avoir de l'aide dans la mission?`,
  'faq.isThereHelpAvailableText': `Oui, vous pouvez accéder aux indices de la mission en cliquant sur le bouton d'aide.`,
  'faq.isMyDeviceCompatible': `Quel type d'appareil fonctionne?`,
  'faq.isMyDeviceCompatibleText': `L'application fonctionne sur ordinateur et tablette et téléphone intelligent plus récent que 2015.
    Si vous avez des problèmes techniques, contactez-nous à <a>info@discapegame.com</a>.`,
  'about.section1.title': 'À PROPOS DE Discape',
  'about.section1.content': `Nous hébergeons des événements de divertissement pour les entreprises et les particuliers.{br}{br}
    Notre mission: se dépasser par le fun.
    {br}{br}
    Discape apporte des expériences de qualité professionnelle dans une interface accessible et dynamique.`,
  'about.sectionLead.title': `Comment nous menons l'industrie?`,
  'about.sectionLead.1': '<b>Expertise en activité:</b> nous organisons des activités de plusieurs types différents, et travaillons en étroite relation avec des Escape Rooms ce qui nous permet de se maintenir au cœur du divertissement.',
  'about.sectionLead.2': `<b>Conçu avec amour en interne:</b> toutes nos activités sont pensées, développées et testées avec nos équipes pour assurer une subtile sophistication équilibrée.`,
  'about.sectionLead.3': `<b>Des hôtes d'événements exceptionnels:</b> Notre équipe d'animation  suit une formation continue pour exceller dans la facilitation. Plusieurs de nos hôtes sont des acteurs professionnels, des humoristes, des  artistes et des éducateurs, garantissant qui savent comment captiver un public.`,
  'about.sectionTeam.title': 'Notre équipe',
  'about.sectionTeam.ceo.name': 'Yvan Richer',
  'about.sectionTeam.ceo.title': 'Président',
  'about.sectionTeam.ceo.content': `Yvan a grandi en France puis a étudié la gestion de l'innovation à Montréal.
    Depuis toujours il imagine et conçoit des univers de jeux à partager avec ses amis autant dans le jeu vidéo que des soirées de fêtes.
    Après avoir fondé Vortex Escape Room, il évolue vers Discape pendant la Covid.`,
  'about.sectionTeam.coo.name': 'Émilie Beaudoin',
  'about.sectionTeam.coo.title': 'Directrice des opérations',
  'about.sectionTeam.coo.content': `Émilie a évolué à Montréal et s'est transformée en véritable moteur de la scène de théâtre d'impro.
    Elle imagine de nouveaux concepts qu'elle anime avec son équipe d'acteurs.`,
  'about.sectionTeam.1.name': 'Anne-Sophie Girard',
  'about.sectionTeam.1.title': 'Responsable Événementiel',
  'about.sectionTeam.2.name': 'Julien Roy',
  'about.sectionTeam.2.title': `Hôte d'événement`,
  'about.sectionTeam.3.name': 'Étienne Côté',
  'about.sectionTeam.3.title': `Hôtesse d'événement`,
  'template.button.state.save': 'Sauvegarder',
  'template.button.state.error': 'Erreur',
  'template.button.state.upToDate': 'À jour',
  'template.button.state.saved': 'Sauvegardé',
  'template.button.state.waiting': 'En attente',
  'template.button.submit': `Envoyer`,
  'template.button.home': `Accueil`,
  'template.button.copyLink': `Copier le lien`,
  'template.message.copiedLink': `Lien copié`,
  'dashboard.name': 'Accueil',
  'app.loading': 'Chargement',
  'dashboard.navBarTitle': 'Sélectionnez votre mission',
  'conversation.title': 'Conversation',
  'conversation.you': 'Vous',
  'conversation.instruction': 'Saisissez un message...',
  'conversation.joinedRoom': `{username} a rejoint la session`,
  'conversation.userLeftRoom': `{username} a quitté la session`,
  'conversation.disconnected': `{username} a été déconnecté`,
  'conversation.userChangedUsername': `{previousUsername} est renommé·e en {newUsername}`,
  'conversation.custom.joCongratzCorsair': `Jo: Vous avez lu à travers lui comme dans un livre, bravo.`,
  'conversation.custom.joFoundCryptedMessage': `Jo: De mon côté j'ai trouvé un message crypté, je viens de vous l'envoyer`,
  'navBar.profile': 'Profile',
  'navBar.logIn': 'Se connecter',
  'navBar.signUp': `S'inscrire`,
  'navBar.logOut': `Se déconnecter`,
  'navBar.settings': `Paramètres`,
  'navBar.leaveSession': `Quitter la session`,
  'auth.title.signUp': `S'inscrire`,
  'auth.title.logIn': `Se Connecter`,
  'auth.label.email': `Email`,
  'auth.label.oldPassword': `Ancien mot de passe`,
  'auth.label.newPassword': `Nouveau mot de passe`,
  'auth.label.password': `Mot de passe`,
  'auth.label.confirmation': `Confirmation`,
  'auth.label.verificationCode': `Code de vérification`,
  'auth.label.username': `Nom d'agent`,
  'auth.label.submit': `Envoyer`,
  'auth.label.home': `Accueil`,
  'auth.signUp.success': `Vous êtes maintenant enregistré et connecté.`,
  'auth.logIn.success': `Vous êtes maintenant connecté.`,
  'auth.logIn.passwordLost': `Mot de passe perdu?`,
  'auth.retrieval.newPassword': `Nouveau mot de passe`,
  'auth.retrieval.title': `Retrouver mon mot de passe`,
  'auth.retrieval.codeValidated': `Votre code a bien été validé!`,
  'auth.retrieval.enterNewPassword': `Veuillez entrer votre nouveau mot de passe:`,
  'auth.retrieval.creationSuccess': `Votre nouveau mot de passe a bien été enregistré!`,
  'profile.infos.tabTitle': `infos`,
  'profile.infos.title': `Infos`,
  'profile.password.tabTitle': `Mot de passe`,
  'profile.password.title': `Mot de passe`,
  'settings.title': `Paramètres`,
  'settings.language.name': 'Langue',
  'settings.musicVolume.name': `Volume de la musique`,
  'renameDialog.title': `Entrez votre nom d'agent`,
  'footer.mission': 'Se dépasser par le fun',
  'footer.company': 'Entreprise',
  'footer.services': 'Services',
  'footer.resources': 'Ressources',
  'footer.teambuilding': 'TeamBuilding',
  'footer.solo': 'Jeux en solo',
  'footer.team': 'Jeux en équipe',
  'footer.contact': 'Contact',
  'footer.email': '<a>info@discapegame.com</a>',
  'footer.faq': 'FAQ',
  'footer.about': 'À Propos',
  'footer.help': 'Aide',
  'footer.press': 'Presse',
  'footer.terms': 'Mentions légales',
  'footer.privacy': 'Vie Privée',
  'footer.copyright': '© 2024 DISCAPE',
  'missionList.breadcrumbsName': 'Liste des missions',
  'missionList.header': 'Choisissez votre mission',
  'missionList.headerSolo': 'EN SOLO',
  'missionList.headerMulti': 'EN ÉQUIPE',
  'missionList.headerSoloShort': 'SOLO',
  'missionList.headerMultiShort': 'ÉQUIPE',
  'missionList.headerMultiExplanation': `Rejoignez vos coéquipiers en ligne pour accomplir ensemble ces missions! {br}Cliquez pour en savoir plus`,
  'missionCatalog.details.playNow': 'Jouer',
  'missionCatalog.details.buyNow': 'Acheter',
  'missionCatalog.details.subscribeForRelease': 'Recevez les mises à jour',
  'missionCatalog.price': 'Prix',
  'missionCatalog.free': 'Gratuit',
  'missionCatalog.viewTrailer': 'Voir le brief',
  'missionCatalog.details.wip': 'Encore en développement',
  'missionCatalog.list.start': 'Voir plus',
  'missionDetails.startMission': 'Démarrer la mission',
  'missionDetails.joinOrCreateSession': 'Rejoindre ou créer une session',
  'sessionSelector.selectSession': 'Sélectionnez une session',
  'sessionSelector.joinSessionHeading': 'Rejoindre une session',
  'sessionSelector.createNewSessionSolo': 'Lancer en solo',
  'sessionSelector.createNewSession': 'Créer une nouvelle session',
  'sessionSelector.noSessionAvailable': 'Pas de session disponible.',
  'sessionSelector.createYours': 'Créez la vôtre!',
  'sessionSelector.explanation': `La session de votre coéquipier apparaîtra ici. Vous devrez compléter le code de sa session. Vous pouvez également créer une session et lui partager le code qui vous sera indiqué.`,
  'sessionSelector.enterLastDigits': 'Demandez au créateur de la session les 3 derniers caractères de la session',
  'sessionSelector.verify': 'Vérifier',
  'lobby.sharableLink': `Lien à partager`,
  'lobby.recommandedParticipantCount': `Participants recommandés:`,
  'lobby.duration': `Durée:`,
  'lobby.back': `Retour`,
  'lobby.you': `(Vous)`,
  'lobby.userList.title': `Participants:`,
  'lobby.startButton': 'Démarrer',
  'lobby.modeTitle': 'Mode: ',
  'lobby.modeSolo': 'SOLO',
  'lobby.modeTeam': 'ÉQUIPE',
  'userList.rename': 'Changer de nom',
  'userList.removeUserFromGroup': 'Retirer du groupe',
  'activity.common.name': 'Inconnue',
  'activity.common.timeLeft': 'Temps Restant:',
  'activity.treason.name': 'Une trahison explosive',
  'activity.treason.description': `Mission urgente: le sergent Vilamo a trahi nos rangs! Des
            indices nous laisse penser qu'il a pris le contrôle d'un
            logiciel militaire. Neutralisez-le!`,
  'activity.treason.endCondition1': ' avant décollage du missile.',
  'activity.treason.endCondition2': ` avant que le missile n'explose sur le bateau.`,
  'activity.coldGame.name': 'Menés en bâteau',
  'activity.coldGame.description': `Une menace masquée vous lance une série de défis, tous aussi meurtriers que dangereux. Vous n'avez pas le choix, il faudra être plus rapide que lui!`,
  'activity.coldGame.endCondition1': ` avant que la bombe JMX n'explose`,
  'activity.coldGame.endCondition2': ` avant que M n'achève Malory`,
  'activity.coldGame.endCondition3': ` avant que l'avion ne s'écrase`,
  'activity.coldGame.endCondition4': ` avant que la police n'arrive sur vous`,
  'activity.unknown.name': 'Bientôt disponible',
  'activity.unknown.description': `Nos informations sur ce dossier sont encore insuffisant, mais les premières données sont inquiétantes...`,
  'activity.chase.name': 'Chasser des fantômes',
  'activity.chase.description': `Vous recommencez votre enquête à zéro: d’où vient ce M, et quel est son objectif ?`,
  'app.satCell.title': `Carte des appels téléphoniques`,
  'app.satCell.selectHour': `Relevés du {date} (chaque point est un téléphone)`,
  'app.cyberCounter.description1': `Connecté à la cyber-attack. La séquence a été récupérée séparemment par les différents agents de votre équipe. Entrez la dans l'ordre. Les "?" sont des informations manquantes que vous pouvez compléter.`,  //Connected to cyber-attack. The sequence has been spread between the agents of your team:
  'app.cyberCounter.yourPersonnalActions': `Vos actions personnelles.`,  //Your personnal actions:
  'app.cyberCounter.counterAlreadyStarted': `Le contre est déjà lancé! Attendez que les autres agents terminent.`,
  'app.cyberCounter.timeOut': `Temps écoulé ! Essayez encore de contrer le hacking !`,
  'app.cyberCounter.wrongSymbol': `Mauvaise entrée, la session a été perdue.`,
  'app.cyberCounter.success': `Bravo ! Le hacking a été interrompu avec succès.`,
  'app.cyberCounter.goDashboard': `Retourner à l'accueil.`, //Return to dashboard
  'app.cyberCounter.needMorePlayers': `Vous devez être au moins deux agents connectés pour pirater le JMX.`,
  'app.vocalEffect.source': 'Source',
  'app.vocalEffect.none': 'Aucun',
  'app.vocalEffect.original': 'Original',
  'app.vocalEffect.effect1': 'Effet 1',
  'app.vocalEffect.effect2': 'Effet 2',
  'app.vocalEffect.penny': 'Agent Pennyworth',
  'app.vocalEffect.malory': 'Agent Malory',
  'app.vocalEffect.martin': 'Agent Martin',
  'app.binaryConverter.header': 'Convertisseur binaire',
  'app.cyberCounter.targetedIp': 'Adresse IP visée:',
  'app.cyberCounter.noAnswerOnThisIp': 'Pas de réponse à cette adresse IP',
  'app.cyberCounter.connectionSuccessful': 'Vous êtes connecté avec succès au JMX de la voiture.',
  'app.cyberCounter.connect': 'Se connecter',
  'app.cyberDetectorFrequency.frequence': `Fréquence`,
  'app.cyberDetector.noSignal': 'Aucun signal sur cette fréquence',
  'app.cyberDetector.requiresAnotherPlayer': 'Un autre agent doit se connecter à cette application pour écouter pendant votre cartographie.',
  'app.cyberDetector.cartographerPosition': 'Position du cartographe:',
  'app.cyberDetector.userListTitle': 'Postes des agents:',
  'app.cyberDetector.ALL_ROLES': `Vous pouvez analyser la direction se trouve le JMX, consulter les marqueurs et vous déplacer sur la carte.`,
  'app.cyberDetector.NAVIGATOR': `Navigateur. Déplacement de la carte.`,
  'app.cyberDetector.LISTENER&DECODER': `Détecteur. Analyse de la direction du JMX par rapport au cercle bleu et consultation des marqueurs.`,
  'app.cyberDetector.LISTENER': `Détecteur. Analyse de la direction du JMX par rapport au cercle bleu.`,
  'app.cyberDetector.DECODER': `Décodeur. Consultation des marqueurs.`,
  'app.cyberDetector.ERROR': `Il y a eu une erreur.`,
  'app.cyberDetector.WAITING': `En attente d'autres joueurs.`,
  'app.cyberDetector.instruction': `Quand vous avez l'adresse d'une voiture identifiée, utilisez l'application "Cyber Counter" pour la neutraliser.`,
  'app.flightList.title': 'Liste des vols en cours',
  'app.flightList.transporter': 'Transporteur',
  'app.flightList.flightNumber': 'Numéro',
  'app.flightList.from': 'Depuis',
  'app.flightList.to': 'Vers',
  'app.gmaps.search': 'Rechercher',
  'app.missilGouv.title': 'MissilGouv',
  'app.missilGouv.objectiveLocked': 'Cible confirmée: Bâteau Pétrolier; France',
  'app.missilGouv.remainingTime': 'Temps avant lancement: ',
  'app.missilGouv.timeBeforeImpact': 'Temps avant impact: ',
  'app.missilGouv.console': "CONSOLE DE LANCEMENT",
  'app.missilGouv.button1': 'Transfert données exploitation',
  'app.missilGouv.button2': 'Baliser les coordonnés extérieures',
  'app.missilGouv.button3': 'Provoquer les actions barrières',
  'app.missilGouv.button4': 'Répéter le code de dislocation',
  'app.missilGouv.button5': 'Équilibrer le niveau de Kérosène',
  'app.missilGouv.button6': 'Stabiliser les détecteurs',
  'app.missilGouv.emergencyInterface': `Interface de télémétrie`,
  'app.telemetry.telemetryOverload': `Surcharge de la télémétrie`,
  'app.telemetry.enterCode': `Essayer un code`,
  'endingPanel.template.missionSuccess': `Mission Réussie`,
  'endingPanel.template.missionFailed': `Mission Échouée`,
  'endingPanel.template.reportTitle': `Rapport`,
  'endingPanel.template.goHome': `Menu Principal`,
  'endingPanel.template.viewNext': `Prochaine mission`,
  'endingPanel.template.tryAgain': `Recommencer`,
  'endingPanel.template.interventionDuration': `Durée de l'intervention`,
  'endingPanel.1.reportContent': `Les agents {playerList} ont fait preuve de discernement, je les recommande pour la prochaine mission.`,
  'endingPanel.2.reportContent': `Les agents {playerList} sont maintenant en garde à vue, je suis en contact avec la police pour les faire sortir de là.`,
  'endingPanel.3.reportContent': `Un message de M nous indique qu'il a achevé Malory. Échec de la mission.`,
  'endingPanel.4.reportContent': `Nous venons de recevoir un signalement, une voiture a été retrouvée, explosée.`,
  'endingPanel.5.reportContent': `Le missile a explosé sur le bâteau. Aucune victime n'est à déplorer. Cependant les dégâts sur l'écosystème sont considérables en plus de causer un impact financier sur la compagnie, ainsi qu’à l’état Français.`,
  'endingPanel.6.reportContent': `Le missile a explosé dans les airs. Aucune victime ni aucun dommage matériel n'est à déplorer. Bravo!`,
  'endingPanel.7.reportContent': `Un avion à destination de Montréal vient d'exploser au large de l'océan atlantique.`,
  'datasheet.template1.descriptionTitle': 'Description',
  'datasheet.template1.behaviorTitle': 'Comportement',
  'datasheet.template1.conclusionTitle': 'Conclusion',
  'datasheet.jmx.name': `JMX`,
  'datasheet.jmx.descriptionContent': `Une fois implanté dans l'ordinateur de la voiture, le JMX attendra que la voiture atteigne 80km/h pour s'activer. Il perturbe alors les contrôles du véhicule et bloque les freins, le plus souvent menant à un accident mortel. Si ce n'est pas suffisant, il envoie une décharge dans le réservoir pour le faire exploser.`,
  'datasheet.jmx.useTitle': `Utilisation`,
  'datasheet.jmx.useContent': `Idéal pour faire disparaître quelqu'un en faisant croire à un accident. Placer le JMX dans l'ordinateur de bord, sur la pin 12.`,
  'datasheet.jmx.detectionTitle': `Détection`,
  'datasheet.jmx.detectionContent': `Le JMX émet un signal sur la bande AM en <b>615.55</b> que l'on peut capter à plus de 2000 km.`,
  'datasheet.jose.name': `José Paquin`,
  'datasheet.jose.descriptionContent': `Homme de peau blanche, barbe rasée, cheveux bruns frisés semi-courts. Il travaille comme contrôleur aérien à la tour de contrôle de l'aéroport Pierre-Elliott Trudeau à Montréal, Québec, Canada.`,
  'datasheet.jose.behaviorContent': `Il est arrivé souvent en retard au travail autour du 10 avril 2020. {br}
    Il a été appelé plusieurs fois par Nils au cours du dernier mois.{br}
    L'enquête a révélé des photos gênantes pour lui, mais rien de significatif jusqu'à maintenant.`,
  'datasheet.jose.conclusionContent': `J'ai demandé à la police locale de l'interroger, mais ça n'a rien donné. Je garde un oeil sur lui.`,
  'datasheet.judith.name': `Judith Rivoli`,
  'datasheet.judith.descriptionContent': `Femme de peau blanche, cheveux bruns longs et lisses. Ingénieure des système d'aération à la centrale nucléaire de Gravelines, en France.`,
  'datasheet.judith.behaviorContent': `Elle a reçu 3 appels de Nils. Sa supérieure a indiqué qu'elle a été particulièrement distraite autour du 5 avril 2020.`,
  'datasheet.judith.conclusionContent': `Interrogation par la police locale, elle a parlé d'un homme qui l'appelait souvent, mais ça n'a rien donné plus exactement. Je l'ai mise sur écoute.`,
  'datasheet.nils.name': `Nils Brown`,
  'datasheet.nils.descriptionContent': `Homme anglais de peau blanche, cheveux bruns courts. Il travaille au parlement de Londres, en Angleterre.`,
  'datasheet.nils.behaviorContent': `J'ai identifié des traces venant de son appartement lors de l'attaque de Paris. Il est impliqué mais je ne sais pas dans quoi. Il est allé en weekend à La Rochelle la semaine dernière.`,
  'datasheet.nils.conclusionContent': `Je le suis personnellement. Ses habitudes sont assez classiques: il fait ses courses le mardi, il ne voit pas grand monde. Il est souvent au téléphone.`,
  'datasheet.ariane.name': `Ariane Estapolo`,
  'datasheet.ariane.descriptionContent': `Femme de peau noire, cheveux bruns courts bouclés. Elle travaille à la Capital Bank, Angleterre.`,
  'datasheet.ariane.behaviorContent': `Elle a été contacté 6 fois par ce Nils Brown. Je n'arrive pas à comprendre leur relation.`,
  'datasheet.ariane.conclusionContent': `Suivie par la police locale. Pas d'activité suspecte.`,
  'datasheet.vilamo.name': `Sergent Eric Vilamo`,
  'datasheet.vilamo.descriptionContent': `Homme blanc de 1m 87. Il était sergent dans l'ANSSI française jusqu'en 2019, puis il a disparu sans laisser de trace.`,
  'datasheet.vilamo.behaviorContent': `Spécialisé dans les systèmes liés aux lancements de missiles, il a déjoué plusieurs attaques informatiques qui visaient les installations françaises. <b>On peut encore retrouver le symbole de son grade utilisé comme signature lors de ses succès.</b>`,
  'datasheet.vilamo.conclusionContent': `Les données indiquent qu'il a programmé le lancement d'un missile Russe sur un bateau pétrolier Français. Il faut l'arrêter à tout prix.`,
  'datasheet.joseMessage.name': `Message Crypté de Jose Paquin`,
  'datasheet.joseMessage.messageTitle': `Message reçu le 2 mai 2019`,
  'datasheet.joseMessage.messageContent': `Destination: 011110010111010101101100`,
  'enigma.1000.name': 'Êtes-vous sur de vouloir quitter la visio?',
  'enigma.template1.no': 'Non',
  'enigma.template1.yes': 'Oui',
  'enigma.template1.ok': 'Bien reçu',
  'enigma.template1.mainMenu': `Menu Principal`,
  'enigma.template1.restartMission': `Recommencer la mission`,
  'enigma.template1.viewNext': `Voir la mission suivante`,
  'enigma.0.name': `Secourir Malory`,
  'enigma.0.question': `Où se trouve-t-elle?`,
  'enigma.0.field0Name': `Quartier`,
  'enigma.0.field1Name': `Rue`,
  'enigma.0.valid': `C'est noté, j'envoie un agent tout de suite!`,
  'enigma.0.invalid': `Nos agents sont allés voir et n'ont rien trouvé. Êtes-vous certain?`,
  'enigma.1.name': `Contrer le JMX`,
  'enigma.1.question': `Trouvez comment se connecter au JMX et le contrer!`,
  'enigma.3.name': `Est-ce que je peux compter sur vous ?`,
  'enigma.4.name': `Trouver le contact de M`,
  'enigma.4.question': `M a dû contacter quelqu'un de l'aviation! L'avez-vous trouvé?`,
  'enigma.4.invalid': `Je ne trouve rien sur cette personne. Êtes-vous certain de son nom?`,
  'enigma.5.name': `Identifier le vol en danger`,
  'enigma.5.question': `Jo: J'ai découvert une séquence crypté dans les communications de José.
    Utilisez la pour trouver quel est l'avion visé par M`,
  'enigma.6.name': `Retrouver M`,
  'enigma.6.question': `Il a dû faire une erreur! Dans quelle ville et dans quel quartier se trouve M?`,
  'enigma.6.field0Name': `Ville`,
  'enigma.6.field1Name': `Quartier`,
  'enigma.7.name': `Qui est M?`,
  'enigma.7.question': `Attention, c'est une question décisive. Cela peut mettre en danger nos agents!`,
  'enigma.7.malory': 'Malory',
  'enigma.7.pennyworth': 'Pennyworth',
  'enigma.7.martin': 'Martin',
  'enigma.8.name': `Accès sécurisé`,
  'enigma.8.question': `Sergent Vilamo`,
  'enigma.9.name': `Alerte: Infiltration détectée`,
  'enigma.9.question': `Séquence piège reconnue
      Accélération du compte à rebours`,
  'enigma.11.name': `MISSION ÉCHOUÉE`,
  'enigma.11.question': `Le missile a explosé sur le bâteau. Aucune victime n'est à déplorer, mais les autorités accusent la Russie de l'attaque. La tension monte...`,
  'enigma.12.name': `Missile détruit`,
  'enigma.12.question': `Le missile a explosé dans les airs. Aucune victime n'est à déplorer, ni aucun dommage matériel. Bravo!`,
  'enigma.13.name': `Secourir Malory`,
  'enigma.13.question': `Malory vient de se faire jeter par la fenêtre! Elle doit être dans la rue, entourée de civils qui appellent les secours.
    Objectif: trouver dans quelle rue se trouve Malory.
    Utilisez le bouton "Secourir Malory" du menu de gauche quand vous l'aurez trouvée`,
  'enigma.14.name': `MISSION RÉUSSIE`,
  'enigma.14.question': `Vous avez gagné votre place au sein du SIG!`,
  'enigma.15.name': `Infiltrer le dossier crypté de Vilamo`,
  'enigma.15.question': `Il semble avoir utilisé une signature particulière.`,
  'enigma.15.validMessage': 'Objectif réussi: Vous avez réussi à accéder à son ordinateur!',
  'enigma.16.name': `Pirater le logiciel MissilGouv`,
  'enigma.16.question': `Vous devrez travailler en équipe pour reconstituer les deux fragments audio qu'il a laissé!`,
  'enigma.16.validMessage': `Accès à MissilGouv complété. {br} Missile lancé! {br} Prochain objectif: neutraliser le missile`,
  'enigma.17.name': `Neutraliser le missile`,
  'enigma.17.question': `La séquence de Vilamo était un piège!
    Le missile a été lancé!
    Il faut saturer la télémétrie du missile pour le faire exploser!`,
  'enigma.22.name': `SESSION INTROUVABLE`,
  'enigma.22.question': `La session sélectionnée est introuvable`,
  'enigma.23.name': `La compagnie aérienne`,
  'enigma.23.question': `José devait savoir quelque chose.La plupart des gens font des gestes particuliers quand ils ont quelque chose à se reprocher.
    Trouvez ce qui le trahi et trouvez la compagnie aérienne de l'avion visé par M!`,
  'enigma.24.name': `Retiré du groupe`,
  'enigma.24.question': `Vous avez été retiré du groupe par l'animateur`,
  'enigma.115.name': 'Fichiers audio verrouillé',
  'enigma.115.question': `Vous n'avez pas accès à ce fichier, mais votre coéquipiez aura peut-être accès.`,
  'fileTree.1000': 'Rappeler Joe',
  'fileTree.1001': `Dossier crypté de Vilamo`,
  'fileTree.1002': `MissilGouv 2.3`,
  'fileTree.1003': `Sergent Vilamo`,
  'fileTree.1004': `Carte transport - Sergent Vilamo`,
  'fileTree.1005': `Note Vilamo Part 1/2`,
  'fileTree.1006': `Note Vilamo Part 2/2`,
  'fileTree.1020': 'Photo de la base de lancement',
  'fileTree.1': `Fichiers M27`,
  'fileTree.10': `Notes de Malory`,
  'fileTree.11': `Notes ${getTextDate(-6)}`,
  'fileTree.12': `Notes ${getTextDate(-5)}`,
  'fileTree.13': `Notes ${getTextDate(-1)}`,
  'fileTree.102': `SatCell`,
  'fileTree.200': `Cyber Detector`,
  'fileTree.201': `Cyber Counter`,
  'fileTree.205': `Fiche: JMX`,
  'fileTree.302': `Suspects`,
  'fileTree.303': `Judith Rivoli`,
  'fileTree.304': `Nils Brown`,
  'fileTree.305': `José Paquin`,
  'fileTree.306': `Ariane Estapolo`,
  'fileTree.309': `José Paquin`,
  'fileTree.310': `Interrogatoire`,
  'fileTree.311': `Questions préliminaires`,
  'fileTree.312': `Interrogatoire`,
  'fileTree.313': `Fiche José Paquin`,
  'fileTree.314': `Arrestation`,
  'fileTree.315': `Arrestation 2`,
  'fileTree.316': `Fiançailles`,
  'fileTree.317': `Amant`,
  'fileTree.318': `Message Crypté José Paquin`,
  'fileTree.340': `Liste des vols`,
  'fileTree.341': `Convertisseur binaire`,
  'fileTree.400': `Concerts`,
  'fileTree.461': `Effets Vocaux`,
  'fileTree.480': `Carte`,
  'fileTree.449': `Les Cloches du Monde`,
  'fileTree.450': `Almudena, Madrid, Espagne`,
  'fileTree.451': `St. Agatha, Kalvarienberg, Allemagne`, //Alte Kirche Alendorf
  'fileTree.452': `Notre-Dame-de-l'Assomption, Cuzco, Pérou`,
  'fileTree.453': `BigBen, Londres, Angleterre`,
  'fileTree.454': `Cathédrale de Santander, Santander, Espagne`,
  'fileTree.455': `Santi Apostoli, Florence, Italie`,
  'fileTree.456': `St.Mary Le Bow, Londres, Angleterre`,
  'fileTree.457': `Église St.Pauls, Londres, Angleterre`,
  'fileTree.458': `Westerkerk, Amsterdam, Pays-Bas`,
  'fileTree.460': `Enregistrements de M`,
  'fileTree.465': `M Enregistrement 1`,
  'fileTree.462': `M Enregistrement 2`,
  'fileTree.463': `M Enregistrement 3`,
  'fileTree.464': `M Enregistrement 4`,
  'menuItem.0': `Tableau de bord`,
  'menuItem.1': `Réserver une expérience`, // Divider
  'menuItem.2': `Expériences virtuelles`,
  'menuItem.6': `FAQ`,
  'menuItem.10': `Teambuilding`, // Divider
  'menuItem.11': `Assister à une démo`,
  'menuItem.12': `À propos de nous`,
  'menuItem.14': `Fonctionnalités et avantages`,
  'menuItem.16': `Structure de prix`,
  'menuItem.20': `Nous Contacter`, // Divider
  'menuItem.22': `Organiser une rencontre`,
  'menuItem.24': `Nous Contacter`,
  'menuItem.105': `Apps`,
  'menuItem.100': `Tableau de bord`,
  'menuItem.103': `Fichiers M27`,
  'menuItem.106': `SatCell`,
  'menuItem.107': `CyberCounter`,
  'menuItem.104': `Objectifs`,
  'menuItem.101': `Secourir Malory`,
  'menuItem.102': `Contrer le JMX`,
  'menuItem.110': `Trouver le contact`,
  'menuItem.111': `Retrouver le vol en danger`,
  'menuItem.112': `Retrouver M`,
  'menuItem.113': `Qui est M?`,
  'menuItem.114': `Quitter`,
  'menuItem.115': `Trouver la compagnie`,
  'menuItem.200': `Apps`,
  'menuItem.201': `Tableau de bord`,
  'menuItem.210': `Indices`,
  'menuItem.211': 'MissilGouv',
  'menuItem.205': `Objectifs`,
  'menuItem.202': `Infiltrer le dossier crypté`,
  'menuItem.203': `Pirater MissilGouv`,
  'menuItem.204': `Empêcher le décollage`,
  'menuItem.206': `Neutraliser le missile`,
  'helpPage.skip': `Passer cette étape`,
  'helpPageE1.title': `Compléments d'enquête`,
  'helpPageE1.subtitle': `À lire lorsque vous n'avez plus l'ombre d'une piste...`,
  'helpPageE1.hint1.title': `Accéder à l'ordinateur de Vilamo`,
  'helpPageE1.hint1.content': `Dans la fiche de Vilamo, il est indiqué qu'il utilise le symbole de son grade comme signature. Regardez l'image de sa carte de transport: vous verrez une forme jaune sur fond rouge. C'est le symbole que vous pourrez utiliser pour décrypter le dossier.`,
  'helpPageE1.hint2.title': `Pirater MissilGouv`,
  'helpPageE1.hint2.content': `<p>Dans le dossier "Ordinateur de Vilamo", vous trouverez un document "Note Vilamo Part 1/2" ou "Note Vilamo Part 2/2".
                  Comme vous êtes au moins de agents, l'un doit avoir le premier, et l'autre le deuxième.
                  Écoutez ces enregistrements: ils se complètent.
                  Ainsi, le premier enregistrement vous donne 3, *, 2, *, 6 et *.
                  Le deuxième vous donne *, 5, *, 4, * et 1.
                  En combinant les deux, vous aurez 3, 5, 2, 4, 6 et 1.
                </p>
                <p>
                  Maintenant allez tous dans l'application MissilGouv. L'un doit pouvoir cliquer sur les boutons, l'autre peut lire des chiffres devant chaque bouton.
                  Ce sera à celui qui peut lire les chiffres d'indiquer à l'autre agent sur quels boutons appuyer.
                </p>
                <p>
                  La séquence de bouton sera alors:
                  3. Provoquer les actions barrières
                  5. Équilibrer le niveau de Kérozène
                  2. Baliser les coordonnées extérieures
                  4. Répéter le code de dislocation
                  6. Stabiliser les détecteurs
                  1. Transfert données exploitation
                </p>`,
  'helpPageE1.hint3.title': `Surcharger la télémétrie`,
  'helpPageE1.hint3.content': `<p>
                  La missile a décollé ! Allez dans le panneau de télémétrie depuis MissilGouv.
                  L'un d'entre vous a un mot, l'autre une zone de texte. Il faudra transmettre les mots rapidement pour réussir à surcharger la télémétrie.
                  Mais attention: les rôles s'inversent parfois!
                </p>`,
  'helpPageE2.title': `Compléments d'enquête`,
  'helpPageE2.subtitle': `À lire lorsque vous n'avez plus l'ombre d'une piste...`,
  'helpPageE2.hint1.title': `Retrouver Malory - Quartier`,
  'helpPageE2.hint1.content': `Malory a laissé 3 vidéo-notes, écoutez les pour retrouver dans quel quartier elle se trouve.`,
  'helpPageE2.hint2.title': `Retrouver Malory - Rue`,
  'helpPageE2.hint2.content': `Malory se trouve à Lambeth. Observez les appels téléphoniques de la zone grâce à SatCell. Dans ce quartier, il semble y avoir dans les dernières minutes une forte concentration d'appels dans une rue spécifique.`,
  'helpPageE2.hint3.title': `Retrouver Malory - Solution`,
  'helpPageE2.hint3.content': `On peut observer une forte concentration dans les dernières minutes à Newport street.`,
  'helpPageE2.hint4.title': `Contrer le JMX - Trouver la fréquence`,
  'helpPageE2.hint4.content': `Les JMX utilisent une fréquence particulière: essayez de la retrouver dans la fiche du JMX. Vous pourrez entrer cette fréquence dans CyberDetector`,
  'helpPageE2.hint5.title': `Contrer le JMX - Identifier la voiture piégée`,
  'helpPageE2.hint5.content': `<p>Une fois la fréquence 615.55 entrée, dans CyberDetector, l'un des joueurs verra des points sur la carte, et l'autre verra les 4 directions cardinales.</p>
                <p>En cliquant sur ces directions, celui-ci peut entendre dans quelle direction se trouve le JMX par rapport au cercle bleu au centre de l'écran du joueur qui a la carte.</p>`,
  'helpPageE2.hint6.title': `Contrer le JMX - Désactiver le JMX`,
  'helpPageE2.hint6.content': `<p>Grâce à la coopération des deux agents, vous aurez trouvé la voiture sur l'autoroute, ayant pour adresse IP: "201.204.93.168".</p>
                <p>En entrant cette adresse sur "Cyber Counter", vous serez connecté. Vous verrez alors une liste de symbole qui est la même pour toute votre équipe, mais certains sont masqués pour vous.</p>
                <p>Vous aurez aussi vos actions personnelles et vous êtes les seuls à les avoir.</p>
                <p>Il faudra décrire aux autres agents quels sont les formes de la séquence, dans l'ordre.</p>
                <p>Mais faites-vite: vous avez un temps limité avant que la séquence ne se remette à zéro.</p>`,
  'helpPageE2.hint7.title': `Identifier le vol en danger - Trouver le contact`,
  'helpPageE2.hint7.content': `Dans le dossier M27, vous trouverez la liste des suspects. L'un d'eux a travaille comme contrôleur aérien.`,
  'helpPageE2.hint8.title': `Identifier le vol en danger - Analyser José`,
  'helpPageE2.hint8.content': `<p>Dans la partie "Questions préliminaires" de l'interrogatoire, José fait un geste particulier quand il ment.</p>
                <p>Peut-être saurez-vous vérifier certaines des réponses grâce aux éléments de l'enquête.</p>`,
  'helpPageE2.hint9.title': `Identifier le vol en danger - Discerner le mensonge`,
  'helpPageE2.hint9.content': `<p>En y regardant de plus près, José se gratte le menton à chaque fois qu'il ment!</p>
                <p>Dans la vidéo "interrogatoire", il répond à une question concernant des compagnies aériennes qui révèle quelque chose.</p>
                <p>Par ailleurs, vous trouverez un message crypté qui semble contenir la destination du vol.</p>`,
  'helpPageE2.hint10.title': `Identifier le vol en danger - Solution`,
  'helpPageE2.hint10.content': `<p>En combinant la compagnie aérienne Corsair révélée par José et la destination du vol (Montréal - YUL) du message décrypté, vous pourrez retrouver le vol dans la liste des vols en cours.</p>
                <p>Le vol est bien: GK 452</p>`,
  'helpPageE2.hint11.title': `Retrouver M - Ville et quartier`,
  'helpPageE2.hint11.content': `L'un des messages de M semble avoir un bruit de fond: on entend une église retentir. saurez-vous la retrouver ?`,
  'helpPageE2.hint12.title': `Retrouver M - Qui est M?`,
  'helpPageE2.hint12.content': `<p>M est bien dans Londres, à Cheapside. Et c'est l'un des 3 agents du SIG présents là bas: Pennyworth, Martin ou Malory.</p>
                <p>En appliquant des effets sur leurs voix, essayer d'identifier lequel a la voix la plus proche de M.</p>`,
  'terms.content': termsFr,
  'privacyPolicy.content': privacyPolicyFr,

}