import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { userActions } from '../actions/userActions'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Frame, Heading, Link, Button, Loading, Words } from 'arwes'
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ProfilePassword from './ProfilePassword'
import ProfileInfos from './ProfileInfos'
import { injectIntl, FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    width: '100%',
    margin: 'auto',
    '@media (min-width: 800px)': {
      maxWidth: 'calc(100vw - 32px)',
    }
  },
  content: {
    padding:16,
    width:'100%',
  },
  heading: {
    marginTop:12,
  },
  value: {
    minWidth: 150,
  },
  table: {
    margin: 'auto',
    marginBottom:20,
    '& td': {
      padding: 10,
      // display: 'inline-flex',
    }
  },
  box: {
    position:'relative',
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.box}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

var ProfilePage = ({ intl }) => {
  const classes = useStyles();

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    if(index > 1)
      index = 1;
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <Frame
        animate
        show={true}
        level={3}
        hover={false}
        corners={4}
        layer='primary'
      >
        <div className={classes.content}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label={intl.formatMessage({id:'profile.infos.tabTitle'})} {...a11yProps(0)} />
            <Tab label={intl.formatMessage({id:'profile.password.tabTitle'})} {...a11yProps(1)} />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <ProfileInfos/>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <ProfilePassword/>
            </TabPanel>
          </SwipeableViews>
        </div>
      </Frame>
    </div>
  )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => {
  return {
  }
}

ProfilePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage)

export default injectIntl(ProfilePage)