/*
 * Mission Data: name, id
 */
import {missionTypes} from 'discapecommon/consts/missionTypes'
import defaultMissionArticles from '../consts/missionArticles'
/*
Liste des missions disponibles
*/

const missionArticles = (state = defaultMissionArticles, action) => {
  switch (action.type) {
    // case missionTypes.MISSION_UPDATE_INFO:
    //   return state.map(mission => {
    //     if(mission.key == action.mission.key) {
    //       // console.log('action ', action.mission)
    //       return {...mission, ...action.mission}
    //     }
    //     else
    //       return mission;
    //   })
    default:
      return state
  }
}

export default missionArticles
