import getTextDate from '../helpers/getTextDate'
import { privacyPolicyEn } from './specialeLocales/privacyPolicyEn'
import { termsEn } from './specialeLocales/termsEn'

export const en = {
  'demo.title': 'Join Us For A Demo',
  'demo.description': `1. Select your demo date below (times are shown in your current time zone).{br}
    2. You are welcome to bring a guest from your organization! This is not meant to be a full-team demo.{br}
    3. On the day of your demo, we will email you a Zoom link to join.
    `,
  'demo.defaultDetailsTitle': 'Rencontrez nous!',
  'demo.defaultDetailsContent': 'Meet our event team during this live demo and discover our activity "SIG-Investigation"!',
  'demo.book': 'Book Now',
  'demo.info.firstName': 'First Name',
  'demo.info.lastName': 'Last Name',
  'demo.info.email': 'Email',
  'demo.info.phone': 'Phone',
  'demo.open': 'Open',
  'demo.free': 'Free',
  'demo.confirm': 'Confirm',
  'demo.cancel': 'Cancel',
  'demo.tryAgain': 'Try Again',
  'demo.bookingConfirmed': 'Booking confirmed',
  'demo.capacity': 'Capacity',
  'demo.location': 'Place',
  'demo.location.value': "Zoom (We'll send you a calendar invite after booking)",
  'demo.date.sig.title': 'SIG Mission',
  'demo.goHome': `Go back to homepage`,
  'error.undefinedKey': 'Error: message undefined',
  'error.invalidEmail': 'Error: invalid email address',
  'error.emailTaken': 'Error: email address already in use',
  'error.usernameTooShort': 'Username must be longer than 3 characters',
  'error.usernameOrPasswordIncorrect': 'Error: email address or password incorrect',
  'error.tokenIncorrect': 'Error: token incorrect',
  'error.gameLockUnfound': `Unable to find selected game lock`,
  'error.selectedArticleUnfound': `Unable to find selected game article`,
  'error.pageUnfound': 'Page unfound',
  'notification.registrationSuccessful': 'Registration successful',
  'notification.ipCopied': 'IP copied successfully',
  'features.title': 'Features and benefits',
  'features.section1.catchphrase': `Why choose us?`,
  'features.section1.description': `Discover exciting online escape games crafted by experts that fit your budget.
    With our satisfaction guarantee, enjoy an unforgettable adventure or get your money back!`,
  'home.section1.slogan': 'Do you accept the mission?',
  'home.section1.button': 'VIEW MISSIONS',
  'homepage.catchphrase': 'The thrill of an action movie, {br}the fun of an escape game! {br}100% online',
  'home.section1.trial': 'TRIAL MISSION (10min)',
  'home.section1.playNow': 'PLAY NOW',
  'home.section1.organiseEvent': 'ORGANISE AN EVENT',
  'home.section1.miniGames': 'Filled with mini-games',
  'home.section1.duration': 'From 20 to 60 minutes',
  'home.section1.unlimitedPlayers': 'Unlimited players',
  'home.section1.howDoesItWork': 'How does it work?',
  'home.section1.headerSolo': 'SOLO',
  'home.section1.howItWorksSolo': `<li>Jo will brief you as soon as possible. She's demanding, but your survival depends on it.</li>
                <li>You'll need to go through documents and use micro-applications to reach your objective.</li>
                <li>Only one watchword: go for it!</li>`,
  'home.section1.goMissionsSolo': 'Solo missions',
  'home.section1.headerMulti': 'TEAM',
  'home.section1.howItWorksMulti': `<li>Gather in a video call on Zoom, Google Meet, or Teams.</li>
                <li>Create a session on Discape for your teammates to join.</li>
                <li>When all teammates have joined, start the session.</li>
                <li>You'll then be able to complete the micro-applications and your objective.</li>
                <li>Only one watchword: go for it!</li>`,
  'home.section1.goMissionsMulti': 'Team missions',
  'home.sectionMissions.featuredMissions': 'Featured missions',
  'home.sectionMissions.viewAllMissions': 'View all missions',
  'home.section2.description': `Discape is a 60-minute remote escape game! You'll have to investigate a London hacker! Team up to find clues and solve puzzles!`,
  'home.sectionNotReady.notReady': 'Not ready yet?',
  'home.sectionNotReady.tryOurFreeSoloDemo': 'Try our free solo demo',
  'home.sectionNotReady.joinOurLiveDemo': 'Join a multiplayer demo session',
  'homepage.descriptionSection.title': 'In a few minutes, dive into a captivating mission.',
  'homepage.descriptionSection.longDescription': `Ready for an Escape Room Adventure?
    Discape offers a home-based adventure game filled with codes to decipher, clues to uncover, and puzzles to solve. If you love escape rooms, you'll adore navigating through these online mysteries.
    Success will require deductive reasoning, critical thinking, and a touch of cleverness! Players can embark on their mission at their convenience, pausing, saving their progress, and resuming whenever they wish.
    {br}Key Features:
    <list>
      <li>Immersive escape room experience from the comfort of your home</li>
      <li>Challenging codes, clues, and puzzles to solve</li>
      <li>Engaging storyline and mysteries to unravel</li>
      <li>Flexible gameplay: pause, save, and resume at your convenience</li>
      <li>Ideal for escape room enthusiasts and puzzle lovers</li>
      <li>Encourages logical thinking, problem-solving, and teamwork</li>
    </list>
    Get ready to put your detective skills to the test and dive into the thrilling world of Discape's online adventure game!`,
  'teambuilding.section1.title': 'TEAMBUILDING',
  'teambuilding.section1.catchphrase': `Develop your teams:{br}
        the most dynamic online activities`,
  'teambuilding.section1.button': `Contact the event team`,
  'teambuilding.sectionKeyElements.gameMasters': `Dedicated hosting team`,
  'teambuilding.sectionKeyElements.duration': `Duration adaptable to your needs`,
  'teambuilding.sectionKeyElements.unlimitedPlayers': `No player limit`,
  'teambuilding.sectionMagic.title': 'Our miracle recipe',
  'teambuilding.sectionMagic.corporateSpirit.title': `1. The #1 experience for team development`,
  'teambuilding.sectionMagic.corporateSpirit.content': `Our online teambuilding activity allows all participants to play simultaneously, fostering a unique and engaging group dynamic. By collaborating in real-time to solve micro-activities, your teams will strengthen their cohesion while having fun, creating lasting bonds beyond the screens. Create connections beyond distance.`,
  'teambuilding.sectionMagic.hosts.title': `2. Our highly-trained hosts`,
  'teambuilding.sectionMagic.hosts.content': `Our team consists of actors highly trained by our creatives and managers. You will be fully taken care of.`,
  'teambuilding.sectionMagic.flexibility.title': `3. Flexibility`,
  'teambuilding.sectionMagic.flexibility.content': `Our online escape game for teambuilding offers unparalleled organizational flexibility, allowing geographically dispersed teams to easily come together without travel constraints. This solution adapts to all schedules and can be set up quickly, offering an effective and accessible team building experience, regardless of your company's situation.`,
  'contact.title': 'Contact us',
  'contact.list.email': 'Email: <a>info@discapegame.com</a>',
  'contact.list.phone': 'Phone: <a>Schedule a call</a>',
  'contact.send': 'Send',
  'contact.info.messageHolder': 'Message',
  'contact.messageConfirmed': 'Your message has been sent.',
  'contact.tryAgain': 'Try again',
  'faq.howDoesItWork': 'How does it work?',
  'faq.howDoesItWorkText': `Team up with your friends, you will need to use multiple devices (phones or computers) connected together to accomplish your mission.
    Once the mission is launched, you can chat together or use a third-party app for voice chat.
    For any assistance, contact us at <a>info@discapegame.com</a>.`,
  'faq.willIBeGroupedWithStrangers': 'Will I be grouped with strangers?',
  'faq.willIBeGroupedWithStrangersText': 'You control who participates in your session. Keep the session "private" to prevent strangers from joining.',
  'faq.ageLimit': `What is the age limit?`,
  'faq.ageLimitText': `We recommend our games for ages 12 and up. We allow younger participants, but some parts of the content may be too difficult for them.`,
  'faq.largeGroup': `Can you accommodate large events or corporate parties?`,
  'faq.largeGroupText': `Absolutely! We love hosting large events and corporate parties.
    We have a dedicated team to help coordinate all the details for you.
    You can contact our team by email at <a>info@discapegame.com</a> for more information.`,
  'faq.howLongDoesAGameLast': `How long does a game last?`,
  'faq.howLongDoesAGameLastText': `Our games have varying lengths, ranging from 20 to 60 minutes.`,
  'faq.gameLanguage': `Is the game available in French and English?`,
  'faq.gameLanguageText': `The games are playable in French and English. You can change the language using the planet icon in the menu bar.`,
  'faq.IHaveBeenDisconnected': `I got disconnected! Can I reconnect?`,
  'faq.IHaveBeenDisconnectedText': `Absolutely. We designed this platform to handle any disconnection/reconnection.
    You just need to reload the page with the same URL.`,
  'faq.isThereHelpAvailable': `Is there help available during the mission?`,
  'faq.isThereHelpAvailableText': `Yes, you can access mission hints by clicking the help button.`,
  'faq.isMyDeviceCompatible': `What type of device works?`,
  'faq.isMyDeviceCompatibleText': `The app works on computers, tablets, and smartphones newer than 2015.
    If you have any technical issues, contact us at <a>info@discapegame.com</a>.`, 'about.section1.title': 'ABOUT Discape',
  'about.section1.content': `We host entertainment events for businesses and individuals.{br}{br}
    Our mission: surpass oneself through fun.
    {br}{br}
  Discape brings professional-quality experiences in an accessible and dynamic interface.`,
  'about.sectionLead.title': `How do we lead the industry?`,
  'about.sectionLead.1': '<b>Activity expertise:</b> we organize activities of several different types, and work closely with Escape Rooms which allows us to stay at the heart of entertainment.',
  'about.sectionLead.2': `<b>Designed with love in-house:</b> all our activities are conceived, developed and tested with our teams to ensure a subtle balanced sophistication.`,
  'about.sectionLead.3': `<b>Exceptional event hosts:</b> Our animation team undergoes continuous training to excel in facilitation. Several of our hosts are professional actors, comedians, artists and educators, ensuring they know how to captivate an audience.`,
  'about.sectionTeam.title': 'Our team',
  'about.sectionTeam.ceo.name': 'Yvan Richer',
  'about.sectionTeam.ceo.title': 'President',
  'about.sectionTeam.ceo.content': `Yvan grew up in France and then studied innovation management in Montreal.
  He has always imagined and designed game universes to share with his friends, both in video games and party nights.
  After founding Vortex Escape Room, he evolved towards Discape during Covid.`,
  'about.sectionTeam.coo.name': 'Émilie Beaudoin',
  'about.sectionTeam.coo.title': 'Chief Operating Officer',
  'about.sectionTeam.coo.content': `Émilie evolved in Montreal and transformed into a true driving force of the improv theater scene.
  She imagines new concepts that she animates with her team of actors.`,
  'about.sectionTeam.1.name': 'Anne-Sophie Girard',
  'about.sectionTeam.1.title': 'Event Manager',
  'about.sectionTeam.2.name': 'Julien Roy',
  'about.sectionTeam.2.title': `Event Host`,
  'about.sectionTeam.3.name': 'Étienne Côté',
  'about.sectionTeam.3.title': `Event Host`,
  'template.button.state.save': 'Save',
  'template.button.state.error': 'Error',
  'template.button.state.upToDate': 'Up to Date',
  'template.button.state.saved': 'Saved',
  'template.button.state.waiting': 'Waiting',
  'template.button.submit': `Send`,
  'template.button.home': `Home`,
  'template.button.copyLink': `Copy Link`,
  'template.message.copiedLink': `Link Copied`,
  'dashboard.name': 'Home',
  'app.loading': 'Loading',
  'dashboard.navBarTitle': 'Select your mission',
  'conversation.title': 'Conversation',
  'conversation.you': 'You',
  'conversation.instruction': 'Start typing here...',
  'conversation.joinedRoom': `{username} has joined the session`,
  'conversation.userLeftRoom': `{username} has left the session`,
  'conversation.disconnected': `{username} has been disconnected`,
  'conversation.userChangedUsername': `{previousUsername} renamed to {newUsername}`,
  'conversation.custom.joCongratzCorsair': `Jo: You read him like a book! Nice job.`,
  'conversation.custom.joFoundCryptedMessage': `Jo: On my end I found something, I just sent it to you`,
  'navBar.profile': 'Profile',
  'navBar.logIn': 'Log In',
  'navBar.signUp': `Sign Up`,
  'navBar.logOut': `Log Out`,
  'navBar.settings': `Settings`,
  'navBar.leaveSession': `Exit Session`,
  'auth.title.signUp': `Sign Up`,
  'auth.title.logIn': `Log In`,
  'auth.label.email': `Email`,
  'auth.label.oldPassword': `Old Password`,
  'auth.label.newPassword': `New Password`,
  'auth.label.password': `Password`,
  'auth.label.confirmation': `Confirmation`,
  'auth.label.verificationCode': `Verification Code`,
  'auth.label.username': `Username`,
  'auth.label.submit': `Submit`,
  'auth.label.home': `Home`,
  'auth.signUp.success': `You are now signed up and signed in.`,
  'auth.logIn.success': `You are now signed in.`,
  'auth.logIn.passwordLost': `Forgot password?`,
  'auth.retrieval.newPassword': `New password`,
  'auth.retrieval.title': `Retrieve password`,
  'auth.retrieval.codeValidated': `Your code was succesfully verified`,
  'auth.retrieval.enterNewPassword': `Please enter your new password:`,
  'auth.retrieval.creationSuccess': `Your password has been successfully changed!`,
  'profile.infos.tabTitle': `profile`,
  'profile.infos.title': `Profile`,
  'profile.password.tabTitle': `Password`,
  'profile.password.title': `Password`,
  'settings.title': `Settings`,
  'settings.language.name': 'Language',
  'settings.musicVolume.name': `Music Volume`,
  'renameDialog.title': 'Enter your agent name',
  'footer.mission': 'Surpass ourselves through fun.',
  'footer.company': 'Company',
  'footer.services': 'Services',
  'footer.resources': 'Resources',
  'footer.teambuilding': 'TeamBuilding',
  'footer.solo': 'Solo games',
  'footer.team': 'Games for groups',
  'footer.contact': 'Contact',
  'footer.email': '<a>info@discapegame.com</a>',
  'footer.faq': 'FAQ',
  'footer.about': 'About',
  'footer.help': 'Help',
  'footer.press': 'Press',
  'footer.terms': 'Terms',
  'footer.privacy': 'Privacy',
  'footer.copyright': '© 2024 DISCAPE',
  'missionList.breadcrumbsName': 'Mission list',
  'missionList.header': 'Choose your mission',
  'missionList.headerSolo': 'SOLO',
  'missionList.headerMulti': 'As a team',
  'missionList.headerSoloShort': 'SOLO',
  'missionList.headerMultiShort': 'TEAM',
  'missionList.headerMultiExplanation': `Join force with your teammate to accomplish breathtaking missions!! {br}Click for more details`,
  'missionCatalog.details.playNow': 'Play',
  'missionCatalog.details.buyNow': 'Buy',
  'missionCatalog.details.subscribeForRelease': 'Get Mission Updates',
  'missionCatalog.price': 'Price',
  'missionCatalog.free': 'Free',
  'missionCatalog.viewTrailer': 'View trailer',
  'missionCatalog.details.wip': 'Still in development',
  'missionCatalog.list.start': 'More',
  'missionDetails.startMission': 'Start mission',
  'missionDetails.joinOrCreateSession': 'Join or create a session',
  'sessionSelector.selectSession': 'Join active session',
  'sessionSelector.joinSessionHeading': 'Join a session',
  'sessionSelector.createNewSessionSolo': 'Solo',
  'sessionSelector.createNewSession': 'Create a new session',
  'sessionSelector.noSessionAvailable': 'No session available.',
  'sessionSelector.createYours': 'Create your own!',
  'sessionSelector.explanation': `This mission requires at least two agents connected on different devices. If your teamate has already created a session, it will appear below. Otherwise create a new session and share with them the link.`,
  'sessionSelector.enterLastDigits': 'Ask the creator of the session the 3 last characters of the session code.',
  'sessionSelector.verify': 'Verify',
  'lobby.sharableLink': `Lien à partager`,
  'lobby.recommandedParticipantCount': `Recommended Participants:`,
  'lobby.duration': `Duration:`,
  'lobby.back': `Back`,
  'lobby.you': `(You)`,
  'lobby.userList.title': `Participants:`,
  'lobby.startButton': 'Start',
  'lobby.modeTitle': 'Mode: ',
  'lobby.modeSolo': 'SOLO',
  'lobby.modeTeam': 'TEAM',
  'userList.rename': 'Rename',
  'userList.removeUserFromGroup': 'Remove from group',
  'activity.common.name': 'Unknown',
  'activity.common.timeLeft': 'Time Remaining:',
  'activity.treason.name': 'An Explosive Betrayal',
  'activity.treason.description': `Urgent Mission: Secret Agent Vilamo has betrayed our ranks! Some hints
        indicate he may have taken control of dangerous military
        software. Stop him before it's too late!`,
  'activity.treason.endCondition1': ' before the missile launches.',
  'activity.treason.endCondition2': ` before the missile blows up the boat.`,
  'activity.coldGame.name': 'Hook, Line, and Sinker',
  'activity.coldGame.description': `A masked menace throws you a series of challenges, each one more dangerous than the next. You don't have a choice, you'll have to be quicker than him!`,
  'activity.coldGame.endCondition1': ` before the JMX bomb explodes`,
  'activity.coldGame.endCondition2': ` before M finished Malory off`,
  'activity.coldGame.endCondition3': ` before the plane crashes`,
  'activity.coldGame.endCondition4': ` before the police comes for you`,
  'activity.unknown.name': 'Available Soon',
  'activity.unknown.description': `Our informations on this mission are insufficient to take action, but the first pieces of data are worrying...`,
  'activity.chase.name': 'Chasing the Wind',
  'activity.chase.description': `You start your investigation all over again: where does this mysterious M come from, and what is his purpose?`,
  'app.satCell.title': `Phone Calls Map`,
  'app.satCell.selectHour': `Choose a time`,
  'app.cyberCounter.description1': `Connected to cyber-attack. The sequence has been split between the agents of your team:`,  //Connected to cyber-attack. The sequence has been split between the agents of your team:
  'app.cyberCounter.yourPersonnalActions': `Your personal actions:`,  //Your personnal actions:
  'app.cyberCounter.counterAlreadyStarted': `The counterattack has been launched! Wait for the other agents to finish.`,
  'app.cyberCounter.timeOut': `Time's Up! Keep trying to counterattack the hacker!`,
  'app.cyberCounter.wrongSymbol': `Wrong symbol, the session was lost.`,
  'app.cyberCounter.success': `Congratulations! You have succesfully interrupted the hack.`,
  'app.cyberCounter.goDashboard': `Return to dashboard.`, //Return to dashboard
  'app.cyberCounter.needMorePlayers': `You must be at least two agents to start hacking the JMX.`,
  'app.vocalEffect.source': 'Source',
  'app.vocalEffect.none': 'None',
  'app.vocalEffect.original': 'Original',
  'app.vocalEffect.effect1': 'Effect 1',
  'app.vocalEffect.effect2': 'Effect 2',
  'app.vocalEffect.penny': 'Agent Pennyworth',
  'app.vocalEffect.malory': 'Agent Malory',
  'app.vocalEffect.martin': 'Agent Martin',
  'app.binaryConverter.header': 'Binary Converter',
  'app.cyberCounter.targetedIp': 'Targeted IP Address:',
  'app.cyberCounter.noAnswerOnThisIp': 'No answer at this IP address',
  'app.cyberCounter.connectionSuccessful': "You successfully connected to the car's JMX",
  'app.cyberCounter.connect': 'Connect',
  'app.cyberDetectorFrequency.frequence': `Frequency`,
  'app.cyberDetector.noSignal': 'No signal on this frequency',
  'app.cyberDetector.cartographerPosition': `Navigator's position:`,
  'app.cyberDetector.requiresAnotherPlayer': 'Another agent must be connected to the application in order to listen during your cartography.',
  'app.cyberDetector.ALL_ROLES': `Vous pouvez identifier dans quelle direction se trouve le JMX, consulter les marqueurs et vous déplacer sur la carte.`,
  'app.cyberDetector.NAVIGATOR': `Navigator. He can move the map.`,
  'app.cyberDetector.LISTENER&DECODER': `Détecteur. He can identify in which direction is the JMX compared to the blue circle and consult the markers.`,
  'app.cyberDetector.LISTENER': `Listener. He can identify in which direction is the JMX compared to the blue circle.`,
  'app.cyberDetector.DECODER': `Decoder. He can consult the markers.`,
  'app.cyberDetector.ERROR': `Error.`,
  'app.cyberDetector.WAITING': `Another agent must be connected to the application in order to listen during your cartography.`,
  'app.cyberDetector.instruction': `Once you have a targeted car identified, use the app "Cyber Counter" to neutralize it.`,
  'app.flightList.title': 'List of current flights:',
  'app.flightList.transporter': 'Transporter',
  'app.flightList.flightNumber': 'Number',
  'app.flightList.from': 'From',
  'app.flightList.to': 'To',
  'app.gmaps.search': 'Search',
  'app.missilGouv.title': 'MissilGouv',
  'app.missilGouv.objectiveLocked': 'Target confirmed: Petrolium Tanker; France',
  'app.missilGouv.remainingTime': 'Time before launch: ',
  'app.missilGouv.timeBeforeImpact': 'Time before impact: ',
  'app.missilGouv.console': "LAUNCH CONSOLE",
  'app.missilGouv.button1': 'Transfer exploitation data',
  'app.missilGouv.button2': 'Mark out exterior coordinates',
  'app.missilGouv.button3': 'Provoke barrier actions',
  'app.missilGouv.button4': 'Repeat dislocation code',
  'app.missilGouv.button5': 'Balance Kerosene levels',
  'app.missilGouv.button6': 'Stabilize detectors',
  'app.missilGouv.emergencyInterface': `Telemetry Interface`,
  'app.telemetry.telemetryOverload': `Telemetry Overload`,
  'app.telemetry.enterCode': `Try a code`,
  'endingPanel.template.missionSuccess': `Mission Success`,
  'endingPanel.template.missionFailed': `Mission Failed`,
  'endingPanel.template.reportTitle': `Report`,
  'endingPanel.template.goHome': `Home`,
  'endingPanel.template.viewNext': `Next mission`,
  'endingPanel.template.tryAgain': `Restart`,
  'endingPanel.template.interventionDuration': `Duration of the intervention`,
  'endingPanel.1.reportContent': `Agents {playerList} have shown excellent judgement, I recommend them for the next mission.`,
  'endingPanel.2.reportContent': `Agents {playerList} are now in jail, I'm contacting the police to get them out of there.`,
  'endingPanel.3.reportContent': `A message from M confirmed that he finished off Malory. Mission failure.`,
  'endingPanel.4.reportContent': `We just received word, a car has been found, blown up.`,
  'endingPanel.5.reportContent': `The missile blew up the boat. There were no victims, lucky. However the damage to the ecosystem is considerable, and both the company and the French state suffered financially.`,
  'endingPanel.6.reportContent': `The missile exploded in midair. There were no victims or physical damage. Bravo!`,
  'endingPanel.7.reportContent': `A plane destined for Montreal just blew up above the atlantic ocean.`,
  'datasheet.template1.descriptionTitle': 'Description',
  'datasheet.template1.behaviorTitle': 'Behaviour',
  'datasheet.template1.conclusionTitle': 'Conclusion',
  'datasheet.jmx.name': `JMX`,
  'datasheet.jmx.descriptionContent': `Once planted in a car's computer, the JMX will wait for the car to reach 80km/h to activate. It then hijacks the vehicle's controls and locks the brakes, most often leading to a fatal accident. If that isn't enough, it sends a charge into the fuel tank to cause an explosion.`,
  'datasheet.jmx.useTitle': `Use`,
  'datasheet.jmx.useContent': `Ideal in order to make someone dissapear while disguising it as an accident. Place the JMX on the car's computer, on pin 12.`,
  'datasheet.jmx.detectionTitle': `Detection`,
  'datasheet.jmx.detectionContent': `The JMX emits a signal on the AM frequency <b>615.55</b> which can be recieved from 2000km away.`,
  'datasheet.jose.name': `José Paquin`,
  'datasheet.jose.descriptionContent': `White skinned man, shaven beard, frizy brown short hair. He works as an air traffic controller at the Pierre-Elliott Trudeau airport of Montréal, Québec, Canada.`,
  'datasheet.jose.behaviorContent': `He often arrived late at work around the 10th of April 2020. {br}
    He was called several times by Nils throughout the last month.{br}
    The investigation revealed ashaming photos of him but nothing of value so far.`,
  'datasheet.jose.conclusionContent': `I asked the police to examinate him, but they found nothing. I keep an eye on him.`,
  'datasheet.judith.name': `Judith Rivoli`,
  'datasheet.judith.descriptionContent': `White skinned woman, brown long hair. Aeration system engineer at the Gravelines nuclear power plant, in France.`,
  'datasheet.judith.behaviorContent': `She received 3 calls from Nils. Her supervisor said she was particularly distracted around April 5, 2020.`,
  'datasheet.judith.conclusionContent': `When questioned by the local police, she spoke of a man who called her often, but nothing more exactly. I bugged her.`,
  'datasheet.nils.name': `Nils Brown`,
  'datasheet.nils.descriptionContent': `English man of white skin, short brown hair. He works at the parliament in London, England.`,
  'datasheet.nils.behaviorContent': `I identified traces coming from his apartment during the Paris attack. He's involved but I don't know how.`,
  'datasheet.nils.conclusionContent': `I am personally following him. His habits are fairly standard: he does his shopping on Tuesday, he doesn't see many people. He is often on the phone.`,
  'datasheet.ariane.name': `Ariane Estapolo`,
  'datasheet.ariane.descriptionContent': `Black skinned woman, short curly brown hair. She works at the parliament in London, England.`,
  'datasheet.ariane.behaviorContent': `She has been contacted 6 times by this Nils Brown. I can't understand their relationship.`,
  'datasheet.ariane.conclusionContent': `Followed by local police. No suspicious activity.`,
  'datasheet.vilamo.name': `Sergeant Eric Vilamo`,
  'datasheet.vilamo.descriptionContent': `White man 6 feet 2. He was sergeant in the French ANSSI until 2019, then he disappeared without a trace.`,
  'datasheet.vilamo.behaviorContent': `Specialized in systems linked to missile launches, he thwarted several computer attacks that targeted French installations. <b>We can still find his rank used as a signature during his successes.</b>`,
  'datasheet.vilamo.conclusionContent': `Data indicates that he scheduled the launch of a Russian missile on a French oil tanker. It must be stopped at all costs.`,
  'datasheet.joseMessage.name': `Encrypted Message from Jose Paquin`,
  'datasheet.joseMessage.messageTitle': `Message received on May 2, 2019`,
  'datasheet.joseMessage.messageContent': `Destination: 011110010111010101101100`,
  'enigma.1000.name': 'Are you sure you want to leave the call?',
  'enigma.template1.no': 'No',
  'enigma.template1.yes': 'Yes',
  'enigma.template1.ok': 'Ok',
  'enigma.template1.mainMenu': `Main Menu`,
  'enigma.template1.restartMission': `Restart mission`,
  'enigma.template1.viewNext': `See the next mission`,
  'enigma.0.name': `Rescue Malory`,
  'enigma.0.question': `Have you found which street she's on?`,
  'enigma.0.field0Name': `District`,
  'enigma.0.field1Name': `Street`,
  'enigma.0.valid': `Received, I'm sending an agent over right now!`,
  'enigma.0.invalid': `Our agents went to look but did not find anything. Are you sure?`,
  'enigma.1.name': `Counter the JMX`,
  'enigma.1.question': `Find out how to connect to the JMX and counter it!`,
  'enigma.3.name': `Can I count on you?`,
  'enigma.4.name': `Find M's contact`,
  'enigma.4.question': `M must have contacted someone working in aviation! Have you found them?`,
  'enigma.4.invalid': `I can't find anything on that person. Are you sure that's their name?`,
  'enigma.5.name': `Find the flight in danger`,
  'enigma.5.question': `We discovered a crypted code in José's communications.
    Decrypt it to find which plane is M targeting`,
  'enigma.6.name': `Find M`,
  'enigma.6.question': `He must have made a mistake! In which city and district is M?`,
  'enigma.6.field0Name': `City`,
  'enigma.6.field1Name': `District`,
  'enigma.7.name': `Who is M?`,
  'enigma.7.question': `Careful, this is a decisive question. It could endanger our agents!`,
  'enigma.7.malory': 'Malory',
  'enigma.7.pennyworth': 'Pennyworth',
  'enigma.7.martin': 'Martin',
  'enigma.8.name': `Secured Access Sergeant Vilamo`,
  'enigma.8.question': `Sergent Vilamo`,
  'enigma.9.name': `Alert: Infiltration detected`,
  'enigma.9.question': `Trap sequence recognized
      Accelerating countdown`,
  'enigma.10.name': `Missile Launched`,
  'enigma.10.question': `Impact in 8 minutes`,
  'enigma.11.name': `MISSION FAILED`,
  'enigma.11.question': `The missile blew up the boat. No victims, luckily, but millions of animals will suffer from the pollution and millions of dollars have been lost...`,
  'enigma.12.name': `Missile destroyed`,
  'enigma.12.question': `The missile exploded in midair. There were no victims or physical damage. Bravo!`,
  'enigma.13.name': `Rescue Malory`,
  'enigma.13.question': `Malory has just been thrown out the window! She must be on the street, surrounded by civilians calling for help.
    Objective: Find out which street Malory is on.
    Use the "Rescue Malory" button in the left-hand menu when you have found her.`,
  'enigma.14.name': `MISSION SUCCEEDED`,
  'enigma.14.question': `You have earned your place in the heart of the IGS!`,
  'enigma.15.name': `Infiltrate Vilamo's crypted folder`,
  'enigma.15.question': `He seems to have used a peculiar signature.`,
  'enigma.15.validMessage': 'Objective achieved: You have successfully accessed his computer!',
  'enigma.16.name': `Hack MissilGouv software`,
  'enigma.16.question': `You need to cross your team's information to understand the message he left!`,
  'enigma.16.validMessage': 'Access to MissilGouv completed. However, the missile has been launched! It must be neutralized.',
  'enigma.17.name': `Neutralize the missile`,
  'enigma.17.question': `Vilamo's sequence was a trap! You will have to saturate the missile's telemetry systems to make it explode.`,
  'enigma.22.name': `SESSION CANNOT BE FOUND`,
  'enigma.22.question': `The selected session cannot be found`,
  'enigma.23.name': `Airline`,
  'enigma.23.question': `José must have known something. Everybody has specific gestures when they lie.
    Find his weak spot and find what is the airline of the flight targeted by M!`,
  'enigma.24.name': `Removed from session`,
  'enigma.24.question': `You've been removed from the group by the animator`,
  'enigma.115.name': 'Audio file locked',
  'enigma.115.question': `You don't have access to this file, but your teammate might have it.`,
  'fileTree.1000': 'Call Joe Back',
  'fileTree.1001': `Vilamo's Crypted Folder`,
  'fileTree.1002': `MissilGouv 2.3`,
  'fileTree.1003': `Sergeant Vilamo`,
  'fileTree.1004': `Transport Map - Sergeant Vilamo`,
  'fileTree.1005': `Vilamo Note Part 1/2`,
  'fileTree.1006': `Vilamo Note Part 2/2`,
  'fileTree.1020': 'Photo of the launch pad',
  'fileTree.1': `M27 Files`,
  'fileTree.10': `Malory's Notes`,
  'fileTree.11': `Notes ${getTextDate(-6)}`,
  'fileTree.12': `Notes ${getTextDate(-5)}`,
  'fileTree.13': `Notes ${getTextDate(-1)}`,
  'fileTree.102': `SatCell`,
  'fileTree.200': `Cyber Detector`,
  'fileTree.201': `Cyber Counter`,
  'fileTree.205': `File: JMX`,
  'fileTree.302': `Suspects`,
  'fileTree.303': `Judith Rivoli`,
  'fileTree.304': `Nils Brown`,
  'fileTree.305': `José Paquin`,
  'fileTree.306': `Ariane Estapolo`,
  'fileTree.309': `José Paquin`,
  'fileTree.310': `Interrogation`,
  'fileTree.311': `Preliminary Questions`,
  'fileTree.312': `Interrogation`,
  'fileTree.313': `José Paquin Doc`,
  'fileTree.314': `Arrest`,
  'fileTree.315': `Arrest 2`,
  'fileTree.316': `Engagement`,
  'fileTree.317': `Lover`,
  'fileTree.318': `Cryptic Message José Paquin`,
  'fileTree.340': `Flight List`,
  'fileTree.341': `Binary Converter`,
  'fileTree.400': `Concerts`,
  'fileTree.461': `Vocal Effects`,
  'fileTree.480': `Map`,
  'fileTree.449': `The Bells of the World`,
  'fileTree.450': `Almudena, Madrid, Spain`,
  'fileTree.451': `St. Agatha, Kalvarienberg, Germany`, //Alte Kirche Alendorf
  'fileTree.452': `Notre-Dame-de-l'Assomption, Cuzco, Peru`,
  'fileTree.453': `BigBen, London, Britain`,
  'fileTree.454': `Cathédrale de Santander, Santander, Spain`,
  'fileTree.455': `Santi Apostoli, Florence, Italy`,
  'fileTree.456': `St.Mary Le Bow, London, Britain`,
  'fileTree.457': `Église St.Pauls, London, Britain`,
  'fileTree.458': `Westerkerk, Amsterdam, Netherlands`,
  'fileTree.460': `M's Recordings`,
  'fileTree.465': `M Recording 1`,
  'fileTree.462': `M Recording 2`,
  'fileTree.463': `M Recording 3`,
  'fileTree.464': `M Recording 4`,
  'menuItem.0': `Dashboard`,
  'menuItem.1': `Book an experience`, // Divider
  'menuItem.2': `Virtual experiences`,
  'menuItem.6': `FAQ`,
  'menuItem.10': `Teambuilding`, // Divider
  'menuItem.11': `Attend a demo`,
  'menuItem.12': `About Us`,
  'menuItem.14': `Features and Benefits`,
  'menuItem.16': `Pricing`,
  'menuItem.20': `Get in Touch`, // Divider
  'menuItem.22': `Schedule a Call`,
  'menuItem.24': `Contact Us`,
  'menuItem.105': `Apps`,
  'menuItem.100': `Dashboard`,
  'menuItem.103': `Malory's Files`,
  'menuItem.106': `SatCell`,
  'menuItem.107': `CyberCounter`,
  'menuItem.104': `Objectives`,
  'menuItem.101': `Rescue Malory`,
  'menuItem.102': `Counter the JMX`,
  'menuItem.110': `Find the contact`,
  'menuItem.111': `Find the flight in danger`,
  'menuItem.112': `Find M`,
  'menuItem.113': `Who is M?`,
  'menuItem.114': `Quit`,
  'menuItem.115': `Find the airline`,
  'menuItem.200': `Apps`,
  'menuItem.201': `Dashboard`,
  'menuItem.210': `Hints`,
  'menuItem.211': 'MissilGouv',
  'menuItem.205': `Objectives`,
  'menuItem.202': `Infiltrate the computer`,
  'menuItem.203': `Hack MissilGouv`,
  'menuItem.204': `Stop the launch`,
  'menuItem.206': `Neutralize the missile`,
  'helpPage.skip': `Skip this step`,
  'helpPageE1.title': `Further Investigation`,
  'helpPageE1.subtitle': `Read when you've run out of leads...`,
  'helpPageE1.hint1.title': `Access Vilamo's computer`,
  'helpPageE1.hint1.content': `In Vilamo's file, it's mentioned that he uses his rank symbol as a signature. Look at the image of his transport card: you'll see a yellow shape on a red background. This is the symbol you can use to decrypt the file.`,
  'helpPageE1.hint2.title': `Hack MissilGouv`,
  'helpPageE1.hint2.content': `<p>In the "Vilamo's Computer" folder, you'll find a document "Vilamo Note Part 1/2" or "Vilamo Note Part 2/2".
                  Since you are at least two agents, one should have the first part, and the other the second part.
                  Listen to these recordings: they complement each other.
                  The first recording gives you 3, *, 2, *, 6 and *.
                  The second gives you *, 5, *, 4, * and 1.
                  By combining the two, you'll have 3, 5, 2, 4, 6 and 1.
                </p>
                <p>
                  Now, all of you go to the MissilGouv application. One should be able to click on the buttons, the other can read numbers in front of each button.
                  The agent who can read the numbers will need to tell the other agent which buttons to press.
                </p>
                <p>
                  The button sequence will then be:
                  3. Trigger barrier actions
                  5. Balance Kerosene level
                  2. Mark exterior coordinates
                  4. Repeat dislocation code
                  6. Stabilize detectors
                  1. Transfer exploitation data
                </p>`,
  'helpPageE1.hint3.title': `Overload telemetry`,
  'helpPageE1.hint3.content': `<p>
                  The missile has launched! Go to the telemetry panel from MissilGouv.
                  One of you has a word, the other a text area. You'll need to transmit the words quickly to successfully overload the telemetry.
                  But be careful: the roles sometimes switch!
                </p>`,

  'helpPageE2.title': `Further Investigation`,
  'helpPageE2.subtitle': `Read when you've run out of leads...`,
  'helpPageE2.hint1.title': `Find Malory - District`,
  'helpPageE2.hint1.content': `Malory left 3 video notes, listen to them to find out which district she's in.`,
  'helpPageE2.hint2.title': `Find Malory - Street`,
  'helpPageE2.hint2.content': `Malory is in Lambeth. Observe the phone calls in the area using SatCell. In this district, there seems to be a high concentration of calls in a specific street in the last few minutes.`,
  'helpPageE2.hint3.title': `Find Malory - Solution`,
  'helpPageE2.hint3.content': `You can observe a high concentration in the last few minutes on Newport Street.`,
  'helpPageE2.hint4.title': `Counter the JMX - Find the frequency`,
  'helpPageE2.hint4.content': `The JMX uses a particular frequency: try to find it in the JMX file. You can enter this frequency in CyberDetector`,
  'helpPageE2.hint5.title': `Counter the JMX - Identify the rigged car`,
  'helpPageE2.hint5.content': `<p>Once the frequency 615.55 is entered in CyberDetector, one of the players will see points on the map, and the other will see the 4 cardinal directions.</p>
                <p>By clicking on these directions, they can hear in which direction the JMX is located relative to the blue circle at the center of the screen of the player who has the map.</p>`,
  'helpPageE2.hint6.title': `Counter the JMX - Deactivate the JMX`,
  'helpPageE2.hint6.content': `<p>Thanks to the cooperation of the two agents, you will have found the car on the highway, with the IP address: "201.204.93.168".</p>
                <p>By entering this address on "Cyber Counter", you will be connected. You will then see a list of symbols that is the same for your entire team, but some are masked for you.</p>
                <p>You will also have your personal actions and you are the only ones to have them.</p>
                <p>You will need to describe to the other agents what the shapes of the sequence are, in order.</p>
                <p>But be quick: you have a limited time before the sequence resets to zero.</p>`,
  'helpPageE2.hint7.title': `Identify the endangered flight - Find the contact`,
  'helpPageE2.hint7.content': `In the M27 file, you will find the list of suspects. One of them works as an air traffic controller.`,
  'helpPageE2.hint8.title': `Identify the endangered flight - Analyze José`,
  'helpPageE2.hint8.content': `<p>In the "Preliminary Questions" part of the interrogation, José makes a particular gesture when he lies.</p>
                <p>Maybe you'll be able to verify some of the answers using elements from the investigation.</p>`,
  'helpPageE2.hint9.title': `Identify the endangered flight - Discern the lie`,
  'helpPageE2.hint9.content': `<p>Looking closer, José scratches his chin every time he lies!</p>
                <p>In the "interrogation" video, he answers a question about airlines that reveals something.</p>
                <p>Additionally, you'll find an encrypted message that seems to contain the flight's destination.</p>`,
  'helpPageE2.hint10.title': `Identify the endangered flight - Solution`,
  'helpPageE2.hint10.content': `<p>By combining the airline Corsair revealed by José and the flight destination (Montreal - YUL) from the decrypted message, you can find the flight in the list of ongoing flights.</p>
                <p>The flight is indeed: GK 452</p>`,
  'helpPageE2.hint11.title': `Find M - City and district`,
  'helpPageE2.hint11.content': `One of M's messages seems to have background noise: you can hear a church bell ringing. Can you find it?`,
  'helpPageE2.hint12.title': `Find M - Who is M?`,
  'helpPageE2.hint12.content': `<p>M is indeed in London, in Cheapside. And it's one of the 3 SIG agents present there: Pennyworth, Martin or Malory.</p>
                <p>By applying effects to their voices, try to identify which one has the voice closest to M's.</p>`,
  'terms.content': termsEn,
  'privacyPolicy.content': privacyPolicyEn,
}