import { setContentPanel, triggerCustomEvent } from 'discapecommon/actions/index'
import {
  upMissilGouvSetPhase2Status,
  upMissilGouvEntries,
  upTelemetryNewIndex
} from './missilGouv.actions'
import { playAudio, stopAudio } from '/src/actions/mediaActions'
import { channels } from 'discapecommon/consts/channels';
import playerInfos from '../../core/playerInfos'

const words = ['EPSILON', 'GAMMA', 'DELTA', 'INFORMATION',
  'BRAVO', 'TANGO', 'FOXTROT', 'NOVEMBER', 'ZULU', 'GOLF', 'QUEBEC',
  'BLOCUS', 'INVESTIGATION', 'EXPLOSION', 'SUSPECT', 'MISSILE', 'MAYDAY', 'INFILTRATION',
  'AGENT', 'ALLIANCE', 'ASSASSIN', 'CODE', 'DANGER', 'INVISIBLE', 'DESTRUCTION',
  'EXTERMINATION', 'GADGET', 'IMPERCEPTIBLE', 'INNOCENT', 'INTELLIGENCE',
  'INTRODUCTION', 'CAMOUFLAGE', 'IMITATION', 'MENACE', 'OBSERVATION', 'PROJECTILE',
  'SATELLITE', 'VIGILANT', 'ROGER'
]

const defaultState = {
  entries: [],
  phase1Status: 'NORMAL',
  phase2Status: 'DISABLED',
  consoleMode: 'NORMAL',
  telemetryWord: 'EPSILON',
  telemetryNextWord: 'GAMMA',
  telemetryPoints: 0,
  telemetryIndex: 0,
  role: 'player1',
  countDownMode: 'NORMAL',
}

// 3 5 2 4 6 1

/* RIGHT SEQUENCE
3: Provoquer les actions barriere
5: Equilibrer le niveau de Kérosène
2: Baliser les coordonnées extérieures
4: Répéter le code de dislocation
6: Stabiliser les détecteurs
1: Transfert données exploitation
*/

const loadStage20 = (state, action) => {
  return { ...state, phase1Status: 'SUCCESS', phase2Status: 'NORMAL', countDownMode: 'NORMAL' }
}

const missilGouv = (state = defaultState, action) => {
  switch (action.type) {
    case 'CLT_MISSIL_GOUV_BUTTON_CLICK':
      var entries = [...state.entries, action.id];
      action.asyncDispatch(upMissilGouvEntries(entries));
      return { ...state, entries }
    case 'DOWN_MISSIL_GOUV_ENTRIES':
      return { ...state, entries: action.entries }
    case 'DOWN_MISSIL_GOUV_SET_PHASE1_STATUS':
      if (action.status == 'SUCCESS') {
        action.asyncDispatch(playAudio(1007, 30, channels.SFX));
        action.asyncDispatch(playAudio(1012, 100, channels.SFX));
        action.asyncDispatch(stopAudio(4, channels.MUSIC));
        action.asyncDispatch(playAudio(1013, 100, channels.MUSIC));
      }
      else if (action.status == 'WRONG') {
        action.asyncDispatch(playAudio(1006, 100, channels.SFX));
      }
      return { ...state, phase1Status: action.status };
    case 'DOWN_MISSIL_GOUV_SET_PHASE2_STATUS':
      return { ...state, phase2Status: action.status };
    case 'CLT_MISSIL_GOUV_EMERGENCY_CLICK':
      return { ...state, consoleMode: 'EMERGENCY' }
    case 'TRIGGER_CUSTOM_EVENT':
      switch (action.key) {
        case 'TREASON_STAGE_20':
          return loadStage20(state, action);
      }
      return state;
    case 'CLT_TELEMETRY_SUBMIT':
      // console.log('index ', newIndex, words[newIndex])
      if (action.data.trim().toUpperCase() == words[state.telemetryIndex]) {
        var newIndex = (state.telemetryIndex + 1) % words.length;
        var newPoints = state.telemetryPoints + 1;
        action.asyncDispatch(upTelemetryNewIndex(newIndex, newPoints));
        action.asyncDispatch(playAudio(1008, 100, channels.SFX));
        return {
          ...state,
          telemetryIndex: newIndex,
          telemetryPoints: newPoints,
          telemetryWord: words[newIndex],
          telemetryLastValid: true,
          telemetryNextWord: words[(newIndex + 1) % words.length],
        }
      }
      else {
        action.asyncDispatch(playAudio(1006, 100, channels.SFX));
        // action.asyncDispatch(upTelemetryNewIndex(state.telemetryIndex, state.telemetryPoints));
        return {
          ...state,
          telemetryIndex: state.telemetryIndex,
          telemetryWord: words[state.telemetryIndex],
          telemetryLastValid: false,
          telemetryNextWord: words[(state.telemetryIndex + 1) % words.length],
        }
      }
    case 'DOWN_TELEMETRY_NEW_INDEX':
      if (action.index == state.telemetryIndex
        && action.formPlayerNumber == state.formPlayerNumber)
        return state;
      if (action.points > state.telemetryPoints)
        action.asyncDispatch(playAudio(1008, 100, channels.SFX));
      else
        action.asyncDispatch(playAudio(1006, 100, channels.SFX));
      return {
        ...state,
        telemetryIndex: action.index,
        telemetryPoints: action.points,
        telemetryWord: words[action.index],
        telemetryLastValid: action.points > state.telemetryPoints,
        telemetryNextWord: words[(action.index + 1) % words.length],
        formPlayerNumber: action.formPlayerNumber,
      }
    case 'TELEMETRY_SUCCESS':
      action.asyncDispatch(upMissilGouvSetPhase2Status('SUCCESS'))
      return state;
    case 'PUSH_SESSION_STATE':
      // console.log('state ',action.state?.appsState?.missilGouv?.status)
      const actionState = action.state?.appsState?.missilGouv;
      if (!actionState)
        return state;
      var newState = { ...state };

      newState.phase1Status = actionState.phase1Status;
      newState.phase2Status = actionState.phase2Status;

      newState.entries = actionState.entries ?? [];

      newState.telemetryIndex = actionState.index;
      newState.consoleMode = 'NORMAL'

      if (actionState.formPlayerNumber) {
        newState.formPlayerNumber = actionState.formPlayerNumber;
      }
      return newState;
    default:
      return state;
  }
}

export default missilGouv;