const initialState = { countDownId: null, objective: null};

/*
    navBar 
    countDownId and configs
*/

export default function navBar(state = initialState, action) {
  switch (action.type) {
    case 'NAV_BAR_SET_COUNT_DOWN_ID':
      return {...state, countDownId: action.id, countDownStatus:'VISIBLE'}
    case 'NAV_BAR_COUNT_DOWN_SET_STATUS':
      return {...state, countDownStatus:action.status}
    case 'NAV_BAR_SET_OBJECTIVE': // Navbar en bas
      return {...state, objective: action.id}
    case 'NAV_BAR_SET_END_CONDITION': // Timer barre du haut
      return {...state, endCondition: action.endCondition}
    case 'CLT_MISSION_CLOSE_SESSION':
      return initialState;
    default:
      return state
  }
}