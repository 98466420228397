import React from 'react';
import { Frame, Button } from 'arwes';
import { makeStyles } from '@material-ui/core/styles';
import { Heading, Image } from 'arwes'
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import BasicFrame from '../components/BasicFrame';
import navigationManager from '../navigationManager';
import photo from '/assets/images/woman-entertained2.jpg';
import handshake from '/assets/images/handshake.jpg';
import clientServiceGirl from '/assets/images/client-service.png';
import carte from '/assets/images/London2.png';
import SemiSection from '../components/SemiSection';
import help from '/assets/images/help.png'
import clock from '/assets/images/HomePage/clock.webp'
import players from '/assets/images/HomePage/players.png'
import SectionContact from './SectionContact';

const useStyles = makeStyles({
    headingContainer: {
        marginBottom: 42,
    },
    frameContent: {
        padding: '32px 16px 32px 16px',
    },
    imgFrame: {
        // width: '60%',
        margin: 'auto',
        marginBottom: 32
    },
    grid: {
        margin: 'auto'
    },
    catchphrase: {
        marginBottom: '24px',
        '@media (min-width: 800px)': {
            fontSize: 26,
            marginBottom: '48px'
        }
    },
    keyElementSection: {
        marginTop: '-36px',
        marginBottom: '36px',
        padding: '70px 0 70px 0'
    },
    keyElement: {
        marginTop: '28px',
    },
    sectionRecipe: {
        padding: '32px',
        '& .magicElement:nth-child(even)': {
            textAlign: 'left',
            '& .magicContent': {
                fontSize: '.9em',
                marginTop: '2px',
                marginLeft: '20px'
            },
        },
        '& .magicElement:nth-child(odd)': {
            textAlign: 'right',
            '& .magicContent': {
                fontSize: '.9em',
                marginTop: '2px',
            }
        },
        '& .magicTitle': {
            fontSize: '1.1em',
        },


    }
});

function TeamBuildingPage() {
    const classes = useStyles();

    return <>
        {/* SECTION 1 */}
        <BasicFrame>
            <div className={classes.headingContainer}>
                <Heading node='h2'>
                    <FormattedMessage id='teambuilding.section1.title' />
                </Heading>
            </div>

            <Grid container spacing={3}>
                <Grid item md={6} sm={12} className={classes.grid}>
                    <div className={classes.catchphrase}>
                        <FormattedMessage id="teambuilding.section1.catchphrase" values={{ br: <br /> }} />
                    </div>

                    <Button onClick={() => navigationManager.push('/contact')}>
                        <FormattedMessage id="teambuilding.section1.button" />
                    </Button>
                </Grid>
                <Grid item md={6} sm={12} className={classes.grid}>
                    <Frame
                        show={true}
                        level={3}
                        hover={false}
                        corners={0}
                        layer='primary'
                        className={classes.imgFrame}
                    >
                        <img style={{ width: '100%' }} src={photo} />
                    </Frame>
                </Grid>
            </Grid>
            <div style={{ height: '64px' }}></div>

            {/* SECTION KEY ELEMENTS */}

            <SemiSection>
                <div className={classes.semiSection + ' ' + classes.keyElementSection}>
                    <Grid container spacing={3}>
                        <Grid item md={4} sm={12} className={classes.grid}>
                            <img style={{ width: '40%' }} src={help} />
                            <div className={classes.keyElement}><FormattedMessage id="teambuilding.sectionKeyElements.gameMasters" /></div>
                        </Grid>

                        <Grid item md={4} sm={12} className={classes.grid}>
                            <img style={{ width: '40%' }} src={clock} />
                            <div className={classes.keyElement}><FormattedMessage id="teambuilding.sectionKeyElements.duration" /></div>
                        </Grid>

                        <Grid item md={4} sm={12} className={classes.grid}>
                            <img style={{ width: '40%' }} src={players} />
                            <div className={classes.keyElement}><FormattedMessage id="teambuilding.sectionKeyElements.unlimitedPlayers" /></div>
                        </Grid>
                    </Grid>
                </div>
            </SemiSection>

            {/* SECTION MAGIC RECIPE */}

            <div className={classes.sectionRecipe}>
                <div className={classes.headingContainer}>
                    <Heading node='h2'>
                        <FormattedMessage id='teambuilding.sectionMagic.title' />
                    </Heading>
                </div>

                <div className='magicElement'>
                    <Grid container spacing={3}>
                        <Grid item md={8} sm={12} className={classes.grid}>
                            <div className="magicTitle"><FormattedMessage id="teambuilding.sectionMagic.corporateSpirit.title" /></div>
                            <div className="magicContent"><FormattedMessage id="teambuilding.sectionMagic.corporateSpirit.content" /></div>

                        </Grid>

                        <Grid item md={4} sm={12} className={classes.grid}>
                            <Image resources={handshake} />
                        </Grid>
                    </Grid>
                </div>
                <div className='magicElement'>
                    <Grid container spacing={3}>
                        <Grid item md={4} sm={12} className={classes.grid}>
                            <img src={clientServiceGirl} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item md={8} sm={12} className={classes.grid}>
                            <div className="magicTitle"><FormattedMessage id="teambuilding.sectionMagic.hosts.title" /></div>
                            <div className="magicContent"><FormattedMessage id="teambuilding.sectionMagic.hosts.content" /></div>

                        </Grid>

                    </Grid>
                </div>
                <div className='magicElement'>
                    <Grid container spacing={3}>
                        <Grid item md={8} sm={12} className={classes.grid}>
                            <div className="magicTitle"><FormattedMessage id="teambuilding.sectionMagic.flexibility.title" /></div>
                            <div className="magicContent"><FormattedMessage id="teambuilding.sectionMagic.flexibility.content" /></div>

                        </Grid>
                        <Grid item md={4} sm={12} className={classes.grid}>
                            <Image resources={carte} />
                        </Grid>

                    </Grid>
                </div>
            </div>
        </BasicFrame>

        <div style={{ height: '64px' }}></div>

        <BasicFrame>
            <SectionContact />
        </BasicFrame>
    </>;
}

export default TeamBuildingPage
