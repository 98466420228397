// import config from 'config';
// import { authHeader } from '../_helpers';
import { socket } from '../networkManager';
import { missionTypes } from 'discapecommon/consts/missionTypes';
import sendClientRequest from './_sendClientRequest'

export function fetchSessions() {
  const request = {
    type: missionTypes.UP_MISSION_FETCH_SESSIONS,
  };

  return sendClientRequest(request);
}

export function getStage(sessionId, userId) {
  const request = {
    type: missionTypes.UP_MISSION_GET_STAGE,
    sessionId,
    userId
  };
  
  return sendClientRequest(request);
}
