/*
* Stores if additional panel is open or close and the activePanel
*/

const defaultState = {
  instanceId: 0,
}

const mainDashboard = (state = defaultState, action) => {
  switch (action.type) {
    case 'SELECT_ACTIVITY':
      
    default:
      return state;
  }
}

export default mainDashboard
