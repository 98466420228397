import { setContentPanel, triggerCustomEvent } from 'discapecommon/actions/index'
import { status } from 'discapecommon/apps/cyberDetector/status'


const defaultCyberDetector = {
  status: status.INVALID_FREQUENCY,
  role: 'NAVIGATOR',
  isFrequencyValid: false,
  hasEnoughPlayers: false,
  centerPosition: { lat: 47.613788, lng: 3.772056 },
  centerZoom: 10,
}

const cyberDetector = (state = defaultCyberDetector, action) => {
  switch (action.type) {
    case 'PUSH_CYBER_DETECTOR_VALID_FREQUENCY':
      return {
        ...state,
        status: status.VALID_FREQUENCY,
        isFrequencyValid: true,
      }
    case 'PUSH_CYBER_DETECTOR_CENTER_UPDATE':
      return {
        ...state,
        centerPosition: action.position?.lat ? action.position : state.centerPosition,
        centerZoom: action.zoom ? action.zoom : state.centerZoom,
      }
    case 'PUSH_CYBER_DETECTOR_ROLES':
      return { ...state, roles: action.roles }
    case 'PUSH_CYBER_DETECTOR_START':
      return {
        ...state,
        status: status.PLAYING,
      }
    case 'PUSH_CYBER_DETECTOR_NEED_MORE_PLAYERS':
      return { ...state, sequenceIndex: 0, status: status.NEED_MORE_PLAYERS }
    case 'PUSH_CYBER_DETECTOR_ENOUGH_PLAYERS':
      return { ...state, hasEnoughPlayers: true }
    case 'CYBER_DETECTOR_LEAVE':
      console.log('leaving')
      action.asyncDispatch(setContentPanel(null))
      return state;
    case 'PUSH_SESSION_STATE':
      let newStatus = action.state?.appsState?.cyberDetector;
      return { ...state, ...newStatus };
    default:
      return state;
  }
}

export default cyberDetector;