import React from 'react';
import { Frame } from 'arwes';
import { makeStyles } from '@material-ui/core/styles';
import { Heading } from 'arwes'
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import BasicFrame from '../components/BasicFrame';
import navigationManager from '../navigationManager';
import photo from '/assets/images/woman-entertained.jpg';
import SectionContact from './SectionContact';

const useStyles = makeStyles({
    headingContainer: {
        marginBottom: 42,
    },
    frameContent: {
        padding: '32px 16px 32px 16px',
    },
    imgFrame: {
        // width: '60%',
        margin: 'auto',
        marginBottom: 32
    },
    grid: {
        margin: 'auto'
    },
    catchphrase: {
        marginBottom: '24px',
        '@media (min-width: 800px)': {
            fontSize: 26,
            marginBottom: '48px'
        }
    }
});

function FeaturesPage() {
    const classes = useStyles();

    return <>
        <BasicFrame>
            <div className={classes.headingContainer}>
                <Heading node='h2'>
                    <FormattedMessage id='features.title' />
                </Heading>
            </div>

            <Grid container spacing={3}>
                <Grid item md={6} sm={12} className={classes.grid}>
                    <div className={classes.catchphrase}>
                        <FormattedMessage id="features.section1.catchphrase" values={{ br: <br /> }} />
                    </div>
                    <div style={{ textAlign: 'left' }}>
                        <FormattedMessage id="features.section1.description" />
                    </div>
                </Grid>
                <Grid item md={6} sm={12} className={classes.grid}>
                    <Frame
                        show={true}
                        level={3}
                        hover={false}
                        corners={0}
                        layer='primary'
                        className={classes.imgFrame}
                    >
                        <img style={{ width: '100%' }} src={photo} />
                    </Frame>
                </Grid>
            </Grid>
        </BasicFrame>
        <div style={{ height: '64px' }}></div>
        <BasicFrame>
            <SectionContact />
        </BasicFrame>
    </>;
}

export default FeaturesPage
