/*
* Stores if additional panel is open or close and the activePanel
*/
import {
  openAdditionalPanel, closeAdditionalPanel, goHome, applyHome, goSceneHome, cltCheckLeaveSession,
  setContentPanel, toggleAdditionalPanel, setCurrentScene
} from 'discapecommon/actions/index'
import { userActions } from '../actions/userActions'
import navigationManager from '../navigationManager';
import browserHistory from 'discapecommon/browserHistory'

let params = location.pathname.split('/').filter(value => value != '')

const defaultState = {
  scene: params[0] == 'play' ? 'MISSION' : 'MAIN_DASHBOARD',
  activeTreeItem: null,
  isDrawerOpened: false,
}

const mainInterface = (state = defaultState, action) => {
  switch (action.type) {
    case 'TOGGLE_DRAWER':
      return { ...state, isDrawerOpened: !state.isDrawerOpened };
    case 'CLOSE_DRAWER':
      return { ...state, isDrawerOpened: false };
    case 'GO_HOME':
      navigationManager.push('/')
      return state;
    case 'APPLY_HOME':
      action.asyncDispatch(cltCheckLeaveSession())
      return { ...state, contentPanel: null, scene: 'MAIN_DASHBOARD', activeTreeItem: null }
    case 'GO_SCENE_HOME':
      switch (state.scene) {
        case 'MISSION': break;
        default: action.asyncDispatch(goHome()); break;
      }
      return { ...state, contentPanel: null, activeTreeItem: null }
    case 'OPEN_APP':
      return { ...state, contentPanel: action.appId, activeTreeItem: action.treeId ?? state.activeTreeItem };
    case 'SET_CONTENT_PANEL':
      let scene = state.scene;
      if (action.name == '') {
        scene = 'MAIN_DASHBOARD';
      }
      return { ...state, scene, contentPanel: action.name };
    case 'SET_CURRENT_SCENE':
      action.asyncDispatch(setContentPanel())
      return { ...state, scene: action.scene }
    case 'CLT_MISSION_CLOSE_SESSION':
      return { ...state, activeTreeItem: null }
    case 'OPEN_DATASHEET':
      if (action.treeId)
        return { ...state, activeTreeItem: action.treeId, contentPanel: 'DATASHEET' }
      return state;
    case 'OPEN_FOLDER':
      if (action.id)
        return { ...state, activeTreeItem: action.id, contentPanel: 'FILES' }
      else {
        action.asyncDispatch(goSceneHome())
      }
      return state;
    case 'OPEN_STATIC_LINK':
      switch (action.id) {
        case 'HELP':
        case 'HINT':
        case 'CONFIG':
          action.asyncDispatch(setContentPanel(action.id));
          break;
        case 'MISSION_LIST':
          action.asyncDispatch(setCurrentScene());
          action.asyncDispatch(setContentPanel(action.id));
          break;
        case 'STATIC_LINK_GO_HOME':
          action.asyncDispatch(setContentPanel());
          action.asyncDispatch(setCurrentScene());
          if (browserHistory.location.pathname != '/') {
            navigationManager.push('/');
          }
          break;
        case 'STATIC_LINK_LEAVE_SESSION':
          navigationManager.push('/browse');
          action.asyncDispatch({ type: 'CLT_MISSION_CLOSE_SESSION' })
          break;
        case 'LOG_OUT':
          action.asyncDispatch(userActions.logOut());
          break;
        case 'SESSION_USER_LIST':
          action.asyncDispatch(openAdditionalPanel('SESSION_USER_LIST'));
          break;
        case 'RENAME_DIALOG':
          action.asyncDispatch(openAdditionalPanel('RENAME_DIALOG'));
          break;
        case 'SIGN_UP':
          action.asyncDispatch(openAdditionalPanel('SIGN_UP'));
          break;
        case 'LOG_IN':
          action.asyncDispatch(openAdditionalPanel('LOG_IN'));
          break;
        default:
          console.error('Unrecognized link id: ', action.id)
      }
      return state;
    default:
      return state;
  }
}

export default mainInterface
