import { isTestEnv } from '../utils'
import browserHistory from 'discapecommon/browserHistory'

export function gtagRegister(type, subtype, data) {
    if (isTestEnv()) {
        return;
    }

    gtag(type, subtype, data);
}

export function gtagView(page) {
    if (isTestEnv()) {
        return;
    }

    gtag('event', 'page_view', {
        'page_title': page,
        'page_location': browserHistory.location.pathname
    });
}