import React from 'react';
import { Frame } from 'arwes';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from '../hooks/useWindowDimensions';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 1400,
    margin: 'auto',
  },
  frameContent: {
    padding: '36px 16px 32px 16px',
  },
});

export default function BasicFrame({ children, classContent }) {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return <Frame
    animate
    show={true}
    level={3}
    hover={false}
    corners={width < 1200 ? 0 : 4}
    layer='primary'
    className={classes.root}
  >
    <div className={classes.frameContent + ' ' + classContent}>
      {children}
    </div>
  </Frame>
}