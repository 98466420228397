export const termsFr = `
  <h1>Mentions légales</h1>
    Bienvenue sur Discape !{br}
{br}
    Les présentes mentions légales définissent les règles d'utilisation du site web et des services de Discape, accessibles à l'adresse https://discapegame.com. En accédant à ce site web et en utilisant nos services, nous considérons que vous acceptez ces mentions légales. Veuillez ne pas continuer à utiliser Discape si vous n'acceptez pas l'intégralité des conditions énoncées sur cette page.

    <h2>Discape </h2>
    info@discapegame.com{br}
    +14388301456{br}
    Directeur de la publication : Yvan Richer

    <h2>Propriété intellectuelle</h2>
    L'ensemble du contenu du site Discape (textes, images, vidéos, etc.) est protégé par le droit d'auteur. Toute reproduction, même partielle, est interdite sans autorisation préalable.
    <h2>Cookies</h2>
    Nous utilisons des cookies pour améliorer votre expérience sur notre site. En continuant à naviguer, vous acceptez l'utilisation de cookies conformément à notre politique de confidentialité.
    <h2>Licence d'utilisation</h2>
    Nous vous accordons une licence limitée, non exclusive et révocable pour accéder et utiliser nos services à des fins personnelles et non commerciales, sous réserve du respect des présentes mentions légales.
    <h2>Conditions d'utilisation</h2>
    En utilisant nos services, vous vous engagez à :{br}
{br}
        Ne pas les utiliser à des fins illégales ou non autorisées{br}
        Ne pas tenter de nuire au fonctionnement du site ou des jeux{br}
        Ne pas copier, modifier ou redistribuer le contenu sans autorisation{br}
        Respecter les droits de propriété intellectuelle{br}
{br}
    Nous nous réservons le droit de suspendre ou supprimer votre accès en cas de non-respect de ces conditions.{br}
    <h2>Réservations et paiements</h2>
    Les réservations sont soumises à nos conditions générales de vente disponibles [lien]. Tous les prix sont indiqués TTC.{br}
    <h2>Responsabilité</h2>
    Discape ne pourra être tenu responsable des dommages directs ou indirects résultant de l'utilisation du site ou des jeux, sauf en cas de faute grave de notre part.{br}
    <h2>Droit applicable</h2>
    Les présentes mentions légales sont soumises au droit français. Tout litige relèvera de la compétence exclusive des tribunaux canadiens.{br}
    <h2>Modification</h2>
    Nous nous réservons le droit de modifier ces mentions légales à tout moment. Votre utilisation continue des services après toute modification constitue votre acceptation des nouvelles conditions. Pour toute question concernant ces mentions légales, vous pouvez nous contacter à info@discapegame.com.
    {br}{br}
    Dernière mise à jour : 2024-10-20
    `