// import config from 'config';
// import { authHeader } from '../_helpers';
import { socket } from '../networkManager';
import { catalogTypes } from 'discapecommon/consts/catalogTypes';
import { missionTypes } from 'discapecommon/consts/missionTypes';
import sendClientRequest from './_sendClientRequest'
import { loadStripe } from '@stripe/stripe-js';
import { isTestEnv } from '../utils'

var stripePromise;

if (isTestEnv(['onlineSandbox', 'localhost']))
  stripePromise = loadStripe('pk_test_bce6FoDQzPHu8aBQGwKRoWqh00uaYAYpRv');
else
  stripePromise = loadStripe('pk_live_ZLBF56AIuFxWXOo9CK9axxq300i77WgGit');


export function getCheckoutSession(items) {
  const request = {
    type: catalogTypes.UP_CATALOG_GET_CHECKOUT_SESSION,
    items,
  };

  return sendClientRequest(request).then(res => {
    startStripe(res.session)

  });
}

async function startStripe(session) {
  const sessionId = session.id;
  const stripe = await stripePromise;
  const { error } = await stripe.redirectToCheckout({
    sessionId,
  });
}

export async function getMissionLockStatus(key) {
  const request = {
    type: missionTypes.UP_GET_MISSION_LOCK_STATUS,
    key,
  };

  return sendClientRequest(request);
}

export async function getMissionInfos(key) {
  const request = {
    type: missionTypes.UP_MISSION_GET_INFOS,
    key,
  };

  return sendClientRequest(request);
}