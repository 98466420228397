import React from 'react'
import FAQPage from "./FAQPage"
import DemoPage from "./DemoPage"
import ProfilePage from '../_User/ProfilePage'
import FeaturesPage from './FeaturesPage'
import TeamBuildingPage from './TeamBuildingPage'
import ContactPage from './ContactPage'
import AboutPage from './AboutPage'
import TermsPage from './TermsPage'
import PrivacyPolicyPage from './PrivacyPolicyPage'

const pageList = [
    {
        pathname: 'faq',
        component: <FAQPage />
    },
    {
        pathname: 'profile',
        component: <ProfilePage />
    },
    {
        pathname: 'demo',
        component: <DemoPage />
    },
    {
        pathname: 'features',
        component: <FeaturesPage />
    },
    {
        pathname: 'teambuilding',
        component: <TeamBuildingPage />
    },
    {
        pathname: 'contact',
        component: <ContactPage />
    },
    {
        pathname: 'about',
        component: <AboutPage />
    },
    {
        pathname: 'terms',
        component: <TermsPage />
    },
    {
        pathname: 'privacy-policy',
        component: <PrivacyPolicyPage />
    }
]

export default pageList