import { contactFormTypes } from 'discapecommon/consts/contactFormTypes';
import sendClientRequest from './_sendClientRequest'

export function sendClientMessage(data) {
  const request = {
    type: contactFormTypes.UP_CONTACT_FORM_SEND,
    ...data
  };

  return sendClientRequest(request)
    .then(response => {
      return response.ok;
    });
}