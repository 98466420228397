import { userTypes } from 'discapecommon/consts/userTypes'

// Pas de notion d'instance ni de mission

const sessionUsers = (state = [], action) => {
  switch (action.type) {
    case 'PUSH_NEW_USER':
      if (state.find(({ userHandlerId }) => userHandlerId == action.userHandlerId)) {
        return state.map(user => {
          if (user.userHandlerId == action.userHandlerId)
            return {
              ...user,
              playerNumber: action.playerNumber,
              isInSession: true,
              pooledUserType: action.pooledUserType,
              sessionId: action.sessionId,
            }
          else
            return user;
        });
      }
      return [...state, {
        userHandlerId: action.userHandlerId,
        playerNumber: action.playerNumber,
        isInSession: true,
        pooledUserType: action.pooledUserType,
        sessionId: action.sessionId
      }];
    case 'PUSH_REMOVE_USER_FROM_SESSION':
      return state.map(user => {
        if (user.userHandlerId == action.userHandlerId) {
          return { ...user, isInSession: false }
        }
        else
          return user
      })
    case 'PUSH_USER_LIST':
      return action.state.users;
    case 'PUSH_SESSION_STATE':
      if (typeof action.state.sessionUsers !== 'undefined') {
        return action.state.sessionUsers;
      }
      else {
        console.log('Error PUSH_SESSION_STATE no users')
        return state;
      }
    case 'DOWN_UPDATE_USERS':
      return [...action.users]
    case userTypes.DOWN_USERS_UPDATE_PLAYER_NUMBERS:
      return state.map(user => {
        var foundUser = action.users.find(({ userHandlerId }) => userHandlerId == user.userHandlerId);
        if (foundUser) {
          return { ...user, playerNumber: foundUser.playerNumber, isInSession: true }
        }
        return { ...user, isInSession: false };
      })
    case 'DOWN_USERS_USERNAME_UPDATE':
      return state.map(user => {
        if (user.userHandlerId == action.userHandlerId) {
          return { ...user, user: { ...user.user, username: action.username } } // Terrible but works
        }
        else {
          return user;
        }
      })
    case userTypes.DOWN_USERS_USERNAME_UPDATE:
      return state.map(user => {
        if (user.userHandlerId == action.userHandlerId) {
          return { ...user, username: action.username }
        }
        else
          return user
      })
    default:
      return state
  }
}

export default sessionUsers