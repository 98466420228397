import React from 'react';
import SectionContact from './SectionContact'
import BasicFrame from '../components/BasicFrame';


const ContactPage = () => {
    return <BasicFrame>
        <SectionContact />
    </BasicFrame>
}

export default ContactPage