import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import BasicFrame from '../components/BasicFrame';

const useStyles = makeStyles({
    root: {
        textAlign: 'left'
    },
    headingContainer: {
        textAlign: 'center',
        marginBottom: 42,
    },
    section1: {
        fontSize: '1.3em',
        margin: '24px',
    },
    sectionLeadIndustry: {
        margin: '48px 24px 32px 24px',
        '& .leadRow': {
            margin: '10px auto',
            'padding': '24px 36px',
        },
        '& .leadRow:nth-child(even)': {
            'background-color': 'rgb(3, 33, 38)',
            'border-radius': '16px'
        }
    },
    sectionTeam: {
        margin: '24px',
        textAlign: 'center'
    },
    sectionTeamLeadName: {
        textAlign: 'center',
        fontSize: '1.4em',
    },
    sectionTeamLeadTitle: {
        textAlign: 'center',
        fontSize: '1em',
    },
    sectionTeamLeadContent: {
        fontSize: '0.9em',
    },
});

function TermsPage() {
    const classes = useStyles();

    return <>
        <div className={classes.root}>
            {/* SECTION 1 */}
            <BasicFrame>
                <FormattedMessage id="terms.content" values={{
                    h1: (...chunks) => <h1>{chunks}</h1>,
                    h2: (...chunks) => <h2>{chunks}</h2>,
                    br: <br />,
                }} />
            </BasicFrame>
        </div>
    </>
}

export default TermsPage;