import { contactFormTypes } from 'discapecommon/consts/contactFormTypes';
import * as contactFormService from '../services/contactFormService';


export function sendContactMessage(data) {
    return dispatch => {
        console.log('request ', request(data))
        dispatch(request(data));

        contactFormService.sendClientMessage(data)
            .then(
                () => dispatch(success()),
                error => dispatch(failure(error.message))
            );
    };

    function request(data) { return { type: contactFormTypes.CONTACT_FORM_SET_PENDING, ...data } }
    function success() { return { type: contactFormTypes.CONTACT_FORM_SET_SUCCESS } }
    function failure(errorMessageKey) { return { type: contactFormTypes.CONTACT_FORM_SET_ERROR, errorMessageKey: errorMessageKey } }
}
