import eventManager from './eventManager.js';

export function stringOfEnum (f_enum,value)
{
  for (var k in f_enum) if (f_enum[k] == value) return k;
  return null;
}

export function logError (message, options) {
  log('Control pad error: '+message, options);
}

export function linesToParagraphs(...nodes) {
    return nodes
        .map(node => typeof node === 'string' ? 
            node.split('\n').map((text, i) => <p key={i}>{text}</p>) : node)
        .reduce((nodes, node) => nodes.concat(node), []);
}

export function linesToList(...nodes) {
    let result = nodes
        .map(node => typeof node === 'string' ? 
            node.split('\n').map((text, i) => <li key={i}>{text}</li>) : node)
        .reduce((nodes, node) => nodes.concat(node), []);
    return <ul>{result}</ul>;
}

export function fNumber(value) {
  return ("0" + value).slice(-2)
}

export function log (message, options) {
  eventManager.emit('log', {
    'message': message,
    'options': options
  });
  console.log('log: '+message);
}

export function isNumber (obj) {
  return ( !isNaN(parseFloat(obj)) )
}

export function isString (obj) {
  return ( typeof obj === 'string' || obj instanceof String )
}

   /* Timer
  Currently every additionnal args are grouped in one array
  TODO: Spread them as many args to the function 
  
    callback
    delay
    autostart
    isPeriodic (true, false)
    args for callback
  */
export function Timer () {
  var fn = arguments[0];
  var delay = arguments[1];
  var autoStart = typeof arguments[2] === 'boolean' ? arguments[2] : true;
  var periodic = typeof arguments[3] === 'boolean' ? arguments[3] : true;
  var additives = Array.prototype.slice.call(arguments, 4);

  var timerObj = null;
  var startTime = new Date();
  var remaining = delay;

  this.stop = function() {
    remaining = delay;
    return this.clear();
  }

  this.clear = function() {
    if (timerObj) {
      if(periodic)
        clearInterval(timerObj);
      else
        clearTimeout(timerObj);  
      timerObj = null;
    }
    return this;
  }

  this.pause = function() {
    // Handle resume on a periodic timer
    remaining -= new Date() - startTime;
    this.clear();
  }

  // alias of resume
  this.start = function() {
    return this.resume();
  }

  this.resume = function() {
    if (!timerObj) {
      startTime = new Date();
      if(periodic)
        timerObj = setInterval(fn, remaining, additives);
      else  
        timerObj = setTimeout(fn, remaining, additives);
    }
    return this;
  }

  this.isStarted = function() {
      return timerObj;
  }

  // Start with new interval, stop current interval
  this.reset = function(newDelay, periodic = periodic) {
      if(typeof newDelay === 'number') {
        delay = newDelay;
        remaining = newDelay;
      }
      return this.stop().start();
  }


  if(autoStart)
    return this.start();

  return this;
}

// Warn if overriding existing method
if(Array.prototype.equals)
    console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
// attach the .equals method to Array's prototype to call it on any array
Array.prototype.equals = function (array) {
    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time 
    if (this.length != array.length)
        return false;

    for (var i = 0, l=this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i]))
                return false;       
        }           
        else if (this[i] instanceof Object && array[i] instanceof Object) {
            // recurse into the nested objects
            if (!this[i].equals(array[i]))
                return false;       
        }           
        else if (this[i] != array[i]) { 
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;   
        }           
    }       
    return true;
}
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", {enumerable: false});


export function compareObjects(A, B) {
    if(typeof B == 'undefined' || typeof A === 'undefined') {
      console.error('Comparing object with undefined')
      return false
    }
    //For the first loop, we only check for types
    for (var propName in A) {
        //Check for inherited methods and properties - like .equals itself
        //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/hasOwnProperty
        //Return false if the return value is different
        if (A.hasOwnProperty(propName) != B.hasOwnProperty(propName)) {
            return false;
        }
        //Check instance type
        else if (typeof A[propName] != typeof B[propName]) {
            //Different types => not equal
            return false;
        }
    }
    //Now a deeper check using other objects property names
    for(var propName in B) {
        //We must check instances anyway, there may be a property that only exists in B
            //I wonder, if remembering the checked values from the first loop would be faster or not 
        if (A.hasOwnProperty(propName) != B.hasOwnProperty(propName)) {
            return false;
        }
        else if (typeof A[propName] != typeof B[propName]) {
            return false;
        }
        //If the property is inherited, do not check any more (it must be equa if both objects inherit it)
        if(!A.hasOwnProperty(propName))
          continue;
        
        //Now the detail check and recursion
        
        //This returns the script back to the array comparing
        /**REQUIRES Array.equals**/
        if (A[propName] instanceof Array && B[propName] instanceof Array) {
                   // recurse into the nested arrays
           if (!A[propName].equals(B[propName]))
                        return false;
        }
        else if (A[propName] instanceof Object && B[propName] instanceof Object) {
                   // recurse into another objects
                   //console.log("Recursing to compare ", A[propName],"with",B[propName], " both named \""+propName+"\"");
           if (!compareObjects(A[propName], B[propName]))
                        return false;
        }
        //Normal value comparison for strings and numbers
        else if(A[propName] != B[propName]) {
           return false;
        }
    }
    //If everything passed, let's say YES
    return true;
}  

/*
  3 different env: localhost, onlineSandbox and live
*/
export function isTestEnv (context = ['localhost', 'onlineSandbox']) {
  if(typeof window.location.href.includes != 'function')
    return false;
  if(typeof context != 'array' && typeof context != 'object') {
    console.error('isTestEnv: typeof context = ',typeof context)
    return false
  }
  if(context.includes('onlineSandbox')) {
    if(window.location.href.includes('discapegame-test'))
      return true;
  }
  if(context.includes('localhost')) {
    return (window.location.href.includes('localhost') 
      || window.location.href.includes('127.0.0.1')
      || window.location.href.includes('192.168'));
  }
  return false;
}