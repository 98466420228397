import { isTestEnv } from '../utils'

const isLogTypePrevented = (action) => {
  switch(action.type) {
    case 'COUNT_DOWN_UPDATE':
    case 'ELAPSED_SECONDS_UPDATE':
    case 'STATUS_UPDATE':
    case 'LANGUAGE_UPDATE':
    case 'GPIO_READ_UPDATE':
    case 'PAD_INPUT_CHECKBOX_UNCHECKED':
    case 'PAD_INPUT_CHECKBOX_CHECKED':
    case 'GPIO_READ_UPDATE':
    case 'VARIABLE_UPDATE':
    case 'SFX_ENDED':
    case 'PUSH_STAGE':
      return true;
    default:
      return false;
  }
}

window.debug = isTestEnv();
// In prod, activate it with window.debug = true
export const logger = store => next => action => {
  if(!window.debug)
    return next(action);

  // Show Client Only
  if(typeof action.meta !== 'undefined'
    && action.meta.context == 'SERVER') {
    return next(action);
  }

  if((typeof action.meta === 'object' 
    && action.meta.log === false)
    || isLogTypePrevented(action))
    return next(action);


	console.group(action.type)
	console.info('Dispatching', action)
	let result = next(action)
	console.log('Next state', store.getState())
	console.groupEnd(action.type)
	return result
}