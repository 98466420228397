export const termsEn = `<h1>Terms & Conditions</h1>
    Welcome to Discape!{br}
    {br}
    This legal notice defines the rules for using the Discape website and services, accessible at https://discapegame.com. By accessing this website and using our services, we consider that you accept these legal terms. Please do not continue to use Discape if you do not accept all the conditions stated on this page.

    <h2>Discape</h2>
    info@discapegame.com{br}
    +14388301456{br}
    Publication Director: Yvan Richer

    <h2>Intellectual Property</h2>
    All content on the Discape site (texts, images, videos, etc.) is protected by copyright. Any reproduction, even partial, is prohibited without prior authorization.{br}

    <h2>Cookies</h2>
    We use cookies to improve your experience on our site. By continuing to browse, you accept the use of cookies in accordance with our privacy policy.{br}

    <h2>License to Use</h2>
    We grant you a limited, non-exclusive, and revocable license to access and use our services for personal and non-commercial purposes, subject to compliance with these legal terms.{br}

    <h2>Terms of Use</h2>
    By using our services, you agree to:{br}
    {br}
        Not use them for illegal or unauthorized purposes{br}
        Not attempt to harm the functioning of the site or games{br}
        Not copy, modify or redistribute content without authorization{br}
        Respect intellectual property rights{br}
    {br}
    We reserve the right to suspend or delete your access in case of non-compliance with these conditions.{br}

    <h2>Reservations and Payments</h2>
    Reservations are subject to our general terms of sale available [link]. All prices are indicated including tax.{br}

    <h2>Liability</h2>
    Discape cannot be held responsible for direct or indirect damages resulting from the use of the site or games, except in case of gross negligence on our part.{br}

    <h2>Applicable Law</h2>
    These legal terms are subject to French law. Any dispute will fall under the exclusive jurisdiction of French courts.{br}

    <h2>Modification</h2>
    We reserve the right to modify these legal terms at any time. Your continued use of the services after any modification constitutes your acceptance of the new conditions. For any questions regarding these legal terms, you can contact us at info@discapegame.com.
    {br}{br}
    Last updated: 2024-10-20`