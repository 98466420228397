import { useLayoutEffect, useState, Suspense, lazy } from 'react';
import Loading from './components/Loading'
const Player = lazy(() => import('./Player'))
import { ThemeProvider, createTheme } from 'arwes';
import navigationManager from './navigationManager';

const nav = navigationManager;

const Router = () => {
  const getTheme = () => {
    return createTheme({
      color: {
        primary: { base: '#a0ecfb' }
      },
      alpha: 0.92,
    });
  }

  return (
    <ThemeProvider theme={getTheme()}>
      <Suspense fallback={<Loading />}>
        <Player />
      </Suspense>
    </ThemeProvider>
  );
};


export default Router