import React, { useRef } from 'react';
import { Frame, Loading } from 'arwes';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Heading } from 'arwes'
import NavLink from '../components/NavLink'
import { useSelector, useDispatch } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment-timezone'
import { sendContactMessage } from '../actions/contactFormActions'
import { contactFormTypes } from 'discapecommon/consts/contactFormTypes';

const useStyles = makeStyles({
    headingContainer: {
        marginBottom: 42,
    },
    content: {
        padding: 16,
    },
    heading: {
        marginTop: 12,
    },
    details: {
        display: 'block',
        textAlign: 'left',
    },
    formInfo: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px',
        margin: '12px',
    },
    textField: {
        flex: 'calc(50% - 8px)',
        '& .MuiTextField-root': {
            width: '100%'
        }
    },
    textFieldLarge: {
        flex: '100%',
        '& .MuiTextField-root': {
            width: '100%'
        }
    },
    list: {
        textAlign: 'left',
        listStyle: 'none'
    },
    bookInstruction: {
        fontSize: '1.2em',
        textAlign: 'center',
        marginTop: '20px',
        '& button': {
            margin: '12px'
        }
    },
    pending: {
        height: '330px',
        position: 'relative'
    },
    success: {
        height: '330px',
        paddingTop: '150px'
    },

    error: {
        height: '330px',
    }
});

function getDateTimeString(momentObject) {
    return momentObject.format("dddd LL").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

export default function SectionContact() {
    const classes = useStyles();
    const intl = useIntl();
    const firstName = useRef()
    const lastName = useRef()
    const email = useRef()
    const phone = useRef()
    const message = useRef()
    const dispatch = useDispatch();
    const formState = useSelector((state) => state.modules.contactForm.formState)
    const errorMessageKey = useSelector((state) => state.modules.contactForm.errorMessageKey)
    const language = useSelector((state) => state.intl.locale)

    const handleSendClick = () => {
        const data = {
            firstName: firstName.current.value,
            lastName: firstName.current.value,
            email: email.current.value,
            phone: phone.current.value,
            message: message.current.value,
            language: language,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timezoneOffset: new Date().getTimezoneOffset() / -60,
            utc: moment().utc().valueOf(),
            utcString: getDateTimeString(moment().utc()),
            montrealDateTimeString: getDateTimeString(moment().tz('America/Toronto')),
            localDateTimeString: getDateTimeString(moment())
        }
        sendContactMessage(data)(dispatch)
    }

    const handleTryAgainClick = () => {
        dispatch({ type: contactFormTypes.CONTACT_FORM_SET_NORMAL })
    }

    const getForm = () => {
        switch (formState) {
            case 'normal':
                return <>
                    <div className={classes.formInfo}>
                        <div className={classes.textField}><TextField inputRef={firstName} label={"* " + intl.formatMessage({ id: 'demo.info.firstName' })} variant="outlined" /></div>
                        <div className={classes.textField}><TextField inputRef={lastName} label={"* " + intl.formatMessage({ id: 'demo.info.lastName' })} variant="outlined" /></div>
                        <div className={classes.textField}><TextField inputRef={email} label={"* " + intl.formatMessage({ id: 'demo.info.email' })} variant="outlined" /></div>
                        <div className={classes.textField}><TextField inputRef={phone} label={"* " + intl.formatMessage({ id: 'demo.info.phone' })} variant="outlined" /></div>
                        <div className={classes.textFieldLarge}><TextField inputRef={message} label={"* " + intl.formatMessage({ id: 'contact.info.messageHolder' })} variant="outlined" multiline minRows={6} /></div>
                    </div>

                    <div className={classes.bookInstruction}>
                        <Button variant="contained" onClick={() => handleSendClick()}>
                            <FormattedMessage id="contact.send" />
                        </Button>
                    </div>
                </>

            case 'pending':
                return <div className={classes.pending}>
                    <Loading animate full />
                </div>

            case 'success':
                return <div className={classes.success}>
                    <FormattedMessage id="contact.messageConfirmed" />
                </div>

            case 'error':
                return <div className={classes.error}>
                    {errorMessageKey ? intl.formatMessage({ id: errorMessageKey }) : 'Error'}
                    <div className={classes.bookInstruction}>
                        <Button variant="contained" onClick={() => handleTryAgainClick()}>
                            <FormattedMessage id="contact.tryAgain" />
                        </Button>
                    </div>
                </div>
        }
    }

    return (
        <>
            <div className={classes.headingContainer}>
                <Heading node='h2'>
                    <FormattedMessage id='contact.title' />
                </Heading>
            </div>

            <Grid container spacing={3}>
                <Grid item md={4} sm={12} className={classes.grid}>
                    <Frame
                        animate
                        show={true}
                        level={3}
                        hover={false}
                        corners={0}
                        layer='primary'
                        className={classes.frameRoot}
                    >
                        <ul className={classes.list}>
                            <li>
                                <FormattedMessage id='contact.list.email' values={{ a: (...chunks) => <NavLink to="mailto:info@discapegame.com" options={{ newTab: true }}>{chunks}</NavLink> }} />
                            </li>
                            <li>
                                <FormattedMessage id='contact.list.phone' values={{ a: (...chunks) => <NavLink to="https://calendly.com/discapegame-info/30min" options={{ newTab: true }}>{chunks}</NavLink> }} />
                            </li>
                        </ul>
                    </Frame>
                </Grid>
                <Grid item md={8} sm={12} className={classes.grid}>
                    <Frame
                        animate
                        show={true}
                        level={3}
                        hover={false}
                        corners={0}
                        layer='primary'
                        className={classes.frameRoot}
                    >
                        {getForm()}
                    </Frame>
                </Grid>
            </Grid>
        </>
    )
}