/*
 * MediaPlayer - Client Only
 */
import { openFolder, goHome, setContentPanel } from 'discapecommon/actions/index'
import { openEnigma } from 'discapecommon/actions/enigmaActions'
import { openApp } from 'discapecommon/actions/appActions'
import { viewMission } from '../actions/missionActions'
import { log } from '../utils'
import MENU_LIST from '../consts/menuList'
import { retrievePassword } from '../services/userService'
import navigationManager from '../navigationManager'
// Default state defined in consts
/*
  Menu system either for side menu or navbar
*/


const menuList = (state = MENU_LIST.default, action) => {
  switch (action.type) {
    case 'OPEN_LINK':
      // console.log('state ',state)
      const link = state.find(({ id }) => id == action.id);
      if (link) {
        // console.log('link ', link)
        switch (link.refType) {
          case 'ENIGMA':
            action.asyncDispatch(openEnigma(link.refId));
            break;
          case 'FOLDER':
            action.asyncDispatch(openFolder(link.refId));
            break;
          case 'APP':
            console.warn('OPEN_LINK Type APP is deprecated')
            action.asyncDispatch(openApp(link.refId, link.id)); // deprecated
            break;
          case 'MISSION':
            action.asyncDispatch(viewMission(link.refId));
            break;
          case 'CORE':
            switch (link.refId) {
              case 'GO_HOME':
                action.asyncDispatch(goHome());
                break;
            }
            break;
          case 'NAVIGATION':
            if (link.options?.newTab) {
              window.open(link.refId, '_blank').focus();
            }
            else {
              navigationManager.push(link.refId)
            }
            break;
          case 'NAVIGATION_IN_GAME':
            if (link.options?.newTab) {
              window.open(link.refId, '_blank').focus();
            }
            else {
              navigationManager.pushInGame(link.refId)
            }
            break;
          default:
            console.error('Unrecognized link.refType: ', link)
        }
      }
      return state;
    case 'CLT_LOAD_MISSION':
      console.log('missionKey : ', action.missionKey)
      if (MENU_LIST[action.missionKey])
        return MENU_LIST[action.missionKey]
      else {
        console.error('Unrecognized missionKey')
        return state;
      }
    case 'CLT_CHECK_LEAVE_SESSION':
    case 'CLT_MISSION_CLOSE_SESSION':
      return MENU_LIST.default;
    case 'SET_CURRENT_SCENE':
      if (!action.scene || action.scene == 'END_SCENE') {
        return MENU_LIST.default;
      }
      return state;
    case 'SET_LINK_STATUS':
      return state.filter(link => typeof link !== 'undefined').map(link => {
        if (typeof action.id == 'number') {
          if (link.id == action.id) {
            return { ...link, status: action.status };
          }
        }
        else if (action.id?.includes(link.id)) {
          return { ...link, status: action.status };
        }
        return link;
      })
    default:
      return state
  }
}

export default menuList
