import React from 'react';
import { Frame, Button } from 'arwes';
import { makeStyles } from '@material-ui/core/styles';
import { Heading, Image } from 'arwes'
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import BasicFrame from '../components/BasicFrame';
import navigationManager from '../navigationManager';
import photo from '/assets/images/woman-entertained2.jpg';
import handshake from '/assets/images/handshake.png';
import clientServiceGirl from '/assets/images/client-service.png';
import carte from '/assets/images/London2.png';
import SemiSection from '../components/SemiSection';
import help from '/assets/images/help.png'
import clock from '/assets/images/HomePage/clock.webp'
import players from '/assets/images/HomePage/players.png'
import SectionContact from './SectionContact';
import NavLink from '../components/NavLink';

const useStyles = makeStyles({
    root: {
        textAlign: 'left'
    },
    headingContainer: {
        textAlign: 'center',
        marginBottom: 42,
    },
    section1: {
        fontSize: '1.3em',
        margin: '24px',
    },
    sectionLeadIndustry: {
        margin: '48px 24px 32px 24px',
        '& .leadRow': {
            margin: '10px auto',
            'padding': '24px 36px',
        },
        '& .leadRow:nth-child(even)': {
            'background-color': 'rgb(3, 33, 38)',
            'border-radius': '16px'
        }
    },
    sectionTeam: {
        margin: '24px',
        textAlign: 'center'
    },
    sectionTeamLeadName: {
        textAlign: 'center',
        fontSize: '1.4em',
    },
    sectionTeamLeadTitle: {
        textAlign: 'center',
        fontSize: '1em',
    },
    sectionTeamLeadContent: {
        fontSize: '0.9em',
    },
});

function PrivacyPolicyPage() {
    const classes = useStyles();

    return <>
        <div className={classes.root}>
            {/* SECTION 1 */}
            <BasicFrame>
                <FormattedMessage id="privacyPolicy.content" values={{
                    h1: (...chunks) => <h1>{chunks}</h1>,
                    h2: (...chunks) => <h2>{chunks}</h2>,
                    h3: (...chunks) => <h3>{chunks}</h3>,
                    p: (...chunks) => <p>{chunks}</p>,
                    ul: (...chunks) => <ul>{chunks}</ul>,
                    li: (...chunks) => <li>{chunks}</li>,
                    strong: (...chunks) => <strong>{chunks}</strong>,
                    a: (...chunks) => <NavLink to="mailto:info@discapegame.com" options={{ newTab: true }}>{chunks}</NavLink>,
                    br: <br />,
                }} />
            </BasicFrame>
        </div>
    </>
}

export default PrivacyPolicyPage;