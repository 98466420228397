/*
* Stores if additional panel is open or close and the activePanel
*/
import {openAdditionalPanel, closeAdditionalPanel} from 'discapecommon/actions/index'

const defaultState = {
  CHAT: false,
  chatIsRead: true,
}

const additionalPanels = (state = defaultState, action) => {
  switch (action.type) {
    case 'OPEN_ADDITIONAL_PANEL':
      if(state[action.key] == true)
        return state;
      var newState = {...state};
      newState[action.key] = true;
      return newState;
    case 'CLOSE_ADDITIONAL_PANEL':
      if(state[action.key] == false)
        return state;
      var newState = {...state};
      // Specific cases
      switch(action.key) {
        case 'AUTH_POPUP':
          newState.LOG_IN = false;
          newState.SIGN_UP = false;
          break;
        default:
          newState[action.key] = false;
      }
      return newState;
    case 'TOGGLE_ADDITIONAL_PANEL':
      var newState = {...state};
      newState[action.key] = !newState[action.key];
      return newState;
    case 'OPEN_COUNT_DOWN_FULLSCREEN':
      action.asyncDispatch(openAdditionalPanel('COUNT_DOWN_FULLSCREEN'));
      return {...state, activeCountDownFullscreen:action.id}
    case 'CLOSE_COUNT_DOWN_FULLSCREEN':
      action.asyncDispatch(closeAdditionalPanel('COUNT_DOWN_FULLSCREEN'));
      return {...state, activeCountDownFullscreen:null}
    case 'OPEN_ENIGMA':
      action.asyncDispatch(openAdditionalPanel('ENIGMA'));
      return {...state, activeEnigma:action.id}
    case 'CLOSE_ENIGMA':
      action.asyncDispatch(closeAdditionalPanel('ENIGMA'));
      return {...state, activeEnigma:null}
    default:
      return state;
  }
}

export default additionalPanels
