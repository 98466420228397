/*
 * Mission Data: name, id
 */
import {missionTypes} from 'discapecommon/consts/missionTypes'
import defaultMissions from '../consts/missionModels'
/*
Liste des missions disponibles
*/

const missions = (state = defaultMissions, action) => {
  switch (action.type) {
    case missionTypes.MISSION_UPDATE_INFO:
      return state.map(mission => {
        if(mission.key == action.mission.key) {
          // console.log('action ', action.mission)
          return {...mission, ...action.mission}
        }
        else
          return mission;
      })
    default:
      return state
  }
}

export default missions
