import * as catalogService from '../services/catalogService';
import * as missionService from '../services/missionService';
import { setContentPanel } from 'discapecommon/actions/index'
import { loadStage } from 'discapecommon/actions/gameFlowActions'
import { missionTypes } from 'discapecommon/consts/missionTypes'
import { compareObjects } from '../utils'
import { IoTimeoutError } from '../services/IoPromiseError';
import { addNotification } from './notificationActions';
import navigationManager from '../navigationManager';

export function viewMission(missionKey) {
  return dispatch => {
    catalogService.getMissionInfos(missionKey)
      .then(
        response => {
          dispatch(updateMissionInfos(response.article));
        },
        error => {
          navigationManager.pushError404();
          console.log('Unable to get mission infos')
        }
      );
    catalogService.getMissionLockStatus(missionKey)
      .then(
        lockStatus => {
          dispatch(transmitViewMission(missionKey, lockStatus));
        },
        error => {
          dispatch(transmitViewMission(missionKey, false)); // Todo: correct this
        }
      );
  };

  function transmitViewMission(missionKey, lockStatus) { return { type: missionTypes.VIEW_MISSION, missionKey, lockStatus } }
  function updateMissionInfos(mission) { return { type: missionTypes.MISSION_UPDATE_INFO, mission } }
}



export function sessionSelectorSetFilter(filterType, value) {
  return {
    type: 'SESSION_SELECTOR_SET_FILTER',
    filterType,
    value
  }
}

export function fetchSessions() {
  return (dispatch, getState) => {
    missionService.fetchSessions()
      .then(
        res => {
          const sessions = getState().instances
          // console.log('sessions ',res.sessions, sessions, compareObjects(sessions, res.sessions))
          if (!compareObjects(sessions, res.sessions))
            dispatch(pushSessionList(res.sessions))
        },
        error => {
          console.error(error);
        }
      )
  }

  function pushSessionList(list) { return { type: 'PUSH_SESSION_LIST', list } }
}

export function getStage() {
  return (dispatch, getState) => {
    const state1 = getState();
    const sessionId = state1.session?.id;
    const userId = state1.user?.id;
    missionService.getStage(sessionId, userId)
      .then(
        res => {
          const { stage } = getState().gameFlow
          // console.log('stage ',stage, res.stage)
          if (stage < res.stage) {
            dispatch(loadStage(res.stage))
          }
        },
        error => {
          if (error instanceof IoTimeoutError) {
            dispatch(addNotification('Timeout error'))
          }
          else {
            dispatch(addNotification('Unknown error contacting the server'))
            console.error(error);
          }
        }
      )
  }
}

export const loadMission = (missionKey) => ({ type: 'CLT_LOAD_MISSION', missionKey })

export const cltTriggerStartMission = (state) => ({
  type: 'CLT_TRIGGER_START_MISSION',
  state
})

export const cltStartMission = (state) => ({ type: 'CLT_START_MISSION', state })

export const registerIsPendingBuy = () => ({ type: 'MISSION_REGISTER_IS_PENDING_BUY' })

export const cltCloseSession = (missionKey) => ({ type: 'CLT_MISSION_CLOSE_SESSION', missionKey })

export const cltMissionEnd = () => ({ type: 'CLT_MISSION_END' })