const defaultState = []

/*
Check also Alerts
WIP
List of the notification such as "someone invited you"
*/

export default function registration(state = defaultState, action) {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      return [...state, { message: action.message, isVisible: true }];
    default:
      return state
  }
}