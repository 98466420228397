/*
 * demoBooking
 */

import { demoTypes } from 'discapecommon/consts/demoTypes';

/*
  Presentation de la endingPanel
*/

const defaultState = {
    bookingState: 'normal'
}

const demoBooking = (state = defaultState, action) => {
    switch (action.type) {
        case demoTypes.DEMO_BOOK_NORMAL:
            return { ...state, bookingState: 'normal' };
        case demoTypes.DEMO_BOOK_PENDING:
            return { ...state, bookingState: 'pending' };
        case demoTypes.DEMO_BOOK_SUCCESS:
            return { ...state, bookingState: 'success' };
        case demoTypes.DEMO_BOOK_ERROR:
            return { ...state, bookingState: 'error', errorMessageKey: action.errorMessageKey };
        default:
            return state
    }
}

export default demoBooking
