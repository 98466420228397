export const cltMissilGouvButtonClick = (id) => ({
    type: 'CLT_MISSIL_GOUV_BUTTON_CLICK', id
});

export const upMissilGouvEmergencyClick = () => ({
    type: 'CLT_MISSIL_GOUV_EMERGENCY_CLICK'
});

export const cltTelemetrySubmit = data => ({
    type: 'CLT_TELEMETRY_SUBMIT', data
})

export function upGetTelemetryRole() {
    return {
        type: 'UP_GET_TELEMETRY_ROLE',
        meta: {
            sendServer: true
        }
    };
}
export const upMissilGouvEntries = entries => ({
    type: 'UP_MISSIL_GOUV_ENTRIES', entries, meta: { sendServer: true }
})

export const upTelemetryNewIndex = (index, points) => ({
    type: 'UP_TELEMETRY_NEW_INDEX',
    index, points, meta: { sendServer: true }
})
export const upGetMissilGouvRole = () => ({
    type: 'UP_GET_MISSIL_GOUV_ROLE', meta: { sendServer: true }
})

export const upMissilGouvSetPhase1Status = status => ({
    type: 'UP_MISSIL_GOUV_SET_PHASE1_STATUS', status, meta: { sendServer: true }
})

export const upMissilGouvSetPhase2Status = status => ({
    type: 'UP_MISSIL_GOUV_SET_PHASE2_STATUS', status, meta: { sendServer: true }
})
export const telemetrySuccess = () => ({
    type: 'TELEMETRY_SUCCESS'
})

