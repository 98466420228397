import React from 'react';
import { Frame } from 'arwes';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Heading } from 'arwes'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: 1200,
    '@media (min-width: 800px) and  (max-width: 1200px)': {
      maxWidth: 'calc(100vw - 32px)',
    }
  },
  content: {
    padding: 16,
  },
  heading: {
    marginTop: 12,
  },
  details: {
    display: 'block',
    textAlign: 'left',
  }
});

function HelpPageFAQ() {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <div className={classes.root}>
      <Frame
        animate
        show={true}
        level={3}
        hover={false}
        corners={width < 800 ? 0 : 4}
        layer='primary'
      >
        <div className={classes.content}>
          <Heading node='h2' className={classes.heading}>FAQ</Heading>
          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Heading node='h3' className={classes.heading}><FormattedMessage id="faq.howDoesItWork" /></Heading>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormattedMessage id="faq.howDoesItWorkText" values={{ a: (...chunks) => <a href="mailto:info@discapegame.com">{chunks}</a> }} />
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Heading node='h3' className={classes.heading}><FormattedMessage id="faq.willIBeGroupedWithStrangers" /></Heading>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormattedMessage id="faq.willIBeGroupedWithStrangersText" />
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Heading node='h3' className={classes.heading}><FormattedMessage id="faq.ageLimit" /></Heading>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormattedMessage id="faq.ageLimitText" />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Heading node='h3' className={classes.heading}><FormattedMessage id="faq.largeGroup" /></Heading>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormattedMessage id="faq.largeGroupText" values={{ a: (...chunks) => <a href="mailto:info@discapegame.com">{chunks}</a> }} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Heading node='h3' className={classes.heading}><FormattedMessage id="faq.howLongDoesAGameLast" /></Heading>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormattedMessage id="faq.howLongDoesAGameLastText" />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Heading node='h3' className={classes.heading}><FormattedMessage id="faq.gameLanguage" /></Heading>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormattedMessage id="faq.gameLanguageText" />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Heading node='h3' className={classes.heading}><FormattedMessage id="faq.IHaveBeenDisconnected" /></Heading>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormattedMessage id="faq.IHaveBeenDisconnectedText" />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Heading node='h3' className={classes.heading}><FormattedMessage id="faq.isThereHelpAvailable" /></Heading>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormattedMessage id="faq.isThereHelpAvailableText" />

              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Heading node='h3' className={classes.heading}><FormattedMessage id="faq.isMyDeviceCompatible" /></Heading>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <FormattedMessage id="faq.isMyDeviceCompatibleText" values={{ a: (...chunks) => <a href="mailto:info@discapegame.com">{chunks}</a> }} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </Frame>
    </div>
  );
}

export default HelpPageFAQ
