/*
 * Mission Data: name, id
 */
import { openAdditionalPanel, setCurrentScene, upLeaveSession, setContentPanel } from 'discapecommon/actions/index'
import { openEnigma } from 'discapecommon/actions/enigmaActions'
import { loadMission, cltTriggerStartMission, cltStartMission, cltCloseSession } from '../actions/missionActions'
import { log } from '../utils'
import browserHistory from 'discapecommon/browserHistory'
import { goHome } from 'discapecommon/actions/index'

/*
gameSession
*/

const defaultState = {}

const session = (state = defaultState, action) => {
  switch (action.type) {
    case 'PUSH_SESSION_STATE':
      switch (action.state.status) {
        case 'PLAYING':
        case 'FAIL':
        case 'SUCCESS':
          action.asyncDispatch(setCurrentScene('MISSION'));
          action.asyncDispatch(loadMission(action.state.missionKey));
          action.asyncDispatch(cltTriggerStartMission(action.state));
          break;
        default: // LOBBY OR START
          if (action.state.mode != "solo") {
            action.asyncDispatch(setCurrentScene('LOBBY'));
          }
          else {
            action.asyncDispatch(loadMission(action.state.missionKey));
          }
          break;
      }
      setTimeout(() => browserHistory.push('/play/' + action.state.id), 10);
      return { ...action.state, sessionUsers: undefined };
    case 'PUSH_START_ACTIVITY':
      action.asyncDispatch(loadMission(action.activityId));
      return { ...state, status: 'PLAYING' };
    case 'SET_CURRENT_SCENE':
      if (!action.scene || action.scene == 'MAIN_DASHBOARD' && state.id) {
        action.asyncDispatch(cltCloseSession(state.missionKey))
        action.asyncDispatch(upLeaveSession())
      }
      return state;
    case 'CLT_CHECK_LEAVE_SESSION':
    case 'VIEW_MISSION':
      if (state.id) {
        action.asyncDispatch(cltCloseSession(state.missionKey))
        action.asyncDispatch(upLeaveSession())
      }
      return defaultState;
    case 'CLT_MISSION_CLOSE_SESSION':
    case 'CLT_CLOSE_SESSION':
      return defaultState;
    case 'DOWN_SESSION_UNABLE_TO_FIND':
      // console.error('Unable to find session',action.id);
      action.asyncDispatch(openEnigma(22));
      return state;
    case 'ENIGMA_SOLVED':
      if (action.id == 22) // Dialog unable to find session
        action.asyncDispatch(goHome())
      return state;
    case 'DOWN_USER_REMOVED_FROM_GROUP':
      action.asyncDispatch(openEnigma(24));
      action.asyncDispatch(cltCloseSession())
      action.asyncDispatch(setContentPanel());
      action.asyncDispatch(setCurrentScene());
      setTimeout(() => browserHistory.push('/'), 10)
      return defaultState;
    default:
      return state
  }
}

export default session
