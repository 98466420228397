import { fr } from '../localeData/fr'
import { en } from '../localeData/en'
import MENU_LIST from './menuList'

export const LOCALES = {
  locale: 'fr',
  fr,
  en
}


// Answers Separator: ","
// Possible answer types: "number, text, ..."

const initialLocale = localStorage.getItem('locale')
const language = initialLocale ? initialLocale : (navigator.language == 'fr' ? 'fr' : 'en')


export const INTL = {
  defaultLocale: language,
  locale: language,
  messages: language == 'fr' ? fr : en,
}

import { ENIGMAS } from './enigmas';

export default {
  locales: LOCALES,
  intl: INTL,
  enigmas: ENIGMAS,
  menuList: MENU_LIST.default
}