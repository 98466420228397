export const privacyPolicyEn = `
    <h1>DISCAPEGAME.COM PRIVACY POLICY</h1>

    <p><strong>Last Updated: November 1, 2024</strong></p>

    <h2>Introduction</h2>

    <p>At Discapegame.com ("we", "us", or "our"), we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our online escape game services, website, and related offerings (collectively, the "Services").</p>

    <h2>Information We Collect</h2>

    <h3>Personal Information You Provide</h3>

    <p>We collect personal information that you voluntarily provide to us when you use our Services, including:</p>

    <ul>
        <li>Contact information (e.g., name, email address, phone number)</li>
        <li>Account credentials (e.g., username, password)</li>
        <li>Payment and billing information</li>
        <li>Demographic information (e.g., date of birth, gender)</li>
        <li>Information you submit in game chats, surveys, or communications with us</li>
    </ul>

    <h3>Information Collected Automatically</h3>

    <p>When you use our Services, we may automatically collect certain information, including:</p>

    <ul>
        <li>Log data (e.g., IP address, browser type, pages visited)</li>
        <li>Device information (e.g., device ID, operating system)</li>
        <li>Usage data (e.g., clickstream data, gameplay statistics)</li>
        <li>Location data</li>
    </ul>

    <p>We use cookies, web beacons, and similar tracking technologies to collect some of this information.</p>

    <h2>How We Use Your Information</h2>

    <p>We may use your personal information to:</p>

    <ul>
        <li>Provide and improve our Services</li>
        <li>Process payments and fulfill orders</li>
        <li>Communicate with you about our Services</li>
        <li>Personalize your experience</li>
        <li>Analyze usage of our Services</li>
        <li>Protect the security and integrity of our Services</li>
        <li>Comply with legal obligations</li>
    </ul>

    <h2>Sharing of Your Information</h2>

    <p>We may share your personal information with:</p>

    <ul>
        <li>Service providers that perform services on our behalf</li>
        <li>Affiliated companies within our corporate group</li>
        <li>Third parties for marketing purposes (with your consent)</li>
        <li>Other users (e.g., in multiplayer games or chats)</li>
        <li>Law enforcement or other parties when required by law</li>
    </ul>

    <h2>Your Choices</h2>

    <p>You can control your information by:</p>

    <ul>
        <li>Updating your account settings</li>
        <li>Opting out of marketing communications</li>
        <li>Disabling cookies in your browser settings</li>
        <li>Requesting access, correction, or deletion of your data</li>
    </ul>

    <h2>Security</h2>

    <p>We implement reasonable security measures to protect your information. However, no method of transmission or storage is 100% secure.</p>

    <h2>Children's Privacy</h2>

    <p>Our Services are not intended for children under 13. We do not knowingly collect personal information from children under 13.</p>

    <h2>Changes to This Policy</h2>

    <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the new policy on this page.</p>

    <h2>Contact Us</h2>

    <p>If you have any questions about this Privacy Policy, please contact us at:</p>

    <p>Email: <a>info@discapegame.com</a></p>
    <p>Address: 1833 rue Sherbrooke Est, H2K1B4, Montreal, QC, Canada</p>
`