import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    semiSection: {
        margin: 'auto -40px',
        padding: '0 40px',
        backgroundColor: 'rgba(0,8,10,0.3)',
    },
});

export default function SemiSection({ children, flex }) {
    const classes = useStyles();

    return (
        <div className={classes.semiSection} style={{ display: flex ? 'flex' : '' }}>{children}</div>
    );
}