/*
 * File Tree
 */
import { playMedia } from '/src/actions/mediaActions'
import { openApp, openDatasheet } from 'discapecommon/actions/appActions'
import { openFolder, triggerCustomEvent } from 'discapecommon/actions/index'
import { openEnigma } from 'discapecommon/actions/enigmaActions'
import { log } from '../utils'
import FILE_TREE from '../consts/fileTree'
import navigationManager from '../navigationManager'


const fileTree = (state = [], action) => {
  switch (action.type) {
    case 'SET_ACTIVE_TREE_ITEM':
      if (action.id == null) {
        // Back to root
        // action.asyncDispatch(openFolder(null));
        return state;
      }
      let item = state.find(({ id }) => id == action.id)
      if (typeof item === 'undefined') {
        console.error('File undefined: ', action)
        return state
      }
      if (item.status == 'PROTECTED') {
        if (item.enigmaId) {
          action.asyncDispatch(openEnigma(item.enigmaId))
        }
        else
          console.error('Undefined enigmaId protecting item')
        return state;
      }
      console.log('openApp ', openApp(item.refId, action.id))
      switch (item.type) {
        case 'AUDIO': case 'VIDEO': case 'IMAGE': case 'MEDIA':
          action.asyncDispatch(playMedia(item.refId));
          break;
        case 'FOLDER': action.asyncDispatch(openFolder(action.id)); break;
        case 'APP': action.asyncDispatch(openApp(item.refId, action.id)); break;
        case 'DATASHEET': action.asyncDispatch(openDatasheet(item.refId, action.id)); break;
        case 'CUSTOM_EVENT': action.asyncDispatch(triggerCustomEvent(item.refValue)); break;
        default: console.error('Unrecognized file type: ', item.type);
      }
      return state;
    case 'CLT_SET_FILES_STATUS':
      return state.map(file => {
        if (typeof action.files == 'number') {
          if (action.files == file.id)
            return { ...file, status: action.status }
          return file;
        }
        else if (action.files.includes(file.id)) {
          return { ...file, status: action.status }
        }
        return file;
      })
    case 'GO_BACK_FOLDER':
      var folder = state.find(({ id }) => id == action.id);
      navigationManager.viewInGameTreeItem(folder?.parentId)
      return state;
    case 'CLT_FILE_TREE_LOAD_TREE':
      return FILE_TREE.filter(({ treeId }) => treeId == action.id)
    default:
      return state
  }
}

export default fileTree
