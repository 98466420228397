/*
 * Mission Data: name, id
 */
import {openAdditionalPanel, setCurrentScene} from 'discapecommon/actions/index'
import {log} from '../utils'

/*
Only one value per filter type
The sessions are stored in common instances
*/

const defaultState = [{
  type: 'MISSION_KEY',
  value: 'all',
}]

const sessionSelector = (state = defaultState, action) => {
  switch (action.type) {
    case 'SESSION_SELECTOR_SET_FILTER':
      return state.map(filter => {
        if(filter.type == action.filterType)
          return {...filter, value: action.value}
        else
          return filter;
      })
  }
  return state;
}

export default sessionSelector
