export default [
  // treason avant acceptation
  {
    id: 1000,
    parentId: null,
    // name: 'Rappeler Joe',
    type: 'CUSTOM_EVENT',
    icon: 'PHONE',
    refValue: 'CE_CALL_JOE',
    treeId: 1,
  },
  {
    id: 1001,
    parentId: null,
    // name: 'Dossier crypté de Vilamo',
    type: 'FOLDER',
    status: 'PROTECTED',
    enigmaId: 8,
    treeId: 2,
  },
  // {
  //   id:3001,
  //   parentId: null,
  //   // name: 'PLAYGROUND',
  //   type: 'APP',
  //   refId: 'PLAYGROUND',
  //   treeId: 2,
  // },
  {
    id: 1002,
    parentId: 1001,
    // name: 'MissilGouv 2.3',
    type: 'APP',
    refId: 'MISSIL_GOUV',
    treeId: 2,
  },
  {
    id: 1003,
    parentId: null,
    // name: 'Sergent Vilamo',
    type: 'DATASHEET',
    refId: 'Vilamo',
    treeId: 2,
  },
  {
    id: 1004,
    parentId: null,
    // name: 'Carte transport - Sergent Vilamo',
    type: 'IMAGE',
    refId: 7,
    treeId: 2,
  },
  {
    id: 1005,
    parentId: 1001,
    // name: 'Note Vilamo Part 1/2',
    type: 'AUDIO',
    status: 'PROTECTED',
    refId: 1010,
    enigmaId: 115,
    treeId: 2,
  },
  {
    id: 1006,
    parentId: 1001,
    // name: 'Note Vilamo Part 2/2',
    type: 'AUDIO',
    status: 'PROTECTED',
    enigmaId: 115,
    refId: 1011,
    treeId: 2,
  },
  {
    id: 1020,
    parentId: 1001,
    // name: 'Image military base',
    type: 'IMAGE',
    refId: 8,
    treeId: 2,
  },
  // COLD GAME: TREE ID: 10
  {
    id: 1,
    parentId: null,
    // name: 'Fichiers M27',
    type: 'FOLDER',
    treeId: 10,
  },
  {
    id: 10,
    parentId: 1,
    // name: 'Notes de Malory',
    type: 'FOLDER',
    treeId: 10,
  },
  {
    id: 11,
    parentId: 10,
    // name: 'Notes '+getTextDate(-6),
    type: 'VIDEO',
    refId: 104,
    treeId: 10,
  },
  {
    id: 12,
    parentId: 10,
    // name: 'Notes '+getTextDate(-5),
    type: 'VIDEO',
    refId: 105,
    treeId: 10,
  },
  {
    id: 13,
    parentId: 10,
    // name: 'Notes '+getTextDate(-1),
    type: 'VIDEO',
    refId: 106,
    treeId: 10,
  },
  {
    id: 102,
    parentId: null,
    // name: 'SatCell',
    type: 'APP',
    refId: 'SATCELL',
    treeId: 10,
  },
  {
    id: 200,
    parentId: null,
    // name: 'Cyber Detector',
    type: 'APP',
    refId: 'CYBER_DETECTOR',
    treeId: 10,
    status: 'INVISIBLE',
  },
  {
    id: 201,
    parentId: null,
    // name: 'Cyber Counter',
    type: 'APP',
    refId: 'CYBERCOUNTER',
    treeId: 10,
    status: 'INVISIBLE',
  },
  {
    id: 205,
    parentId: null,
    // name: 'Fiche: JMX',
    type: 'DATASHEET',
    refId: 'JMX',
    treeId: 10,
    status: 'INVISIBLE',
  },
  // {
  //   id:206,
  //   parentId: null,
  //   // name: 'Decryptage des adresses',
  //   type: 'APP',
  //   refId: 'IP_DECODER',
  //   status: 'PROTECTED',
  //   enigmaId: 24,
  //   treeId: 10,
  //   status: 'INVISIBLE',
  // },
  {
    id: 302,
    parentId: 1,
    // name: 'Suspects',
    type: 'FOLDER',
    treeId: 10,
  },
  {
    id: 303,
    parentId: 302,
    // name: 'Judith Rivoli',
    type: 'DATASHEET',
    refId: 'Judith',
    treeId: 10,
  },
  {
    id: 304,
    parentId: 302,
    // name: 'Nils Brown',
    type: 'DATASHEET',
    refId: 'Nils',
    treeId: 10,
  },
  {
    id: 305,
    parentId: 302,
    // name: 'José Paquin',
    type: 'DATASHEET',
    refId: 'Jose',
    treeId: 10,
  },
  {
    id: 306,
    parentId: 302,
    // name: 'Ariane Estapolo',
    type: 'DATASHEET',
    refId: 'Ariane',
    treeId: 10,
  },
  {
    id: 309,
    parentId: null,
    // name: 'José Paquin files',
    type: 'FOLDER',
    treeId: 10,
    status: 'INVISIBLE',
  },
  {
    id: 310,
    parentId: 309,
    // name: 'Interrogatoire',
    type: 'FOLDER',
    treeId: 10,
  },
  {
    id: 311,
    parentId: 310,
    // name: 'Questions préliminaires',
    type: 'VIDEO',
    refId: 319,
    treeId: 10,
  },
  {
    id: 312,
    parentId: 310,
    // name: 'Interrogatoire',
    type: 'VIDEO',
    refId: 320,
    treeId: 10,
  },
  {
    id: 313,
    parentId: 309,
    // name: 'José Paquin fiche',
    type: 'DATASHEET',
    refId: 'Jose',
    treeId: 10,
  },
  {
    id: 314,
    parentId: 309,
    // name: 'Arrestation',
    type: 'IMAGE',
    refId: 314,
    treeId: 10,
  },
  {
    id: 315,
    parentId: 309,
    // name: 'Arrestation',
    type: 'IMAGE',
    refId: 311,
    treeId: 10,
  },
  {
    id: 316,
    parentId: 309,
    // name: 'Fiançailles',
    type: 'IMAGE',
    refId: 312,
    treeId: 10,
  },
  {
    id: 317,
    parentId: 309,
    // name: 'Amant',
    type: 'IMAGE',
    refId: 313,
    treeId: 10,
  },
  {
    id: 318,
    parentId: null,
    // name: 'Message Crypté José Paquin',
    type: 'DATASHEET',
    refId: 'PaquinCrypted',
    treeId: 10,
    status: 'INVISIBLE',
  },
  {
    id: 340,
    parentId: null,
    // name: 'Liste des vols',
    type: 'APP',
    refId: 'FLIGHTLIST',
    treeId: 10,
    status: 'INVISIBLE',
  },
  {
    id: 341,
    parentId: null,
    // name: 'Convertisseur binaire',
    type: 'APP',
    refId: 'BINARY_CONVERTER',
    treeId: 10,
    status: 'INVISIBLE',
  },
  {
    id: 400,
    parentId: null,
    // name: 'Concerts',
    type: 'APP',
    refId: 'LONDON_SHOW_LIST',
    treeId: 10,
    status: 'INVISIBLE',
  },
  {
    id: 461,
    parentId: null,
    // name: 'Effets Vocaux',
    type: 'APP',
    refId: 'VOCAL_EFFECT',
    treeId: 10,
    status: 'INVISIBLE',
  },
  {
    id: 480,
    parentId: null,
    // name: 'Carte',
    type: 'APP',
    refId: 'GOOGLE_MAPS_SEARCH',
    treeId: 10,
    status: 'INVISIBLE',
  },
  {
    id: 449,
    parentId: null,
    // name: 'Les Cloches du Monde',
    type: 'FOLDER',
    treeId: 10,
    status: 'INVISIBLE',
  },
  // Cloches: Pas besoin de rendre invisible
  {
    id: 450,
    parentId: 449,
    // name: 'Almudena-Madrid-Spain',
    type: 'AUDIO',
    refId: 450,
    treeId: 10,
  },
  {
    id: 451,
    parentId: 449,
    // name: 'Alte Kirche Alendorf - Kalvarienberg - Germany',
    type: 'AUDIO',
    refId: 451,
    treeId: 10,
  },
  {
    id: 452,
    parentId: 449,
    // name: 'Basilica De La Cathedral - Cusco - Peru',
    type: 'AUDIO',
    refId: 452,
    treeId: 10,
  },
  {
    id: 453,
    parentId: 449,
    // name: 'BigBen - London - England',
    type: 'AUDIO',
    refId: 453,
    treeId: 10,
  },
  {
    id: 454,
    parentId: 449,
    // name: 'Santander Cathedral - Santander-Spain',
    type: 'AUDIO',
    refId: 454,
    treeId: 10,
  },
  {
    id: 456,
    parentId: 449,
    // name: 'St.Mary Le Bow - London - England',
    type: 'AUDIO',
    refId: 456,
    treeId: 10,
  },
  {
    id: 457,
    parentId: 449,
    // name: 'St.Pauls Church - London - England',
    type: 'AUDIO',
    refId: 457,
    treeId: 10,
  },
  {
    id: 460,
    parentId: null,
    // name: 'Enregistrements de M',
    type: 'FOLDER',
    treeId: 10,
    status: 'INVISIBLE',
  },
  {
    id: 465,
    parentId: 460,
    // name: 'M Enregistrement 1',
    type: 'AUDIO',
    refId: 501,
    treeId: 10,
  },
  {
    id: 462,
    parentId: 460,
    // name: 'M Enregistrement 2',
    type: 'AUDIO',
    refId: 502,
    treeId: 10,
  },
  {
    id: 463,
    parentId: 460,
    // name: 'M Enregistrement 3',
    type: 'AUDIO',
    refId: 503,
    treeId: 10,
  },
  {
    id: 464,
    parentId: 460,
    // name: 'M Enregistrement 4',
    type: 'AUDIO',
    refId: 504,
    treeId: 10,
  },
]