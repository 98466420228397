/*
 * MediaPlayer - Client Only
 */
import {openAdditionalPanel, setContentPanel} from 'discapecommon/actions/index'
import {log} from '../utils'
import extend from 'extend'

/*
Handles file opening in the explorer
*/

const mediaPlayer = (state = [], action) => {
  switch (action.type) {
    // Open the player and trigger playing
    case 'PLAY_MEDIA':
      if(action.mode != 'DEFAULT')
        return state;
      if(state.find(({id}) => id == action.id))
        return state;
      action.asyncDispatch(openAdditionalPanel('MEDIA_PLAYER'));
      return [...state, {
        id: action.id,
        status: 'PLAY',
      }]
    case 'CLOSE_FILE_PANEL': 
      return state.filter(({id}) => id != action.id);
    // Deprecated
    // case 'MEDIA_PLAY':
    //   var newState = extend({}, state);
    //   newState.status = 'PLAY';
    //   return newState;
    // case 'MEDIA_PAUSE':
    //   var newState = extend({}, state);
    //   newState.status = 'PAUSE';
    //   return newState;
    // case 'MEDIA_STOP':
    //   var newState = extend({}, state);
    //   newState.status = 'STOP';
    //   return newState;
    // case 'MEDIA_END':
    //   var newState = extend({}, state);
    //   newState.status = 'STOP';
    //   return newState;
    default:
      return state
  }
}

export default mediaPlayer
