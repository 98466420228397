import React from 'react'
import browserHistory from 'discapecommon/browserHistory'
import store from './store'
import { viewMission } from './actions/missionActions'
import { userActions } from './actions/userActions'
import { joinSession, applyHome, openStaticLink, goHome, goSceneHome } from 'discapecommon/actions/index'
import pageList from './pages/pageList'
import { setContentPanel } from 'discapecommon/actions/index'
import { setActiveTreeItem } from 'discapecommon/actions/index'
import { openApp } from 'discapecommon/actions/appActions'
import { alertActions } from 'discapecommon/actions/alertActions';
import { gtagView } from './core/GTagManager'

class NavigationManager {
  constructor() {
    this.previousPathname = '/';

    browserHistory.listen(({ location }) => {
      this.handlePathname(location.pathname);
    })

    setTimeout(() => this.handlePathname(browserHistory.location.pathname), 10)
  }

  handlePathname(pathname) {
    // console.log('pathname', pathname)
    if (pathname == this.previousPathname) {
      return;
    }
    this.previousPathname = pathname;

    if (pathname == '/') {
      store.dispatch(openStaticLink('STATIC_LINK_GO_HOME'))
      this.scrollToTop();
      gtagView('home');
      return;
    }
    else {
      let params = pathname.split('/').filter(value => value != '')

      let param1 = '';
      if (params.length > 1) {
        param1 = params[1];
      }

      const page = pageList.find(p => p.pathname == params[0])
      if (page) {
        store.dispatch(setContentPanel(page.pathname));
        gtagView('page-' + page.pathname);
      }
      else {
        switch (params[0]) {
          case 'browse':
            store.dispatch(openStaticLink('MISSION_LIST'));
            gtagView('mission-list');
            break;
          case 'session': case 'play':
            var state = store.getState();
            if (state.session?.id != param1 || state.mainInterface.scene != 'MISSION') {
              param1 = param1.replace('#', '')
              store.dispatch(joinSession(param1));
              gtagView('join-session-' + param1);
            }
            else if (params.length > 2) { // else: Pas de tree navigation si on vient juste de rejoindre
              // Handle tree navigation
              if (params[2] == 'folder') {
                if (params.length > 3) {
                  store.dispatch(setActiveTreeItem(params[3]));
                  gtagView('mission-folder-' + params[3]);
                }
                else {
                  console.error('navigation: undefined folder')
                }
              }
              else if (params[2] == 'hints') {
                gtagView('mission-hints');
                store.dispatch(setContentPanel('HINT'));
              }
              else if (params[2] == 'config') {
                gtagView('mission-config');
                store.dispatch(setContentPanel('CONFIG'));
              }
              else if (params[2] == 'app') {
                if (params.length > 3) {
                  gtagView('mission-app-' + params[3]);
                  store.dispatch(openApp(params[3]));
                }
                else {
                  console.error('navigation: undefined app')
                }
              }
              else if (params[2] == 'profile') {
                this.handleProfile()
                gtagView('mission-profile');
              }
            }
            else if (state.mainInterface.scene == 'MISSION') {
              store.dispatch(goSceneHome());
            }
            break;
          case 'mission': case 'title':
            store.dispatch(viewMission(param1));
            gtagView('view-title');
            break;
          case 'password-reset': store.dispatch(userActions.verifyResetToken(param1)); gtagView('password-reset'); break;
          case 'profile': this.handleProfile(); gtagView('profile'); break;
          case 'checkout_cancel': gtagView('stripe-checkout-cancel'); break;
          default: console.error('PAGE NOT FOUND'); gtagView('not-found'); break;
        }
      }
    }

    this.scrollToTop()
  }

  scrollToTop() {
    let mainRoot = document.getElementsByClassName('arwes-main-root')
    if (mainRoot.length == 1) {
      mainRoot[0].scrollTo(0, 0)
    }
  }

  handleProfile() {
    store.dispatch(setContentPanel('PROFILE'))
  }

  push(pathname) {
    setTimeout(() => {
      browserHistory.push(pathname)
    }, 1)
  }

  pushInGame(pathname) {
    let params = location.pathname.split('/').filter(value => value != '')

    if (params[0] != 'play') {
      this.push('/' + pathname)
      console.error('InGame navigation with no play in the params')
      return;
    }

    if (!pathname) {
      this.push('/' + params[0] + '/' + params[1])
      return;
    }

    this.push('/play/' + params[1] + '/' + pathname)
  }

  viewInGameTreeItem(id) {
    let params = location.pathname.split('/').filter(value => value != '')

    if (!id || Number.isNaN(id)) {
      if (params.length >= 2 && params[0] == 'play') {
        this.push('/' + params[0] + '/' + params[1])
      }
      else {
        this.push('/')
      }
      return;
    }

    if (params[0] != 'play') {
      this.push('/' + id)
      console.error('InGame navigation with no play in the params')
      return;
    }

    this.push('/play/' + params[1] + '/folder/' + id)
  }

  pushRoot() {
    let params = location.pathname.split('/').filter(value => value != '')
    if (params[0] == 'play' && params.length >= 2) {
      this.push('/' + params[0] + '/' + params[1])
      return;
    }
    this.push('/')
  }

  pushMainDashboard() {
    setTimeout(() => {
      browserHistory.push('/')
    }, 10)
  }

  viewMission(missionKey) {
    this.push('/mission/' + missionKey)
  }

  pushError404() {
    this.push('/');
    setTimeout(() => store.dispatch(alertActions.error('error.pageUnfound')), 1)
  }
}

export default new NavigationManager();