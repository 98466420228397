export const privacyPolicyFr = `
    <h1>POLITIQUE DE CONFIDENTIALITÉ DE DISCAPEGAME.COM</h1>

<p><strong>Dernière mise à jour : 1er novembre 2024</strong></p>

<h2>Introduction</h2>

<p>Chez Discapegame.com (« nous », « notre » ou « nos »), nous respectons votre vie privée et nous nous engageons à protéger vos informations personnelles.
Cette Politique de Confidentialité explique comment nous collectons, utilisons, divulguons et protégeons vos informations lorsque vous utilisez nos services de jeu d'évasion en ligne,
notre site web et nos offres connexes (collectivement, les « Services »).</p>

<h2>Informations que nous collectons</h2>

<h3>Informations personnelles que vous fournissez</h3>

<p>Nous collectons les informations personnelles que vous nous fournissez volontairement lorsque vous utilisez nos Services, notamment :</p>

<ul>
    <li>Informations de contact (par ex., nom, adresse e-mail, numéro de téléphone)</li>
    <li>Identifiants de compte (par ex., nom d'utilisateur, mot de passe)</li>
    <li>Informations de paiement et de facturation</li>
    <li>Informations démographiques (par ex., date de naissance, sexe)</li>
    <li>Informations que vous soumettez dans les chats de jeu, les sondages ou les communications avec nous</li>
</ul>

<h3>Informations collectées automatiquement</h3>

<p>Lorsque vous utilisez nos Services, nous pouvons collecter automatiquement certaines informations, notamment :</p>

<ul>
    <li>Données de journal (par ex., adresse IP, type de navigateur, pages visitées)</li>
    <li>Informations sur l'appareil (par ex., identifiant de l'appareil, système d'exploitation)</li>
    <li>Données d'utilisation (par ex., données de flux de clics, statistiques de jeu)</li>
    <li>Données de localisation</li>
</ul>

<p>Nous utilisons des cookies, des balises web et des technologies de suivi similaires pour collecter certaines de ces informations.</p>

<h2>Comment nous utilisons vos informations</h2>

<p>Nous pouvons utiliser vos informations personnelles pour :</p>

<ul>
    <li>Fournir et améliorer nos Services</li>
    <li>Traiter les paiements et exécuter les commandes</li>
    <li>Communiquer avec vous au sujet de nos Services</li>
    <li>Personnaliser votre expérience</li>
    <li>Analyser l'utilisation de nos Services</li>
    <li>Protéger la sécurité et l'intégrité de nos Services</li>
    <li>Respecter les obligations légales</li>
</ul>

<h2>Partage de vos informations</h2>

<p>Nous pouvons partager vos informations personnelles avec :</p>

<ul>
    <li>Des prestataires de services qui effectuent des services pour notre compte</li>
    <li>Des sociétés affiliées au sein de notre groupe d'entreprises</li>
    <li>Des tiers à des fins de marketing (avec votre consentement)</li>
    <li>D'autres utilisateurs (par ex., dans les jeux multijoueurs ou les chats)</li>
    <li>Les forces de l'ordre ou d'autres parties lorsque la loi l'exige</li>
</ul>

<h2>Vos choix</h2>

<p>Vous pouvez contrôler vos informations en :</p>

<ul>
    <li>Mettant à jour les paramètres de votre compte</li>
    <li>Vous désabonnant des communications marketing</li>
    <li>Désactivant les cookies dans les paramètres de votre navigateur</li>
    <li>Demandant l'accès, la correction ou la suppression de vos données</li>
</ul>

<h2>Sécurité</h2>

<p>Nous mettons en œuvre des mesures de sécurité raisonnables pour protéger vos informations.
Cependant, aucune méthode de transmission ou de stockage n'est sûre à 100%.</p>

<h2>Confidentialité des enfants</h2>

<p>Nos Services ne sont pas destinés aux enfants de moins de 13 ans.
Nous ne collectons pas sciemment d'informations personnelles auprès d'enfants de moins de 13 ans.</p>

<h2>Modifications de cette politique</h2>

<p>Nous pouvons mettre à jour cette Politique de Confidentialité de temps à autre.
Nous vous informerons de tout changement important en publiant la nouvelle politique sur cette page.</p>

<h2>Nous contacter</h2>

<p>Si vous avez des questions concernant cette Politique de Confidentialité, veuillez nous contacter à :</p>

<p>Email : <a>info@discapegame.com</a></p>

<p>Adresse : 1833 rue Sherbrooke Est, H2K1B4, Montréal, QC, Canada</p>
`