// MENUS
/*
    {
      id: 0,
      order:1,
      type:'LINK' || 'DIVIDER'
      // name:'Tableau de bord',
      refType:'FOLDER' || 'LINK' || 'ENIGMA'... (type of the target)
      refId: null,
      status:'DEFAULT' || 'DEACTIVATED'
    },
*/
export default {
  default: [
    {
      id: 0,
      order: 1,
      type: 'LINK',
      // name:'Tableau de bord',
      refType: 'NAVIGATION',
      refId: '/',
      status: 'DEFAULT',
      location: 'leftOnly'
    },
    {
      id: 1,
      order: 1,
      type: 'LINK',
      refType: 'NAVIGATION',
      refId: '/browse',
      // name:'BOOK EXPERIENCE',
    },
    {
      id: 2,
      order: 2,
      type: 'LINK',
      // name:'Virtual Experience',
      refType: 'NAVIGATION',
      refId: '/browse',
      status: 'DEFAULT',
      parent: 1,
    },
    {
      id: 6,
      order: 6,
      type: 'LINK',
      // name:'FAQ',
      refType: 'NAVIGATION',
      refId: '/faq',
      status: 'DEFAULT',
      parent: 1,
    },
    {
      id: 10,
      order: 10,
      type: 'LINK',
      refType: 'NAVIGATION',
      refId: '/teambuilding',
      // name:'Teambuilding'
    },
    {
      id: 11,
      order: 1,
      type: 'LINK',
      // name:'Attend a demo',
      refType: 'NAVIGATION',
      refId: '/demo',
      status: 'DEFAULT',
      parent: 10,
    },
    {
      id: 12,
      order: 2,
      type: 'LINK',
      // name:'A propos de nous',
      refType: 'NAVIGATION',
      refId: '/about',
      status: 'DEFAULT',
      parent: 10,
    },
    {
      id: 14,
      order: 2,
      type: 'LINK',
      // name:'Fonctionnalités et avantages',
      refType: 'NAVIGATION',
      refId: '/features',
      status: 'DEFAULT',
      parent: 10,
    },
    // {
    //   id: 16,
    //   order: 6,
    //   type: 'LINK',
    //   // name:'Pricing',
    //   refType: 'LINK',
    //   refId: '/pricing',
    //   status: 'DEFAULT',
    //   parent: 10,
    // },
    {
      id: 20,
      order: 10,
      type: 'LINK',
      refType: 'NAVIGATION',
      refId: '/contact',
      // name:'Get in touch',
    },
    {
      id: 22,
      order: 2,
      type: 'LINK',
      // name:'Schedule Call',
      refType: 'NAVIGATION',
      refId: 'https://calendly.com/discapegame-info/30min',
      status: 'DEFAULT',
      options: { newTab: true },
      parent: 20,
    },
    {
      id: 24,
      order: 4,
      type: 'LINK',
      // name:'Contact Us',
      refType: 'NAVIGATION',
      refId: '/contact',
      status: 'DEFAULT',
      parent: 20,
    },
  ],
  coldGame: [
    {
      id: 105,
      order: 0,
      type: 'DIVIDER',
      // name:'Apps',
    },
    {
      id: 100,
      order: 1,
      type: 'LINK',
      // name:'Tableau de bord',
      refType: 'NAVIGATION_IN_GAME',
      refId: null,
      status: 'DEFAULT'
    },
    {
      id: 102,
      order: 12,
      type: 'LINK',
      // name:'Contrer le JMX',
      refType: 'ENIGMA',
      refId: 1,
      status: 'INVISIBLE'
    },
    {
      id: 103,
      order: 2,
      type: 'LINK',
      // name:'Fichiers M27',
      refType: 'FOLDER',
      refId: 1,
      status: 'DEFAULT'
    },
    {
      id: 104,
      order: 10,
      // name:'Objectifs',
      type: 'DIVIDER',
    },
    {
      id: 106,
      order: 3,
      type: 'LINK',
      // name:'SatCell',
      refType: 'APP',
      refId: 'SATCELL',
      status: 'DEFAULT'
    },
    {
      id: 107,
      order: 4,
      type: 'LINK',
      // name:'CyberCounter',
      refType: 'APP',
      refId: 'CYBERCOUNTER',
      status: 'PROTECTED'
    },
    {
      id: 101,
      order: 11,
      type: 'LINK',
      // name:'Secourir Malory',
      refType: 'ENIGMA',
      refId: 0,
      status: 'UNSOLVED'
    },
    {
      id: 110,
      order: 13,
      type: 'LINK',
      // name:`Trouver le contact`,
      refType: 'ENIGMA',
      refId: 4,
      status: 'INVISIBLE'
    },
    {
      id: 111,
      order: 14,
      type: 'LINK',
      // name:`Retrouver le vol en danger`,
      refType: 'ENIGMA',
      refId: 5,
      status: 'INVISIBLE'
    },
    {
      id: 112,
      order: 15,
      type: 'LINK',
      // name:`Retrouver M`,
      refType: 'ENIGMA',
      refId: 6,
      status: 'INVISIBLE'
    },
    {
      id: 113,
      order: 16,
      type: 'LINK',
      // name:`Qui est M?`,
      refType: 'ENIGMA',
      refId: 7,
      status: 'INVISIBLE'
    },
    {
      id: 114,
      order: 17,
      type: 'LINK',
      // name:`Quitter`,
      refType: 'NAVIGATION',
      refId: '/',
      status: 'INVISIBLE'
    },
    {
      id: 115,
      order: 14,
      type: 'LINK',
      // name:`Retrouver la compagnie aérienne`,
      refType: 'ENIGMA',
      refId: 23,
      status: 'INVISIBLE'
    },
  ],
  treason: [
    {
      id: 200,
      order: 0,
      type: 'DIVIDER',
      // name:'Apps',
    },
    {
      id: 201,
      order: 1,
      type: 'LINK',
      // name:'Tableau de bord',
      refType: 'NAVIGATION_IN_GAME',
      refId: null,
      status: 'DEFAULT'
    },
    {
      id: 211,
      order: 2,
      type: 'LINK',
      // name:'MissilGouv',
      refType: 'NAVIGATION_IN_GAME',
      refId: 'app/MISSIL_GOUV',
      status: 'INVISIBLE'
    },
    {
      id: 210,
      order: 3,
      type: 'LINK',
      // name:'Indices',
      refType: 'NAVIGATION_IN_GAME',
      refId: 'hints',
      status: 'DEFAULT'
    },
    {
      id: 205,
      order: 5,
      type: 'DIVIDER',
      // name:'Objectifs',
    },
    {
      id: 202,
      order: 6,
      type: 'LINK',
      // name:`Infiltrer l'ordinateur`,
      refType: 'ENIGMA',
      refId: 15,
      status: 'DEFAULT'
    },
    {
      id: 203,
      order: 6,
      type: 'LINK',
      // name:`Pirater MissilGouv`,
      refType: 'ENIGMA',
      refId: 16,
      status: 'DEACTIVATED'
    },
    {
      id: 204,
      order: 6,
      type: 'LINK',
      // name:`Empêcher le décollage`,
      refType: 'ENIGMA',
      refId: 17,
      status: 'DEACTIVATED'
    },
    {
      id: 206,
      order: 6,
      type: 'LINK',
      // name:`Neutraliser le missile`,
      refType: 'ENIGMA',
      refId: 17,
      status: 'INVISIBLE'
    },
  ]
}