import React, { useRef, useState } from 'react';
import { Frame } from 'arwes';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Heading } from 'arwes'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { useIntl, FormattedMessage } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import PeopleIcon from '@material-ui/icons/People';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { Button, TextField } from '@material-ui/core';
import { Loading } from 'arwes';
import { book } from '../actions/demoActions'
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone'
import { demoTypes } from 'discapecommon/consts/demoTypes';
import navigationManager from '../navigationManager'

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: 1200,
    '@media (min-width: 800px) and  (max-width: 1200px)': {
      maxWidth: 'calc(100vw - 32px)',
    },
    '& .MuiButtonBase-root': {
      borderBottom: '1px solid rgb(11, 27, 28)',
    },
    '& .MuiAccordionDetails-root': {
      display: 'block',
      textAlign: 'left',
    }
  },
  content: {
    padding: 16,
  },
  heading: {
    marginTop: 12,
  },
  description: {
    textAlign: 'left',
  },
  dateAndChipsContainer: {
    '@media (max-width: 600px)': {
      display: 'flex'
    }
  },
  date: {
    border: '1px solid #e2e6ec',
    borderRadius: '6px',
    width: '64px',
    marginRight: '22px',
    height: 'fit-content',
    '& .date-top': {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: '600',
      lineHeight: '20px',
      fontSize: '11px',
      borderBottom: '1px solid #e2e6ec',
      color: 'rgb(23, 136, 251)',
    },
    '& .date-bottom': {
      textalign: 'center',
      lineHeight: '32px',
      fontWeight: '400',
      fontSize: '20px',
      paddingBottom: '3px',
    }
  },
  showTimeTitleBar: {
    margin: 'auto 0px',
    display: 'flex',
    gap: '30px',
    '& .showTimeTitle': {
      margin: '4px'
    },
    '& .MuiChip-root': {
      pointerEvents: 'none',
    }
  },
  showTimeSubInfo: {
    fontSize: '0.65em',
    textAlign: 'left',
    marginBottom: '18px',
    '& .MuiGrid-item': {
      padding: '4px 12px'
    }
  },
  subInfoIcon: {
    float: 'left',
    margin: '1px 6px auto auto',
  },
  detailsTitle: {
    fontSize: '1.2em',
  },
  detailsContent: {
    fontSize: '1em',
  },
  bookInstruction: {
    fontSize: '1.2em',
    textAlign: 'center',
    marginTop: '20px',
    '& button': {
      margin: '12px'
    }
  },
  separator: {
    width: '100%',
    borderTop: '2px solid #0C474A',
    margin: '14px auto',
  },
  textField: {
    flex: 'calc(50% - 8px)',
    '& .MuiTextField-root': {
      width: '100%'
    }
  },
  info: {
    marginTop: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px'
  },
  pending: {
    height: '140px',
    position: 'relative'
  },
  success: {
    height: '140px'
  },

  error: {
    height: '140px'
  },
  chips: {
    '& .MuiChip-root': {
      margin: '4px'
    }
  }
});

function getDateTimeString(moment) {
  const dateString = moment.format("dddd LL").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  return `${dateString}, ${moment.format("h:mm a")} - ${moment.clone().add(45, 'minutes').format("h:mm a")}`;
}

function ShowTimeItem({ date }) {
  const classes = useStyles();
  const [detailsMode, setDetailsMode] = useState('normal');
  const intl = useIntl();
  const firstName = useRef()
  const lastName = useRef()
  const email = useRef()
  const phone = useRef()
  const dispatch = useDispatch();
  const bookingState = useSelector((state) => state.modules.demoBooking.bookingState)
  const errorMessageKey = useSelector((state) => state.modules.demoBooking.errorMessageKey)
  const language = useSelector((state) => state.intl.locale)
  const dateTimeString = getDateTimeString(date.moment)
  const { width } = useWindowDimensions()


  const handleBookClick = () => {
    setDetailsMode('booking')
  }

  const handleCancelClick = () => {
    setDetailsMode('normal')
  }

  const handleTryAgainClick = () => {
    setDetailsMode('booking')
    dispatch({ type: demoTypes.DEMO_BOOK_NORMAL })
  }

  const handleConfirmClick = () => {
    book({
      firstName: firstName.current.value,
      lastName: firstName.current.value,
      email: email.current.value,
      phone: phone.current.value,
      language: language,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezoneOffset: new Date().getTimezoneOffset() / -60,
      utc: date.moment.clone().utc().valueOf(),
      utcString: getDateTimeString(date.moment.clone().utc()),
      montrealDateTimeString: getDateTimeString(date.moment.clone().tz('America/Toronto')),
      localDateTimeString: dateTimeString
    })(dispatch)
  }

  const getDetails = () => {
    if (detailsMode == 'booking') {
      if (bookingState == 'normal')
        return <>
          <div className={classes.info}>
            <div className={classes.textField}><TextField inputRef={firstName} label={"* " + intl.formatMessage({ id: 'demo.info.firstName' })} /></div>
            <div className={classes.textField}><TextField inputRef={lastName} label={"* " + intl.formatMessage({ id: 'demo.info.lastName' })} /></div>
            <div className={classes.textField}><TextField inputRef={email} label={"* " + intl.formatMessage({ id: 'demo.info.email' })} /></div>
            <div className={classes.textField}><TextField inputRef={phone} label={"* " + intl.formatMessage({ id: 'demo.info.phone' })} /></div>
          </div>
          <div className={classes.bookInstruction}>
            <Button variant="contained" onClick={() => handleCancelClick()}>
              <FormattedMessage id="demo.cancel" />
            </Button>
            <Button variant="contained" onClick={() => handleConfirmClick()}>
              <FormattedMessage id="demo.confirm" />
            </Button>
          </div>
        </>
      else if (bookingState == 'pending') {
        return <div className={classes.pending}>
          <Loading animate full />
        </div>
      }
      else if (bookingState == 'success') {

        return <div className={classes.success}>
          <FormattedMessage id="demo.bookingConfirmed" />
          <div style={{ height: '16px' }}></div>
          <Button variant="contained" onClick={() => navigationManager.push('/')}><FormattedMessage id="demo.goHome" /></Button>
        </div>
      }
      else if (bookingState == 'error') {
        return <div className={classes.error}>
          {errorMessageKey ? intl.formatMessage({ id: errorMessageKey }) : 'Error'}
          <div className={classes.bookInstruction}>
            <Button variant="contained" onClick={() => handleTryAgainClick()}>
              <FormattedMessage id="demo.tryAgain" values={{ br: <br /> }} />
            </Button>
          </div>
        </div>
      }

    }
    return (
      <>
        <h2 className={classes.detailsTitle}><FormattedMessage id="demo.defaultDetailsTitle" values={{ br: <br /> }} /></h2>
        <div className={classes.detailsContent}><FormattedMessage id="demo.defaultDetailsContent" values={{ br: <br /> }} /></div>
        <div className={classes.separator}></div>
        <div className={classes.bookInstruction}>
          <Button variant="contained" onClick={() => handleBookClick()}>
            <FormattedMessage id="demo.book" values={{ br: <br /> }} />
          </Button>
        </div>
      </>
    )
  }

  const getChips = () => {
    return <div className={classes.chips}>
      <Chip label={intl.formatMessage({ id: 'demo.open' })} color="primary" />
      <Chip label={intl.formatMessage({ id: 'demo.free' })} color="primary" variant='outlined' />
    </div>
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={1}>
            <div className={classes.dateAndChipsContainer}>
              <div className={classes.date}>
                <div className='date-top'>{date.moment.format('MMM')}</div>
                <div className='date-bottom'>{date.moment.format('D')}</div>
              </div>
              {width < 600 && getChips()}
            </div>
          </Grid>
          <Grid item xs={12} sm={11}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className={classes.showTimeTitleBar}>
                    <div className='showTimeTitle'>{date.title}</div>
                    {width > 600 && getChips()}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.showTimeSubInfo}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <div className={classes.subInfoIcon}><PeopleIcon style={{ fontSize: '1.3em' }} /></div>
                        <div><FormattedMessage id='demo.capacity' />: 2 / 6</div>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <div className={classes.subInfoIcon}><LocationOnIcon style={{ fontSize: '1.3em' }} /></div>
                        <div><FormattedMessage id='demo.location' />: <FormattedMessage id='demo.location.value' /></div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.subInfoIcon}><ScheduleIcon style={{ fontSize: '1.3em' }} /></div>
                        <div>
                          {dateTimeString}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {getDetails()}
      </AccordionDetails>
    </Accordion>
  )
}

Date.prototype.getUTCTime = function () {
  return this.getTime() - (this.getTimezoneOffset() * 60000);
};

function getDates() {
  /*
    On veut afficher une séance à  15h (UTC) = 11h (mtl) = 17h (france)
    On part du temps UTC de mercredi prochain, puis on applique l'offset
  */
  let nextWednesday = moment().locale('fr')
  if (moment().weekday() < 4)
    nextWednesday = nextWednesday.add(1, 'week').day(4);
  else
    nextWednesday = nextWednesday.add(2, 'week').day(4);
  nextWednesday = nextWednesday.utc().hours(15).local().minutes(0).seconds(0)

  let dates = []
  dates.push(
    {
      title: 'Mission SIG',
      moment: nextWednesday,
    },
    {
      title: 'Mission SIG',
      moment: nextWednesday.clone().add(1, 'week').day(3),
    },
    {
      title: 'Mission SIG',
      moment: nextWednesday.clone().add(2, 'week').day(2),
    }
  )
  return dates;
}

function DemoPage() {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <div className={classes.root}>
      <Frame
        animate
        show={true}
        level={3}
        hover={false}
        corners={width < 800 ? 0 : 4}
        layer='primary'
      >
        <div className={classes.content}>
          <Heading node='h2' className={classes.heading}><FormattedMessage id="demo.title" /></Heading>
          <div className={classes.description}>
            <FormattedMessage id='demo.description' values={{ br: <br /> }} />
          </div>
          <div style={{ height: 24 }} />
          {getDates().map((date, index) => <ShowTimeItem key={index} date={date} />)}
        </div>
      </Frame>
    </div>
  );
}

export default DemoPage
