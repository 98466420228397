// import {log, logError} from '../logManager'
import eventManager from '../eventManager'

class PlayerInfos {
  constructor(store) {
    this.playerNumber = 0;
    this.store = store;
    this.mode = 'solo';

    this.dispatch = this.dispatch.bind(this);
    eventManager.on('action', this.dispatch)
  }

  dispatch(action) {
    switch(action.type) {
      case 'PUSH_SESSION_STATE':
        this.setPlayerNumber(action.playerNumber)
        this.setMode(action.state.mode)
    }
  }

  setPlayerNumber(playerNumber) {
    if(playerNumber) {
      this.playerNumber = playerNumber;
    }
    else
      console.error('Empty playerNumber')
  }

  getPlayerNumber() {
    return this.playerNumber;
  }

  setMode(mode) {
    if(mode) {
      this.mode = mode;
    }
    else
      console.error('Empty mode')
  }

  getMode() {
    return this.mode;
  }

  isSolo() {
    return this.mode == 'solo';
  }
}

export default new PlayerInfos();