// import config from 'config';
// import { authHeader } from '../_helpers';
import { userTypes } from 'discapecommon/consts/userTypes';
import sendClientRequest from './_sendClientRequest'
import eventManager from '../eventManager'

export function login({email, password}) {
  const request = {
    type: userTypes.UP_USERS_LOGIN,
    email,
    password,
  };

  return sendClientRequest(request)
    .then(response => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        storeToken(response.user.token);
        return response.user;
    });
}

export function getStoredToken() {
  return localStorage.getItem('token');
}

export function storeToken(token) {
  const previousToken = getStoredToken();
  var result = false;
  console.log('storeToken: ',token, ' previousToken: ', previousToken)
  if(previousToken != token) {
    result = localStorage.setItem('token', token)
    eventManager.emit('updateUserData')
  }
  return result;
}

export function verifyToken() {
  return new Promise((resolve, reject) => {
    const storedToken = getStoredToken();

    if(!storedToken || !storedToken.token) {
      return reject(new Error('No Token found'));
    }

    // console.log('stored ',storedToken)
    const request = {
      type: userTypes.UP_USERS_VERIFY_TOKEN,
      token: storedToken?.token,
    };

    sendClientRequest(request).then(user => resolve(user), err => reject(err));
  })
}

export function logOut() {
  // remove user from local storage to log user out
  localStorage.removeItem('token');

  const request = {
    type: userTypes.UP_USERS_LOG_OUT,
  };

  return sendClientRequest(request)
}

export function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

export function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

export function register(user) {
  const request = {
    type: userTypes.UP_USERS_REGISTER,
    user,
    token: getStoredToken(),
  };

  return sendClientRequest(request)
    .then(response => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        storeToken(response.user?.token)
        return response.user;
    });
}

export function update(user) {
  const request = {
    type: userTypes.UP_USERS_UPDATE,
    user,
    token: getStoredToken(),
  };

  return sendClientRequest(request)
    // .then(() => localStorage.setItem('user', JSON.stringify({...storedToken, ...user})))
}

export function updatePassword(passwords) {
  const request = {
    type: userTypes.UP_USERS_UPDATE_PASSWORD,
    ...passwords,
    token: getStoredToken(),
  };

  return sendClientRequest(request)
}

export function retrievePassword(email) {
  const request = {
    type: userTypes.UP_USERS_RETRIEVE_PASSWORD,
    email,
  };

  return sendClientRequest(request)
}

export function verifyResetToken(token) {
  const request = {
    type: userTypes.UP_USERS_VERIFY_RESET_TOKEN,
    token,
  };

  return sendClientRequest(request).then(() => {
    localStorage.setItem('resetToken', JSON.stringify(token))
  })
}

export function setNewPassword(password) {
  const storedResetToken = JSON.parse(localStorage.getItem('resetToken'));

  const request = {
    type: userTypes.UP_USERS_SET_NEW_PASSWORD,
    password,
    token: storedResetToken,
  };

  return sendClientRequest(request)
}

// prefixed function name with underscore because delete is a reserved word in javascript
export function _delete(id) {
  const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}




