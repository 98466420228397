/*
 * MediaPlayer - Client Only
 */
import {openAdditionalPanel, setCurrentScene} from 'discapecommon/actions/index'
import {channels} from 'discapecommon/consts/channels'
import {log} from '../utils'

const defaultState = {
  musicVolume: 20,
}

const settings = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_CHANNEL_VOLUME':
      if(action.channelId == channels.MUSIC) {
        var volume = parseInt(action.volume)
        return {...state, musicVolume:volume};
      }
      else
        return state;
    default:
      return state
  }
}

export default settings
