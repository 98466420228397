/*
 * demoBooking
 */

import { contactFormTypes } from 'discapecommon/consts/contactFormTypes';

/*
  Presentation de la endingPanel
*/

const defaultState = {
    formState: 'normal'
}

const contactForm = (state = defaultState, action) => {
    switch (action.type) {
        case contactFormTypes.CONTACT_FORM_SET_NORMAL:
            return { ...state, formState: 'normal' };
        case contactFormTypes.CONTACT_FORM_SET_PENDING:
            return { ...state, formState: 'pending' };
        case contactFormTypes.CONTACT_FORM_SET_SUCCESS:
            return { ...state, formState: 'success' };
        case contactFormTypes.CONTACT_FORM_SET_ERROR:
            return { ...state, formState: 'error', errorMessageKey: action.errorMessageKey };
        default:
            return state
    }
}

export default contactForm
