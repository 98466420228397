import { setContentPanel, triggerCustomEvent } from 'discapecommon/actions/index'
import {status} from 'discapecommon/apps/cyberCounter/status'


const defaultCyberCounter = {
  status: status.READY,
  sequenceIndex:0,
}

const cyberCounter = (state = defaultCyberCounter, action) => {
  switch(action.type) {
    case 'PUSH_CYBER_COUNTER_START':
      return {...state, 
        status: status.PLAYING, 
        duration: action.duration, 
        sequence: action.sequence, 
        userActions: action.userActions,
        sequenceIndex: 0,
      }
    case 'PUSH_SESSION_STATE':
      let newStatus = action.state?.appsState?.cyberCounter?.status;
      if(typeof newStatus !== 'undefined') {
        if(newStatus == status.PLAYING) {
          return {...state, status: status.ALREADY_PLAYING}
        }
        return {...state, status: newStatus};
      }
      else
        return state;
    case 'PUSH_CYBER_COUNTER_NEED_MORE_PLAYERS':
      return {...state, sequenceIndex: 0, status: status.NEED_MORE_PLAYERS}
    case 'PUSH_CYBER_COUNTER_SEQUENCE_INDEX':
      return {...state, sequenceIndex: action.sequenceIndex}
    case 'PUSH_CYBER_COUNTER_SUCCESS':
      action.asyncDispatch(triggerCustomEvent('CYBER_COUNTER_SUCCESS'))
      return {...state, status: status.SUCCESS}
    case 'PUSH_CYBER_COUNTER_WRONG_SYMBOL':
      return {...state, status: status.WRONG_SYMBOL}
    case 'PUSH_CYBER_COUNTER_STOP':
      return {...state, status: status.TIMEOUT}
    case 'CYBER_COUNTER_LEAVE':
      console.log('leaving')
      action.asyncDispatch(setContentPanel(null))
      return state;
    default:
      return state;
  }
}

export default cyberCounter;