import { cltFileTreeLoadTree } from 'discapecommon/actions/appActions'
import {
  setCurrentScene, closeAdditionalPanel, setLinkStatus, goHome,
  triggerCustomEvent, upTriggerCustomEvent,
} from 'discapecommon/actions/index'
import { loadStage } from 'discapecommon/actions/gameFlowActions'
import {
  playMedia, setMediaList, playAudio, stopAudio, stopAllAudio,
  setFilesStatus, startVideoConference, updateVideoConference
} from '/src/actions/mediaActions'
import { closeDrawer } from 'discapecommon/actions/layoutActions'
import { openChatPanel } from 'discapecommon/actions/conversationActions'
import {
  countDownCreate, countDownSetStatus, openCountDownFullscreen,
  closeCountDownFullscreen
} from 'discapecommon/actions/countDownActions'
import { openEndingPanel } from 'discapecommon/actions/layoutActions'
import { navBarSetCountDownId, navBarSetEndCondition, navBarCountDownSetStatus } from 'discapecommon/actions/navBarActions'
import { upMissilGouvSetPhase2Status, telemetrySuccess } from '../../apps/missilGouv/missilGouv.actions'
import { openEnigma, closeEnigma, forceEnigmaStatus } from 'discapecommon/actions/enigmaActions'
import { viewMission, cltMissionEnd } from '../../actions/missionActions'
import { channels } from 'discapecommon/consts/channels';
import validSound from '/assets/audios/arwes/valid.wav'
import carteTransport from '/assets/missions/treason/images/Carte transport.jpg'
import militaryBaseImage from '/assets/missions/treason/images/russian-military-base.jpg'
import audioExplosion from '/assets/missions/treason/audios/Explosion.mp3'
import audioDisturbed from '/assets/missions/treason/audios/Disturbed.mp3'
import audioTakeOff from '/assets/missions/treason/audios/TakeOff.mp3'
import Vilamo1 from '/assets/missions/treason/audios/Vilamo1.mp3'
import Vilamo1En from '/assets/missions/treason/audios/Vilamo1_En.mp3'
import Vilamo2 from '/assets/missions/treason/audios/Vilamo2.mp3'
import Vilamo2En from '/assets/missions/treason/audios/Vilamo2_En.mp3'
import Alarm2 from '/assets/missions/treason/audios/Alarm2.mp3'
import error from '/assets/audios/arwes/error.mp3';
import valid from '/assets/audios/arwes/valid.mp3';
import askValid from '/assets/audios/arwes/askValid.mp3';
import clockTicking from '/assets/missions/treason/audios/Clock Ticking.mp3';
import alerteVoix from '/assets/missions/treason/audios/Alerte--Alerte--Tentative-voixsolo.mp3';
import playerInfos from '../../core/playerInfos'
import ringtone from '/assets/audios/Ringtone.mp3';
import { isTestEnv } from '../../utils'
import navigationManager from '../../navigationManager'
import { gtagRegister } from '../../core/GTagManager';

// Test value is defined later;
var isVideoMode = true;
var mediaHost = 'https://discape-production.s3.us-west-002.backblazeb2.com/'

if (isTestEnv(['localhost']))
  mediaHost = '/S3/'

const alerteMusique = mediaHost + 'Alerte--Alerte--Tentative-musique.mp3';
const stealthMusic = mediaHost + 'Stealth-30db.mp3';
const spyMusic = mediaHost + 'SpyMusic-30db.mp3'


const V001Fr = mediaHost + 'E1_Seq1_Fr_1080p.mp4'
const V001En = mediaHost + 'E1_Seq1_En_1080p.mp4'
const V005Fr = mediaHost + 'E1_Seq2_Fr_1080p.mp4'
const V005En = mediaHost + 'E1_Seq2_En_1080p.mp4'
const V006Fr = mediaHost + 'E1_Seq3_Fr_1080p.mp4'
const V006En = mediaHost + 'E1_Seq3_En_1080p.mp4'
const V050Fr = mediaHost + 'E1_Seq4_Fr_1080p.mp4'
const V050En = mediaHost + 'E1_Seq4_En_1080p.mp4'
const V099Fr = mediaHost + 'E1_Seq5_Fr_1080p.mp4'
const V099En = mediaHost + 'E1_Seq5_En_1080p.mp4'
const V002 = mediaHost + 'E1_Seq6_Loop_1080p.mp4' // Loop

const filesTreason = [
  { src: V001Fr, localeSrc: { en: V001En, fr: V001Fr }, mediaType: 'VIDEO', id: 1, name: 'Joe' },
  { src: V002, mediaType: 'VIDEO', id: 2, name: 'Joe attend Loop' },
  { src: V005Fr, localeSrc: { en: V005En, fr: V005Fr }, mediaType: 'VIDEO', id: 5, name: 'Joe Joueur accepte' },
  { src: V006Fr, localeSrc: { en: V006En, fr: V006Fr }, mediaType: 'VIDEO', id: 6, name: 'Joe Joueur refuse' },
  { src: V050Fr, localeSrc: { en: V050En, fr: V050Fr }, mediaType: 'VIDEO', id: 50, name: 'Jo réagi au lancement du missile' },
  { src: V099Fr, localeSrc: { en: V099En, fr: V099Fr }, mediaType: 'VIDEO', id: 99, name: 'Joe Congratz' },
  { src: Vilamo1, localeSrc: { en: Vilamo1En, fr: Vilamo1 }, mediaType: 'AUDIO', id: 1010, localeKey: 'fileTree.1005' },
  { src: Vilamo2, localeSrc: { en: Vilamo2En, fr: Vilamo2 }, mediaType: 'AUDIO', id: 1011, localeKey: 'fileTree.1006' },
  { src: spyMusic, mediaType: 'AUDIO', id: 4, name: 'spyMusic' },
  { src: validSound, mediaType: 'AUDIO', id: 1001, name: 'validSound' },
  { src: audioExplosion, mediaType: 'AUDIO', id: 1002, name: 'audioExplosion' },
  { src: audioDisturbed, mediaType: 'AUDIO', id: 1003, name: 'audioDisturbed' },
  { src: audioTakeOff, mediaType: 'AUDIO', id: 1004, name: 'audioTakeOff' },
  { src: Alarm2, mediaType: 'AUDIO', id: 1005, name: 'Alarm2' },
  { src: error, mediaType: 'AUDIO', id: 1006, name: 'Error Bip' },
  { src: valid, mediaType: 'AUDIO', id: 1007, name: 'Valid Bip' },
  { src: askValid, mediaType: 'AUDIO', id: 1008, name: 'askValid' },
  { src: clockTicking, mediaType: 'AUDIO', id: 1009, name: 'clockTicking' },
  { src: carteTransport, mediaType: 'IMAGE', id: 7, localeKey: 'fileTree.1004' },
  { src: militaryBaseImage, mediaType: 'IMAGE', id: 8, localeKey: 'fileTree.1020' },
  { src: alerteVoix, mediaType: 'AUDIO', id: 1012, name: 'Alerte Voix' },
  { src: alerteMusique, mediaType: 'AUDIO', id: 1013, name: 'Alerte Musique' },
  { src: stealthMusic, mediaType: 'AUDIO', id: 1014, name: 'Alerte Musique' },
  { src: ringtone, mediaType: 'AUDIO', id: 2000, name: 'Ringtone' },
]

function assignPlayerFile() {
  return (dispatch, getState) => {
    if (playerInfos.mode != 'solo') {
      if (playerInfos.getPlayerNumber() % 2 == 1) {
        dispatch(setFilesStatus(1005, 'DEFAULT'))
        dispatch(setFilesStatus(1006, 'PROTECTED'))
      }
      else {
        dispatch(setFilesStatus(1005, 'PROTECTED'))
        dispatch(setFilesStatus(1006, 'DEFAULT'))
      }
    }
    else {
      dispatch(setFilesStatus(1005, 'DEFAULT'))
      dispatch(setFilesStatus(1006, 'DEFAULT'))
    }
  }
}

const COUNT_DOWN_IDS = {
  timeBeforeTakeOff: 0,
  timeBeforeTakeOffMinus15: 2,
  timeBeforeExplosion: 1,
  fastCountDown: 5
}

const load1StartGame = (state, action) => {
  console.log('--- TREASON: startGame ---')
  action.asyncDispatch(setMediaList(filesTreason));
  action.asyncDispatch(setCurrentScene('MISSION'));

  gtagRegister("event", "level_start", {
    level_name: "The journey begins..."
  });
  // DEV
  // if (isTestEnv(['localhost'])) {
  //   return load20MissileLaunched(state, action)
  // }
  // END DEV


  const countDownTakeOffDuration = 600; // Redéfini par le serveur
  action.asyncDispatch(countDownCreate(COUNT_DOWN_IDS.timeBeforeTakeOff, countDownTakeOffDuration, false))
  action.asyncDispatch(countDownCreate(COUNT_DOWN_IDS.timeBeforeTakeOffMinus15, countDownTakeOffDuration - 15, false))
  action.asyncDispatch(countDownCreate(COUNT_DOWN_IDS.timeBeforeExplosion, 480, false))  //480
  action.asyncDispatch(countDownCreate(COUNT_DOWN_IDS.fastCountDown, 100, false))  //480   // Count down super fast (set to remaining time of )

  // TESTS
  // showVideos = false;
  // action.asyncDispatch(cltFileTreeLoadTree(2));
  // action.asyncDispatch(assignPlayerFile());
  // action.asyncDispatch(setFilesStatus(1001, 'UNLOCKED')); // Unlock ordinateur de vilamo
  // action.asyncDispatch(countDownSetStatus(1, 'PLAY'));
  // action.asyncDispatch(countDownSetStatus(0, 'PLAY'));
  // TEST TELEMETRY
  // action.asyncDispatch(missilGouvSetStatus('PHASE2_NORMAL'));

  // Hoping the user will do an action before
  setTimeout(() => {
    action.asyncDispatch(navBarSetCountDownId(COUNT_DOWN_IDS.timeBeforeTakeOff));
    action.asyncDispatch(navBarSetEndCondition('activity.treason.endCondition1'))
    if (isVideoMode) {
      action.asyncDispatch(startVideoConference(1, 'PLAY', { keepUp: true, isSkippable: false, showPickUp: true }));
    }
  }, 100)

  return { ...state, status: 'PLAYING', stage: 1 }
}

const acceptMission = (state, action) => {
  if (isVideoMode) {
    action.asyncDispatch(updateVideoConference(5, 'PLAY', { keepUp: false, showPickUp: false }));
  }
  action.asyncDispatch(cltFileTreeLoadTree(2));
  action.asyncDispatch(assignPlayerFile());
  action.asyncDispatch(upTriggerCustomEvent('TREASON_MISSION_ACCEPTED'));
  return { ...state, canRelyOnAgentAsked: true };
}

const load90Success = (state, action) => {
  if (isVideoMode) {
    action.asyncDispatch(startVideoConference(99, 'PLAY', { isSkippable: false }));
  }

  action.asyncDispatch(cltMissionEnd());
  action.asyncDispatch(navBarCountDownSetStatus('HIDDEN'));
  action.asyncDispatch(openEndingPanel(6));
  return { ...state, stage: 90 };
}

const load100Fail = (state, action) => {
  action.asyncDispatch(cltMissionEnd())
  action.asyncDispatch(navBarCountDownSetStatus('HIDDEN'))
  action.asyncDispatch(openEndingPanel(5));
  action.asyncDispatch(upMissilGouvSetPhase2Status('FAILED'));
  return { ...state, stage: 100 };
}

const load20MissileLaunched = (state, action) => {
  console.log('--- TREASON: load20MissileLaunched --- After missil launched, Missil Gouv part 1 done, telemetry required')

  // Lancement du missile
  action.asyncDispatch(startVideoConference(50, 'PLAY', { isSkippable: false }));
  action.asyncDispatch(playAudio(1004, 100, channels.SFX));
  action.asyncDispatch(countDownSetStatus(COUNT_DOWN_IDS.timeBeforeExplosion, 'PLAY'));
  action.asyncDispatch(navBarSetEndCondition('activity.treason.endCondition2'))
  action.asyncDispatch(navBarSetCountDownId(COUNT_DOWN_IDS.timeBeforeExplosion));
  action.asyncDispatch(setLinkStatus([MENU.enigmaInfiltrateComputer, MENU.enigmaHackMissileGouv], 'SOLVED'))
  action.asyncDispatch(setLinkStatus(MENU.enigmaPreventLaunch, 'INVISIBLE'));
  action.asyncDispatch(setLinkStatus(MENU.enigmaNeutralizeMissile, 'DEFAULT'))
  action.asyncDispatch(cltFileTreeLoadTree(2));
  action.asyncDispatch(assignPlayerFile());
  action.asyncDispatch(forceEnigmaStatus([ENIGMAS.lockScreenPattern, ENIGMAS.infiltrateFolder, ENIGMAS.hackMissileGouv], 'VALID'));
  action.asyncDispatch(setLinkStatus([MENU.missilGouv], 'DEFAULT'))
  action.asyncDispatch(closeEnigma(9))
  action.asyncDispatch(triggerCustomEvent('TREASON_STAGE_20'));
  action.asyncDispatch(playAudio(4, 100, channels.MUSIC));
  action.asyncDispatch(setFilesStatus(FILES.vilamoFolder), 'UNLOCKED'); // Vilamo folder
  setTimeout(() => action.asyncDispatch(stopAudio(1005, channels.SFX)), 2000); // Stop alarm
  return { ...state, isVilamoAccessUnlocked: true, stage: 20 };
}

const load15TrapActivated = (state, action) => {
  console.log('--- TREASON: load15TrapActivated ---')
  action.asyncDispatch(openEnigma(9)) // Decompte rapide
  action.asyncDispatch(playAudio(1005, 90, channels.SFX));
  action.asyncDispatch(countDownSetStatus(COUNT_DOWN_IDS.timeBeforeTakeOff, 'STOP'))
  action.asyncDispatch(countDownSetStatus(COUNT_DOWN_IDS.timeBeforeTakeOffMinus15, 'STOP'));
  action.asyncDispatch(countDownSetStatus(COUNT_DOWN_IDS.fastCountDown, 'SUPER_FAST'));  // 15 sec avant takeoff
  action.asyncDispatch(navBarSetCountDownId(COUNT_DOWN_IDS.fastCountDown));
  action.asyncDispatch(setLinkStatus([MENU.enigmaHackMissileGouv, MENU.missilGouv], 'DEFAULT'))
  action.asyncDispatch(setFilesStatus(FILES.vilamoFolder), 'UNLOCKED'); // Vilamo folder
  return { ...state, isVilamoAccessUnlocked: true, stage: 15 };
}

const load10MissionFailedRocketExploded = (state, action) => {
  action.asyncDispatch(cltMissionEnd())
  action.asyncDispatch(navBarCountDownSetStatus('HIDDEN'))
  action.asyncDispatch(openEndingPanel(5));
  return { ...state, stage: 10 };
}

const load5Accepted = (state, action) => {
  console.log('--- TREASON: load5Accepted')
  action.asyncDispatch(navBarSetCountDownId(0));
  action.asyncDispatch(navBarSetEndCondition('activity.treason.endCondition1'))
  action.asyncDispatch(playAudio(4, 100, channels.MUSIC));
  action.asyncDispatch(cltFileTreeLoadTree(2));
  action.asyncDispatch(assignPlayerFile());
  // TEST
  if (isTestEnv()) {
    // action.asyncDispatch(startVideoConference(1, 'PLAY', { keepUp: true, isSkippable: false }));
  }
  return { ...state, isVilamoAccessUnlocked: true, stage: 5 };
}

const MENU = {
  enigmaInfiltrateComputer: 202,
  enigmaHackMissileGouv: 203,
  enigmaPreventLaunch: 204,
  enigmaNeutralizeMissile: 206,
  missilGouv: 211
}

const FILES = {
  vilamoFolder: 1001
}

const ENIGMAS = {
  lockScreenPattern: 8,
  infiltrateFolder: 15,
  hackMissileGouv: 16
}

const unlockPattern = (state, action) => {
  action.asyncDispatch(setLinkStatus(MENU.enigmaInfiltrateComputer, 'SOLVED'))
  action.asyncDispatch(setLinkStatus([MENU.enigmaHackMissileGouv, MENU.missilGouv], 'DEFAULT'))
  action.asyncDispatch(forceEnigmaStatus(15, 'VALID'));
  action.asyncDispatch(setFilesStatus(FILES.vilamoFolder), 'UNLOCKED'); // Vilamo folder
  navigationManager.viewInGameTreeItem(1001);
  return state;
}

const applyLoadStage = (state, action, stage) => {
  console.log('--- TREASON: applyLoadStage', stage)
  if (state.stage >= stage) {
    return state;
  }
  action.asyncDispatch(setMediaList(filesTreason));
  switch (stage) {
    case 90:  // Success
      return load90Success(state, action);
    case 100: // Fail
      return load100Fail(state, action);
    case 40: // Surcharge de la télémétrie => Success
      action.asyncDispatch(telemetrySuccess())
      return load20MissileLaunched(state, action);
    case 20:  // Sequence Vilamo réussie => timer 0 over
      return load20MissileLaunched(state, action);
    case 15:  // Missile Gouv Phase 1 Successful, Start super fast timer
      return load15TrapActivated(state, action);
    case 10:  // GameOver: Timeout before stage 15
      return load10MissionFailedRocketExploded(state, action);
    case 5: // Mission Accepted
      if (state.stage == 1) { // Still playing the video
        return { ...state, stage: 5 };
      }
      else {
        return load5Accepted(state, action);
      }
    case 1: // Started, Visio
      return load1StartGame(state, action);
    case 0: // Lobby
      return { ...state, stage: 0 }
    default:
      return load1StartGame(state, action);
  }
}

const gameFlowTreason = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_STAGE': // @deprecated
    case 'PUSH_STAGE': // @deprecated
    case 'DOWN_LOAD_STAGE':
      return applyLoadStage(state, action, action.stage)
    case 'CLT_START_MISSION':
      switch (action.state?.status) {
        case 'FAIL':
          return load100Fail(state, action);
        case 'SUCCESS':
          return load90Success(state, action);
        default:
          return applyLoadStage(state, action, action.state?.currentStage);
      }
      return state;
    case 'VIDEO_CONFERENCE_ENDED':
      switch (action.mediaId) {
        case 1: // Fin video introduction
          if (action.reason == 'CLOSED_BY_USER') {
            console.log('Loading refused sequence')
            action.asyncDispatch(cltFileTreeLoadTree(1));
            return state;
          }
          else {
            action.asyncDispatch(updateVideoConference(2, 'LOOP', { keepUp: true })); // Video loop joe attend
            action.asyncDispatch(openEnigma(3)); // Do you accept mission ?
            return { ...state, canRelyOnAgentAsked: true };
          }
        case 6: // Fin video mission refusée
          if (isVideoMode) {
            action.asyncDispatch(stopAudio(1014, channels.MUSIC));
            action.asyncDispatch(playAudio(4, 100, channels.MUSIC));
          }
          break;
        case 5:   // Fin vidéo mission acceptée
          action.asyncDispatch(countDownSetStatus(0, 'PLAY'));
          action.asyncDispatch(countDownSetStatus(2, 'PLAY'));
          action.asyncDispatch(navBarSetCountDownId(0));
          action.asyncDispatch(stopAudio(1014, channels.MUSIC));
          action.asyncDispatch(playAudio(4, 100, channels.MUSIC));
          action.asyncDispatch(playAudio(1009, 90, channels.SFX));
          action.asyncDispatch(openEnigma(15)); // Résumé mission
          break
        case 50: // Fin video décollage du missile
          action.asyncDispatch(openEnigma(17)); // Résumé lancement missile
          break;
      }
      return state;
    case 'CLOSE_ENIGMA': // Objectif initial de la mission
      if (action.id == 15 && !state.objective1Given) {
        action.asyncDispatch(openCountDownFullscreen(0));
        setTimeout(() => action.asyncDispatch(closeCountDownFullscreen()), 5000);
        return { ...state, objective1Given: true };
      }
      console.log('state ', action.id, state.objective1Given == true)
      return state;
    case 'VIDEO_CONFERENCE_PICKED_UP':
      action.asyncDispatch(playAudio(1014, 100, channels.MUSIC));
      return state;
    case 'ENIGMA_SOLVED':
      switch (action.id) {
        case 3:
          return acceptMission(state, action);
        case 8: // Trouver le pattern de Vilamo
          action.asyncDispatch(playAudio(1001, 90, channels.SFX))
          return unlockPattern(state, action);
        case 10: // Le missile vient d'être lancé
          action.asyncDispatch(openEnigma(17));
          break;
        case 11: // Mission failed, restart mission
          if (playerInfos.mode != 'solo') {
            navigationManager.push('/mission/treason-multi')
          }
          else {
            navigationManager.push('/mission/treason-solo')
          }
          break;
      }
      return state;
    case 'ENIGMA_INVALID_ATTEMPT':
      switch (action.id) {
        case 3:
          action.asyncDispatch(cltFileTreeLoadTree(1));
          action.asyncDispatch(updateVideoConference(6, 'PLAY', { keepUp: false }));// Refus mission
          break;
        case 11:  // Mission Failed, main menu
          navigationManager.pushMainDashboard();
          break
      }
      return state;
    case 'TRIGGER_CUSTOM_EVENT':
      switch (action.key) {
        case 'CE_CALL_JOE':
          return acceptMission(state, action);
        case 'TREASON_CAN_RELY_AGENT_EVENT':
          console.log('canRelyOnAgentAsked ', state.canRelyOnAgentAsked)
          if (!state.canRelyOnAgentAsked) {
            action.asyncDispatch(openEnigma(3));
            return { ...state, canRelyOnAgentAsked: true }
          }
          return state;
      }
    case 'DOWN_TRIGGER_CUSTOM_EVENT':
      switch (action.key) {
      }
      return state;
    case 'COUNT_DOWN_TIMEOUT':
      switch (action.id) {
        case 2: // 20 sec before takeoff
          action.asyncDispatch(playAudio(1005, 90, channels.SFX));
          break;
        case 1:
          // Explosion du missile
          action.asyncDispatch(playAudio(1002, 90, channels.SFX));
          setTimeout(() => fail(state, action), 3000);
          break;
      }
      break;
    case 'DOWN_MISSIL_GOUV_SET_STATUS':
      if (action.status == 'PHASE1_SUCCESS') {
        load15TrapActivated(state, action);
      }
      else if (action.status == 'PHASE2_WILL_SUCCEED') {
        action.asyncDispatch(countDownSetStatus(1, 'STOP'))
        action.asyncDispatch(countDownSetStatus(2, 'STOP'));  // 20 sec avant takeoff
        setTimeout(() => success(state, action), 5000);
        action.asyncDispatch(setLinkStatus(206, 'SOLVED'))
      }
      return state;
    case 'REFRESH_ROLE_DISTRIBUTION':
      action.asyncDispatch(assignPlayerFile())
      return state;
  }
  return state
}



export default gameFlowTreason