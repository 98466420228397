import { alertConstants } from 'discapecommon/consts/alertConsts';
import { alertActions } from 'discapecommon/actions/alertActions'

export default function alerts(state = [], action) {
  switch (action.type) {
    case alertConstants.ALERT_MESSAGE:
      var id = 1;
      var duration = 2000;
      if (action.duration > 1)
        duration = action.duration;
      setTimeout(() => action.asyncDispatch(alertActions.close(id)), duration)
      return [{
        id,
        type: 'alert-message',
        message: action.message
      }];
    case alertConstants.SUCCESS:
      var id = 1;
      var duration = 10000;
      if (action.duration > 1)
        duration = action.duration;
      setTimeout(() => action.asyncDispatch(alertActions.close(id)), duration)
      return [{
        type: 'alert-success',
        message: action.message
      }];
    case alertConstants.ERROR:
      console.log('alert ', state)
      var id = Math.max(0, ...state.map(f => f.id)) + 1;
      var duration = 20000;
      if (action.duration > 1)
        duration = action.duration;
      setTimeout(() => action.asyncDispatch(alertActions.close(id)), duration)
      return [...state, {
        id,
        type: 'alert-danger',
        message: action.message,
      }];
    case alertConstants.CLOSE_ALERT:
      return state.filter(alert => alert.id != action.id);
    case alertConstants.CLEAR:
      return [];
    default:
      return state
  }
}