import { combineReducers } from 'redux'
import cyberCounter from '../apps/cyberCounter/cyberCounter.reducer'
import cyberDetector from '../apps/cyberDetector/cyberDetector.reducer'
import missilGouv from '../apps/missilGouv/missilGouv.reducer'

const appData = combineReducers({
  cyberCounter,
  cyberDetector,
  missilGouv,
})

export default appData