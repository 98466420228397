import { userTypes } from 'discapecommon/consts/userTypes';
import { alertConstants } from 'discapecommon/consts/alertConsts';

const defaultState = {
  status: 'UNREGISTERED',
  isRegistering: false,
  alert: {},
}
export default function registration(state = defaultState, action) {
  switch (action.type) {
    case userTypes.USERS_REGISTER_REQUEST:
      return {...state, isRegistering: true, status: 'REGISTERING' };
    case userTypes.USERS_REGISTER_SUCCESS:
      return {...state, isRegistering: false, status: 'REGISTERED'};
    case userTypes.USERS_REGISTER_FAILURE:
      return {...state, isRegistering: false, status: 'UNREGISTERED'};

    // ALERT MANAGEMENT
    case alertConstants.SUCCESS:
      return {...state, alert: {
        type: 'alert-success',
        message: action.message
      }};
    case alertConstants.ERROR:
      return {...state, alert: {
        type: 'alert-danger',
        message: action.message
      }};
    case alertConstants.CLEAR:
      return {...state, alert:{} };
    default:
      return state
  }
}